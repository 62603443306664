export const PointCommandIds = Object.freeze({
  CKU: 'bb31a54a-3e25-44da-95f5-8a6e005122a7',
  CKS: 'eb9ee20e-a303-4f6c-99b7-3ccc8097ca63',
  KSC: 'b2ede966-d014-44bc-9926-2489e2e64410',
  KS: '7275fd9c-a82b-493f-8358-e4e3c0d0bcca',
});

/**
 *  @typedef { PointCommandIds[keyof PointCommandIds] } PointCommandIdsType - Types of products for EdenRed, should use `PointCommandIds.jsx`
 * @exports { PointCommandIdsType };
 */
