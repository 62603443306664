import instance from '../instance';

const baseUrl = 'toucan/token/';

const toucanToken = {
  get(userId, goalDefinitionId) {
    return instance.get(baseUrl, {
      params: { user_id: userId, goal_definition_id: goalDefinitionId },
    });
  },
};

export default toucanToken;
