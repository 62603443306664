import instance from '../instance';
import '../../../helpers/DateHelper';
import { appendSearchParams } from '@src/helpers/UrlHelper';
import { baseApi } from '@src/store/api-query';
import { tagTypes } from '@src/store/api-tags';

const baseUrl = 'team-groups/';
const refactoredBaseUrl = 'hierarchy-nodes/';

const hierarchyNode = {
  /** @param {HierarchyNodeParams} queryParams */
  get({ hierarchyNodeId }) {
    let url = `${refactoredBaseUrl}${hierarchyNodeId}`;
    return instance.get(url);
  },
  /** @param {HierarchyNodePatchParams} queryParams */
  patch({ hierarchyNode }) {
    const url = `${refactoredBaseUrl}${hierarchyNode.baseCode}/`;
    return instance.patch(url, hierarchyNode);
  },
};

const hierarchyNodeQueries = baseApi.injectEndpoints({
  tagTypes: [tagTypes.hierarchyNode],
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<HierarchyNodeParams, typeof builder.query, 'api-query', {}>}*/
    getHierarchyNode: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => hierarchyNode.get(params));
      },
      providesTags: [tagTypes.hierarchyNode],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<HierarchyNodePatchParams, typeof builder.mutation, 'api-query', {}>}*/
    patchHierarchyNode: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => hierarchyNode.patch(params));
      },
      invalidatesTags: [tagTypes.hierarchyNode],
    }),
  }),
});

export const {
  useGetHierarchyNodeQuery,
  useLazyGetHierarchyNodeQuery,
  usePatchHierarchyNodeMutation,
} = hierarchyNodeQueries;

// Types
/**
 * @typedef {Object} HierarchyNodeParams
 * @property {string} hierarchyNodeId Id du nœud hiérarchique
 */

/**
 * @typedef {Object} HierarchyNode
 * @property {string} baseCode - Default code of the hierarchy node for params
 * @property {string} [code] - Code of the hierarchy node
 * @property {string} [name] - Name of the hierarchy node
 * @property {string} [status] - Status of the owner (e.g., "ACTIVE")
 */

/**
 * @typedef {Object} HierarchyNodePatchParams
 * @property {HierarchyNode} hierarchyNode Data du noeud hiérarchique
 */

const teamGroupsOld = {
  get(teamGroupId) {
    let url = `${refactoredBaseUrl}${teamGroupId}`;
    return instance.get(url);
  },
  list(full, abortController = null) {
    let url = `${baseUrl}${full ? '?full=true' : ''}`;
    return instance.get(url, { signal: abortController?.signal });
  },
  tree(full, admin, abortController = null) {
    const endpoint = admin ? 'admin_tree' : 'tree';

    return instance.get(`${baseUrl}${endpoint}/${full ? '?full=true' : ''}`, {
      signal: abortController?.signal,
    });
  },
  collaborators(id, options = {}) {
    const url = `${baseUrl}${id}/collaborators/`;
    const {
      simpleCollaborators,
      collaboratorIds,
      listCollaborators,
      abortController,
      limit,
      orderBy,
      search,
    } = options;
    return instance.get(
      appendSearchParams(url, {
        simpleCollaborators,
        collaboratorIds,
        listCollaborators,
        limit,
        orderBy,
        search,
      }),
      { signal: abortController?.signal },
    );
  },
  collaboratorChallenges(id, time, year, start, end, type) {
    var url = `${baseUrl}${id}/collaborator-challenges/?time=${time}`;
    if (year != null) url = `${url}&year=${year}`;
    if (start != null) url = `${url}&start=${start.toUTCJSON()}`;
    if (end != null) url = `${url}&end=${end.toUTCJSON()}`;
    if (type != null) url = `${url}&type=${type}`;
    return instance.get(url);
  },
  teamChallenges(id, time, year, start, end, type) {
    var url = `${baseUrl}${id}/team-challenges/?time=${time}`;
    if (year != null) url = `${url}&year=${year}`;
    if (start != null) url = `${url}&start=${start.toUTCJSON()}`;
    if (end != null) url = `${url}&end=${end.toUTCJSON()}`;
    if (type != null) url = `${url}&type=${type}`;
    return instance.get(url);
  },
  teamGroupBasedChallenges(id, time, year, start, end, type) {
    var url = `${baseUrl}${id}/team-group-challenges/?time=${time}`;
    if (year != null) url = `${url}&year=${year}`;
    if (start != null) url = `${url}&start=${start.toUTCJSON()}`;
    if (end != null) url = `${url}&end=${end.toUTCJSON()}`;
    if (type != null) url = `${url}&type=${type}`;
    return instance.get(url);
  },
  teamPersonalizedChallenges(id, time, year, start, end, type) {
    var url = `${baseUrl}${id}/team-personalized-challenges/?time=${time}`;
    if (year != null) url = `${url}&year=${year}`;
    if (start != null) url = `${url}&start=${start.toUTCJSON()}`;
    if (end != null) url = `${url}&end=${end.toUTCJSON()}`;
    if (type != null) url = `${url}&type=${type}`;
    return instance.get(url);
  },
  create(teamGroup) {
    return instance.post(baseUrl, teamGroup);
  },
  update(teamGroup) {
    const url = `${baseUrl}${teamGroup.id}/`;
    return instance.put(url, teamGroup);
  },
  hierarchyNodesUpdate(teamGroup) {
    const url = `${refactoredBaseUrl}${teamGroup.baseCode}/`;
    return instance.put(url, teamGroup);
  },
  hierarchyNodesPatch(teamGroup) {
    const url = `${refactoredBaseUrl}${teamGroup.baseCode}/`;
    return instance.patch(url, teamGroup);
  },
  delete(id) {
    const url = `${baseUrl}${id}/`;
    return instance.delete(url);
  },
};

export default teamGroupsOld;
