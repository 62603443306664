import { toast } from 'react-toastify';
import { useOrderRewardMutation } from '@async-calls/rewards';
import { setHierarchyNodeUser, useAuth } from '@src/auth';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import React, { useEffect } from 'react';
import { allHierarchyNodeUsersFetchingStart } from '@src/Spider/features/base/allPagesFetchingFromApi/slices';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

/**
 * @param {{
 *   steps: Array,
 *   goHome: () => void,
 *   onOpenSuccessOrderDialog: () => void,
 * }} props
 */
export const useStepperValidity = ({
  steps,
  goHome,
  onOpenSuccessOrderDialog,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const { contract } = useParams();
  const { hierarchyNodeUser } = useAuth();
  const convertPointsContext = useConvertPoints();
  const dispatch = useDispatch();

  const allHierarchyNodeUsersFetchingState = useSelector(
    state => state.allHierarchyNodeUsersFetching,
  );

  const activeStepFromSessionStorage = sessionStorage.getItem('activeStep');
  const rewardFromSessionStorage = sessionStorage.getItem('reward');

  const [activeStep, setActiveStep] = React.useState(
    activeStepFromSessionStorage !== null
      ? parseInt(activeStepFromSessionStorage)
      : 0,
  );

  const [orderReward, orderRewardResult] = useOrderRewardMutation();

  const optionalField = field => {
    if (!field || field?.length === 0) return null;
    return field;
  };

  const nextStep = () => {
    if (activeStep === steps.length - 1) {
      dispatch(allHierarchyNodeUsersFetchingStart([contract, true]));
      onOpenSuccessOrderDialog();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.personalization.update.success',
        }),
      );
      return;
    }
    history.push(steps[activeStep + 1].src, { shallow: true });
    setActiveStep(activeStep + 1);
  };

  const previousStep = () => {
    if (activeStep === 0) {
      goHome();
      return;
    }
    setActiveStep(activeStep - 1);
    history.push(steps[activeStep - 1].src, { shallow: true });
  };

  const findStepIndexBySrc = src => {
    return steps.findIndex(step => step.src === src);
  };

  /** @param {import('@spider:src/enums/conversionStepsUrls').ConversionStepsUrls} stepName */
  const goStep = stepName => {
    const src = `/nodes/${contract}/${stepName}`;
    history.push(src, {
      shallow: true,
    });
    setActiveStep(findStepIndexBySrc(src));
  };

  const checkStepValidity = async (
    ignoreProps,
    errorMessageId = 'common.error_page_title',
  ) => {
    const orderRewardParams = {
      hierarchyNodeUserId: hierarchyNodeUser.uuid,
      form: convertPointsContext.informationStepData
        ? {
            ...convertPointsContext.informationStepData,
            delivery_address: convertPointsContext.informationStepData
              .delivery_address
              ? {
                  ...convertPointsContext.informationStepData.delivery_address,
                  line2: optionalField(
                    convertPointsContext.informationStepData.delivery_address
                      .line2,
                  ),
                }
              : {},
            personal_address: convertPointsContext.informationStepData
              .personal_address
              ? {
                  ...convertPointsContext.informationStepData.personal_address,
                  line2: optionalField(
                    convertPointsContext.informationStepData.personal_address
                      .line2,
                  ),
                }
              : {},
          }
        : null,
      items: convertPointsContext.simulation?.order_suggested?.items,
      lfss_form: convertPointsContext.declarationStepData
        ? {
            ...convertPointsContext.declarationStepData,
            birth_name: optionalField(
              convertPointsContext.declarationStepData.birth_name,
            ),
            personal_address: {
              ...convertPointsContext.declarationStepData.personal_address,
              line2: optionalField(
                convertPointsContext.declarationStepData.personal_address.line2,
              ),
            },
            employer_address: {
              ...convertPointsContext.declarationStepData.employer_address,
              line2: optionalField(
                convertPointsContext.declarationStepData.employer_address.line2,
              ),
            },
          }
        : null,
      check: activeStep !== steps.length - 1,
    };
    try {
      if (
        orderRewardParams.form?.delivery_address &&
        !convertPointsContext.informationStepData?.delivery_address
      ) {
        const { delivery_address, ...restForm } = orderRewardParams.form;
        orderRewardParams.form = restForm;
      }
      if (
        orderRewardParams.form?.personal_address &&
        !convertPointsContext.informationStepData?.personal_address
      ) {
        const { personal_address, ...restForm } = orderRewardParams.form;
        orderRewardParams.form = restForm;
      }
      await orderReward(orderRewardParams).unwrap();
      nextStep();
    } catch (error) {
      console.error(error);
      if (
        Object.keys(error?.data)?.every(key => {
          return !ignoreProps.includes(key);
        })
      ) {
        nextStep();
        return;
      }
      convertPointsContext.setError({ ...error?.data, preventDelete: true });
      toast.error(
        intl.formatMessage({
          id: errorMessageId,
        }),
      );
    }
  };

  useEffect(() => {
    if (rewardFromSessionStorage) {
      convertPointsContext.setReward(JSON.parse(rewardFromSessionStorage));
    }
    sessionStorage.setItem('activeStep', activeStep);
  }, [activeStep]);

  useEffect(() => {
    if (!allHierarchyNodeUsersFetchingState.data) return;
    const currentHierarchyNodeUser =
      allHierarchyNodeUsersFetchingState.data.find(
        user => user.uuid === hierarchyNodeUser.uuid,
      );
    if (!currentHierarchyNodeUser) return;
    dispatch(setHierarchyNodeUser(currentHierarchyNodeUser));
  }, [allHierarchyNodeUsersFetchingState]);

  return {
    previousStep,
    checkStepValidity,
    goStep,
    activeStep,
    orderRewardResult,
  };
};
