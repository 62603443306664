import React from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import { useGetRewardsListQuery } from '@async-calls/rewards';
import { Loader } from '@src/components';
import { useParams } from 'react-router-dom';
import { Reward } from '@spider:src/scenes/Admin/components/ParametersTab/components/ProductsParameters/components/Reward';

const styles = {
  rewardLayout: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  rewards: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
};

const ProductsParameters = ({ ...props }) => {
  const intl = useIntl();
  const params = useParams();

  const { data, isLoading, isFetching } = useGetRewardsListQuery({
    hierarchyNodeId: params.contract,
    distributor: 'Edenred',
  });

  return isLoading || isFetching ? (
    <Loader centered />
  ) : (
    <div className={props.classes.rewardLayout}>
      <Typography variant={'h4'} component={'h4'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.title',
        })}
      </Typography>

      <Typography variant={'subtitle1'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.rewards.subtitle',
        })}
      </Typography>

      {data?.count === 0 && (
        <Typography variant={'subtitle1'}>
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.rewards.empty',
          })}
        </Typography>
      )}
      {data?.count > 0 && (
        <div className={props.classes.rewards}>
          {data.results?.map(reward => (
            <Reward key={reward.uuid} reward={reward} />
          ))}
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(ProductsParameters);
