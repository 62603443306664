import React from 'react';
import { UserRoles } from '@spider:src/enums';
import { AuthorizedRolesWrapper } from '@spider:src/components/AuthorizedRolesWrapper';
import AdminParticipants from './components/AdminParticipants';
import { ManagerParticipants } from './components/ManagerParticipants';

const Participants = () => {
  return (
    <>
      <AuthorizedRolesWrapper
        roles={[UserRoles.ADMINISTRATOR, UserRoles.SUPER_ADMINISTRATOR]}
      >
        <AdminParticipants />
      </AuthorizedRolesWrapper>
      <AuthorizedRolesWrapper roles={[UserRoles.MANAGER]}>
        <ManagerParticipants />
      </AuthorizedRolesWrapper>
    </>
  );
};

export default Participants;
