import React, { useMemo } from 'react';
import { useCurrentUser } from '@spider:src/hooks/useCurrentUser';
/**
 * @typedef {import('react').ReactNode} ReactNode
 * @typedef {import('@spider:src/enums/userRoles').UserRolesType} UserRoles
 */

/**
 * @param {{ roles: UserRoles[], children: ReactNode, fallback: ReactNode}} props
 */
export const AuthorizedRolesWrapper = ({
  roles,
  children,
  fallback = null,
}) => {
  // Fetch du user en cours, ne fait la requete qu'une fois, sinon regarde
  // d'abord dans le store redux (comme ca pas de chargement multiple).
  // Déjà implémenté pour la maintenance.
  const user = useCurrentUser();
  const hasPermission = useMemo(
    () => user && roles.includes(user?.role),
    [user, roles],
  );

  if (!user) return null;

  // Afficher le children seulement si autorisé, sinon afficher un fallback
  return hasPermission ? <>{children}</> : <>{fallback}</>;
};
