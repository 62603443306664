import React from 'react';
import { Divider, makeStyles, Typography } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  children: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [createTheme().breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [createTheme().breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
});

/** @param {{ title: string, first: boolean, showDivider: boolean, children: React.ReactNode }} props */
const InformationContentBlock = ({
  title,
  first = false,
  showDivider = false,
  children,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        variant={'body1'}
        style={{
          margin: first ? '0.5rem 0rem 1rem 0rem' : '0rem 0rem 1rem 0rem',
          fontWeight: '700',
        }}
      >
        {title}
      </Typography>
      <div className={classes.children}>{children}</div>
      {showDivider && (
        <Divider style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }} />
      )}
    </div>
  );
};

export { InformationContentBlock };
