import React, { useMemo } from 'react';
import { withStyles } from '@mui/styles';
import { ReactComponent as PointCup } from '@src/Spider/assets/img/points-cup.svg';
import { ReactComponent as InfoCircle } from '@src/Spider/assets/img/homeParticipantsConvert/info-circle.svg';
import { ReactComponent as WarningIcon } from '@src/Spider/assets/img/warning-icon.svg';
import { ReactComponent as LampOnIcon } from '@src/Spider/assets/img/lamp-on-icon.svg';
import BackgroundPriceConverter from '@src/Spider/assets/img/homeParticipantsConvert/background-price-converter.png';
import { neutralColors, systemColors } from '@src/Spider/themes';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import Formsy from 'formsy-react';
import { Button } from '@src/Spider/components';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import useSessionStorageSliceForm from '@src/Spider/hooks/UseSessionStorageSliceForm';
import { useAuth } from '@src/auth';
import useCommandReward from '@src/Spider/hooks/CommandReward/UseCommandReward';
import { PointCommandIds } from '@src/Spider/enums';
import useConvertPointsHelper from '@src/Spider/hooks/CommandReward/UseConvertPointsHelper';
import { Alert } from '@spider:components/Alert';
import { SystemCode } from '@src/Spider/enums/systemCode';
import { CloseButtonIcon } from '@spider:components/CloseButtonIcon';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { Loader } from '@src/components';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { useFormHelper } from '@src/Spider/hooks/useFormHelper';
import { useBaseFormsyValidation } from '@src/Spider/hooks/useBaseFormsyValidation';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  convertZone: {
    display: 'flex',
    padding: '2.5rem 4rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: neutralColors.neutralColdWhite,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  informationChip: {
    display: 'flex',
    padding: '0.25rem 0.5rem',
    alignItems: 'center',
    gap: '0.5rem',
    borderRadius: '3rem',
    border: `1px solid ${systemColors.infoRegular}`,
    color: systemColors.infoRegular,
  },
  priceResultZone: {
    display: 'flex',
    padding: '2rem 1rem',
    marginTop: '-1rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: `url(${BackgroundPriceConverter})`,
    backgroundSize: 'cover',
    width: '95%',
    margin: 'auto',
  },
  priceDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    alignSelf: 'stretch',
  },
};

/**
 * @param {{
 *   nextStep: (stepKeys: Array<string>) => void,
 *   classes: any
 * }} props
 */
const Component = ({ nextStep, classes }) => {
  const { hierarchyNodeUser } = useAuth();
  const intl = useIntl();

  const { baseFormsyValidationErrors, baseFormsyValidations } =
    useBaseFormsyValidation();

  const convertPointsContext = useConvertPoints();
  const stepKeys = ['items'];

  const { Card } = useCommandReward(convertPointsContext.reward);
  const { minimumPoints, maximumPoints, maximumPerCard, isMultipleCard } =
    useConvertPointsHelper();

  const { open, onOpen, onClose } = useDisclosure();

  const pointsError = useMemo(() => {
    if (
      !convertPointsContext.error?.items ||
      !convertPointsContext.error?.items[0] ||
      !convertPointsContext.error?.items[0].points ||
      !convertPointsContext.error?.items[0].points[0]
    )
      return null;
    return convertPointsContext.error.items[0].points[0];
  }, [convertPointsContext.error]);

  const { formData, handleFormChange, saveStateData, clearSavedState } =
    useSessionStorageSliceForm({
      key: 'convertPointsStep',
      contextValue: convertPointsContext.pointStepData,
      changeContextValue: convertPointsContext.setPointStepData,
    });

  const { formRef, isSubmitButtonDisabled, onValid, onInvalid, formChange } =
    useFormHelper({
      isFormDefaultDirty: !!convertPointsContext.pointStepData,
      clearSavedState,
      saveStateData,
      onFormChange: convertPointsContext.setErrorOnFormChange,
      errorHook: useConvertPoints,
    });

  const helperTextValue = useMemo(
    () =>
      intl
        .formatMessage({
          id: 'spider.convertPoints.stepper.pointStep.helperTextFieldSpe',
        })
        .format(
          parseInt(minimumPoints).toFormatNumber(false),
          parseInt(maximumPoints).toFormatNumber(false),
        ),
    [
      convertPointsContext.reward,
      minimumPoints,
      maximumPoints,
      formData.points,
    ],
  );

  return (
    <Formsy
      ref={formRef}
      name={'point-converter-form'}
      className={classes.root}
      onValidSubmit={() => nextStep(stepKeys)}
      onValid={() => onValid(formData, true)}
      onInvalid={onInvalid}
      onChange={() => formChange(stepKeys)}
    >
      <div className={classes.rootTitle}>
        <PointCup
          fill={neutralColors.neutral900}
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
        <Typography variant={'h3'} component={'h3'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.pointStep.title',
          })}
        </Typography>
      </div>

      {convertPointsContext.reward?.uuid === PointCommandIds.CKU &&
        hierarchyNodeUser.cku &&
        hierarchyNodeUser.cku.last_active_cku !== null && (
          <Alert
            type={SystemCode.WARNING}
            Icon={() => <WarningIcon />}
            title={intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.rechargeAlertTitle',
            })}
            titleVariant={'subtitle1'}
            titleComponent={'span'}
            onClick={onOpen}
          >
            <div>
              <Typography variant={'body2'} style={{ lineHeight: '1.5rem' }}>
                {intl.formatMessage({
                  id: 'spider.convertPoints.stepper.pointStep.rechargeAlertMessageOne',
                })}
              </Typography>
              <Typography variant={'body2'} style={{ lineHeight: '1.5rem' }}>
                {intl.formatMessage({
                  id: 'spider.convertPoints.stepper.pointStep.rechargeAlertMessageTwo',
                })}
              </Typography>
            </div>
          </Alert>
        )}

      <div>
        <div className={classes.convertZone}>
          <Typography variant={'subtitle1'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.convertTitle',
            })}
          </Typography>
          <TextFieldFormsy
            name={'points'}
            type={'number'}
            value={formData.points}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.textFieldLabel',
            })}
            variant={'outlined'}
            required
            style={{ width: '60%' }}
            helperText={helperTextValue}
            validations={{
              ...baseFormsyValidations({
                isInt: true,
                customErrorMessage: pointsError,
              }),
              isMoreThanOrEquals: minimumPoints,
              isLessThanOrEquals: maximumPoints,
            }}
            validationErrors={{
              ...baseFormsyValidationErrors({
                isRequired: true,
                isInt: true,
                customErrorMessage: pointsError,
              }),
              isMoreThanOrEquals: helperTextValue,
              isLessThanOrEquals: helperTextValue,
            }}
            onChange={handleFormChange}
          />
          <div className={classes.informationChip}>
            <InfoCircle fill={systemColors.infoRegular} />
            <Typography
              variant={'subtitle1'}
              style={{ fontSize: '0.625rem', fontWeight: 'bold' }}
            >
              {intl
                .formatMessage({
                  id: 'spider.convertPoints.stepper.pointStep.informationDetails',
                })
                .format(
                  hierarchyNodeUser.point_balance.available - formData.points,
                )}
            </Typography>
          </div>
        </div>
        <div className={classes.priceResultZone}>
          <div className={classes.priceDetails}>
            {convertPointsContext.reward &&
              isMultipleCard &&
              maximumPerCard && (
                <Alert
                  Icon={() => <LampOnIcon />}
                  style={{
                    backgroundColor: `${neutralColors.neutralWhite}66`,
                    color: neutralColors.neutral900,
                  }}
                  title={intl
                    .formatMessage({
                      id: 'spider.convertPoints.stepper.pointStep.multipleCardAlertTitle',
                    })
                    .format(
                      convertPointsContext.reward?.name,
                      maximumPerCard.toFormatNumber(),
                    )}
                  titleVariant={'body2'}
                  titleComponent={'span'}
                  titleStyle={{
                    fontWeight: 'normal',
                    color: neutralColors.neutral900,
                  }}
                />
              )}
            <Typography variant={'body1'}>
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.priceReceivedLabel',
              })}
            </Typography>
            {isMultipleCard && convertPointsContext.isSimulateFetching ? (
              <Loader centered />
            ) : (
              isMultipleCard &&
              !convertPointsContext.isSimulateFetching && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    gap: '0.5rem',
                  }}
                >
                  <div>
                    {convertPointsContext.simulation?.order_suggested?.items?.map(
                      (detail, index) => (
                        <span key={index}>
                          <Typography variant={'body2'} component={'span'}>
                            {intl
                              .formatMessage({
                                id: 'spider.convertPoints.stepper.pointStep.priceDetailsCard',
                              })
                              .format(detail.quantity)}
                          </Typography>
                          <Typography
                            variant={'body2'}
                            component={'span'}
                            style={{ fontWeight: 'bold' }}
                          >
                            {intl
                              .formatMessage({
                                id: 'spider.convertPoints.stepper.pointStep.priceDetailsMonetaryValue',
                              })
                              .format(detail.monetary_value.toFormatNumber())}
                          </Typography>
                          {convertPointsContext.simulation.order_suggested
                            .items[index + 1] && (
                            <Typography variant={'body2'} component={'span'}>
                              {intl.formatMessage({
                                id: 'spider.convertPoints.stepper.pointStep.priceDetailsPlus',
                              })}
                            </Typography>
                          )}
                        </span>
                      ),
                    )}
                  </div>
                  <Typography variant={'body1'}>
                    {intl.formatMessage({
                      id: 'spider.convertPoints.stepper.pointStep.priceReceivedLabelTotal',
                    })}
                  </Typography>
                </div>
              )
            )}
            <div>
              {convertPointsContext.isSimulateFetching ? (
                <Loader centered />
              ) : (
                <Typography
                  variant={'h1'}
                  component={'h1'}
                  style={{ fontSize: '3.5rem', fontWeight: 'bold' }}
                >
                  {intl
                    .formatMessage({
                      id: 'spider.convertPoints.stepper.pointStep.price',
                    })
                    .format(
                      (
                        convertPointsContext.simulation?.order_suggested
                          ?.monetary_value ?? 0
                      )?.toFormatNumber(),
                    )}
                </Typography>
              )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.25rem',
                }}
              >
                <Typography variant={'body1'} style={{ fontSize: '0.75rem' }}>
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.includeFeesInfo',
                  })}
                </Typography>
                <Tooltip
                  title={intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.includeFeesInfoTooltip',
                  })}
                  placement='top'
                  arrow
                >
                  <InfoCircle fill={neutralColors.neutral900} />
                </Tooltip>
              </div>
            </div>
            <Card />
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={onClose}>
        <DialogTitle disableTypography style={{ alignItems: 'baseline' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <CloseButtonIcon onClick={onClose} />
          </div>
        </DialogTitle>

        <DialogContent
          style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
        >
          <div>
            <Typography
              variant={'h1'}
              component={'h1'}
              className={'underline-center'}
              style={{ width: '100%', textAlign: 'center' }}
            >
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.title',
              })}
            </Typography>
          </div>

          <div>
            <Typography variant={'subtitle1'} style={{ textAlign: 'center' }}>
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.blockMessage',
              })}
            </Typography>
            <ul style={{ margin: '0' }}>
              <li>
                <Typography
                  variant={'subtitle1'}
                  style={{ textAlign: 'center' }}
                >
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.blockFromClientSpace',
                  })}
                </Typography>
              </li>
              <li>
                <Typography
                  variant={'subtitle1'}
                  style={{ textAlign: 'center' }}
                >
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.blockByPhone',
                  })}
                </Typography>
              </li>
            </ul>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            size={'small'}
            color={'primary'}
            variant={'contained'}
            onClick={onClose}
          >
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointBalanceDialog.closeAction',
            })}
          </Button>
        </DialogActions>
      </Dialog>

      <div className={classes.actions}>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'small'}
          type={'submit'}
          disabled={isSubmitButtonDisabled}
        >
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </Button>
      </div>
    </Formsy>
  );
};

const PointsStep = withStyles(styles)(Component);

export { PointsStep };
