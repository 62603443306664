import instance from '../instance';
import { baseApi } from '@src/store/api-query';

/**
 * @param {string} id ID du noeud hiérarchique en base de données
 */
const baseUrl = id => `hierarchy-nodes/${id}/hierarchy-node-users`;

/**
 * @typedef {Object} ParticipantListParams
 * @property {string} id ID du noeud hiérarchique en base de données
 * @property {number} [page] Page à retourner
 * @property {number} [page_size] Nombre de résultats à retourner par page
 * @property {boolean} [own] Permet de filtrer uniquement sur les profils appartement à l’utilisateur connecté.
 * @property {boolean} [include_address] Si true, retourne les adresses du profil utilisateur dans la propriété legal_entity_address d’une ressource de type HierarchyNodeUser
 * @property {boolean} [include_approval] Si true, retourne la date d’approbation du règlement actif de la plateforme dans la propriété approval d’une ressource de type UserSystemFileApproval. Sinon la propriété n’est pas retournée.
 * @property {boolean} [include_cku] Si true, retourne les informations de la dernière CKU active du profil dans le propriété point_balence d’une ressource de type HierarchyNodeUser, uniquement si l’utilisateur connecté est administrateur ou est propriétaire du profil
 * @property {boolean} [include_manager] Permet de filtrer uniquement sur les profils appartement à l’utilisateur connecté.
 * @property {boolean} [include_point_balance] Si true, retourne le solde de points du profil utilisateur dans la propriété point_balance d’une ressource de type HierarchyNodeUser, avec les informations sur l’expiration la plus proche. Sinon la propriété n’est pas retournée.
 */

const participants = {
  /**
   * @description Retourne la liste des participants appartenant à un hierarchy node
   * @param {ParticipantListParams} queryParams
   */
  list({
    id,
    page,
    page_size,
    own,
    include_address,
    include_approval,
    include_cku,
    include_manager,
    include_point_balance,
  }) {
    const params = new URLSearchParams();
    if (page) params.append('page', page);
    if (page_size) params.append('page_size', page_size);
    if (own) params.append('own', own);
    if (include_address) params.append('include_adress', include_address);
    if (include_approval) params.append('include_approval', include_approval);
    if (include_cku) params.append('include_cku', include_cku);
    if (include_manager) params.append('include_manager', include_manager);
    if (include_point_balance)
      params.append('include_point_balance', include_point_balance);
    return instance.get(baseUrl(id), { params });
  },
};

const participantsQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<ParticipantListParams, typeof builder.query, 'api-query', {}>}*/
    getHierarchyNodeUsersList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => participants.list(params));
      },
    }),
  }),
});

export const {
  useGetHierarchyNodeUsersListQuery,
  useLazyGetHierarchyNodeUsersListQuery,
} = participantsQueries;

export default participants;
