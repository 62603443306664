/**
 * @template T
 * @typedef {{id: string, page?: number, page_size?: number}} ListParams
 * @param {ListParams} params
 * @param {T[]} dataset
 * @returns {Promise<{data: {results: T[], count: number}}>}
 */
export const mockedGetList = ({ id, page = 1, page_size = 10 }, dataset) => {
  console.log('mocked list with ID', id);

  return new Promise(resolve => {
    setTimeout(() => {
      const startIndex = (page - 1) * page_size;
      const endIndex = startIndex + page_size;

      const paginatedResults = dataset.slice(startIndex, endIndex);

      resolve({
        data: {
          results: paginatedResults,
          count: dataset.length,
        },
      });
    }, 600);
  });
};

/**
 * @template T
 * @param {T[]} dataset
 * @param {(item: T) => boolean} findPredicate
 * @returns {Promise<{data: T}>}
 */
export const mockedGetDetails = (dataset, findPredicate) => {
  return new Promise(resolve => {
    const data = dataset.find(findPredicate);
    setTimeout(() => {
      if (!data) console.error('No item found for this ID');
      resolve({
        data,
      });
    }, 600);
  });
};
