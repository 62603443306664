import React from 'react';
import { withStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import {
  useGetHierarchyNodeQuery,
  usePatchHierarchyNodeMutation,
} from '@async-calls/teamGroups';
import { Loader } from '@src/components';
import { Button } from '@spider:components/index';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { UserRoles } from '@src/Spider/enums';
import { AuthorizedRolesWrapper } from '@spider:components/AuthorizedRolesWrapper';
import HierarchyNodeStatus from '@spider:components/HierarchyNodeStatus';
import { HierarchyNodeStatuses } from '@src/Spider/enums/hierarchyNodeStatuses';
import { getIntlEnumLabel } from '@src/Spider/enums/utils';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
    height: '100%',
  },
};

const Component = ({ ...props }) => {
  const intl = useIntl();
  const { contract } = useParams();

  const { data, isFetching } = useGetHierarchyNodeQuery({
    hierarchyNodeId: contract,
  });

  const [patchHierarchyNode, mutateResult] = usePatchHierarchyNodeMutation({
    hierarchyNodeId: contract,
  });

  const switchOperationMode = async () => {
    if (!data) return;

    const newStatus =
      data.status === HierarchyNodeStatuses.ACTIVE
        ? HierarchyNodeStatuses.MAINTENANCE
        : HierarchyNodeStatuses.ACTIVE;

    try {
      await patchHierarchyNode({
        hierarchyNode: {
          baseCode: contract,
          status: newStatus,
        },
      }).unwrap();
      toast.success(
        intl.formatMessage({
          id: getIntlEnumLabel('switch_maintenance_success', newStatus),
        }),
      );
    } catch (error) {
      console.error('Failed to update status:', error);
      toast.error(
        intl.formatMessage({
          id: getIntlEnumLabel('switch_maintenance_error', newStatus),
        }),
      );
    }
  };

  if (
    data?.status !== HierarchyNodeStatuses.ACTIVE &&
    data?.status !== HierarchyNodeStatuses.MAINTENANCE
  )
    return <></>;

  return isFetching || mutateResult.isFetching ? (
    <Loader centered />
  ) : (
    <div className={props.classes.root}>
      <HierarchyNodeStatus status={data.status} />
      <AuthorizedRolesWrapper roles={[UserRoles.SUPER_ADMINISTRATOR]}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          onClick={switchOperationMode}
        >
          {intl.formatMessage({
            id: getIntlEnumLabel(
              'switch_maintenance_button_label',
              data?.status,
            ),
          })}
        </Button>
      </AuthorizedRolesWrapper>
    </div>
  );
};

const MaintenanceSwitch = withStyles(styles)(Component);
export { MaintenanceSwitch };
