import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetContractDetailQuery } from '@async-calls/contractDetails';
import { makeStyles } from '@material-ui/styles';
import { Loader } from '@src/components';
import { Link, Typography } from '@material-ui/core';
import { useGetHierarchyNodeQuery } from '@async-calls/teamGroups';
import { useSelector } from 'react-redux';
import neutralColors from '@src/Spider/themes/colors/neutralColors';
import { useIntl } from 'react-intl';
import { systemColors } from '@src/Spider';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@material-ui/core/styles';
import { ImageView } from '@spider:components/ImageView';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderRadius: '1rem',
    backgroundColor: neutralColors.neutralWhite,
  },
  bannerImage: {
    borderRadius: '1rem 1rem 0rem 0rem',
    minHeight: '12.5rem',
    height: '12.5rem',
    backgroundColor: ({ mainColor, banner }) =>
      banner ? neutralColors.neutralWhite : mainColor,
  },
  bannerContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '1rem',
  },
  bannerLogo: {
    minHeight: '10rem',
    height: '10rem',
    minWidth: '10rem',
    width: '10rem',
    padding: '1.125rem 1.313rem',
    borderRadius: '1rem',
    backgroundColor: ({ mainColor, logo }) =>
      logo ? neutralColors.neutralWhite : mainColor,
  },
  bannerLogoMobile: {
    minHeight: '3rem',
    height: '3rem',
    minWidth: '3rem',
    width: '3rem',
    padding: '0.208rem 0.25rem',
    borderRadius: '1rem',
    backgroundColor: ({ mainColor, logo }) =>
      logo ? neutralColors.neutralWhite : mainColor,
  },
  bannerTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',
  },
  bannerInformation: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    [createTheme().breakpoints.up('sm')]: {
      gap: '0.5rem',
    },
  },
  linkContent: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem',
    alignItems: 'center',
  },
});

const Banner = () => {
  const intl = useIntl();
  const { contract } = useParams();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));

  const maxDescriptionPreShowLength = useMemo(
    () => (isMobile ? 75 : 300),
    [isMobile],
  );
  const preShowGap = 20;
  const [showAllDescription, setShowAllDescription] = useState(false);

  const mainState = useSelector(state => state.mainKey);

  const hierarchyNodeResult = useGetHierarchyNodeQuery({
    hierarchyNodeId: contract,
  });

  const contractDetailResult = useGetContractDetailQuery({
    hierarchyNodeId: contract,
  });

  const indexToExclude = useMemo(() => {
    const descriptionLength = contractDetailResult.data?.description?.length;
    if (!descriptionLength) return 0;
    if (
      showAllDescription ||
      Math.abs(maxDescriptionPreShowLength - descriptionLength) < preShowGap
    )
      return contractDetailResult.data.description.length;
    return maxDescriptionPreShowLength;
  }, [contractDetailResult, showAllDescription, maxDescriptionPreShowLength]);

  const classes = useStyles({
    mainColor: mainState?.config?.mainColor?.value,
    banner: hierarchyNodeResult.data?._context?.banner,
    logo: hierarchyNodeResult.data?._context?.logo,
  });

  const descriptionSplit = useMemo(
    () =>
      contractDetailResult.data?.description
        ?.substring(0, indexToExclude)
        ?.split('\n'),
    [contractDetailResult, indexToExclude],
  );

  const showExpandLink =
    contractDetailResult.data?.description?.length >
    maxDescriptionPreShowLength;

  return hierarchyNodeResult.isFetching || contractDetailResult.isFetching ? (
    <Loader centered />
  ) : (
    <div className={classes.root}>
      <ImageView
        imageViewClass={classes.bannerImage}
        image={hierarchyNodeResult.data?._context?.banner}
        alt='banner'
        imageStyle={{ borderRadius: '1rem 1rem 0rem 0rem' }}
      />
      <div className={classes.bannerContent}>
        {!isMobile && (
          <ImageView
            imageViewClass={classes.bannerLogo}
            image={hierarchyNodeResult.data?._context?.logo}
            alt='logo'
          />
        )}
        <div className={classes.bannerInformation}>
          <div className={classes.bannerTitle}>
            {isMobile && (
              <ImageView
                imageViewClass={classes.bannerLogoMobile}
                image={hierarchyNodeResult.data?._context?.logo}
                alt='logo'
              />
            )}
            <Typography
              variant={isMobile ? 'h4' : 'h1'}
              component={isMobile ? 'h4' : 'h1'}
              className='underline-left'
            >
              {contractDetailResult.data?.title}
            </Typography>
          </div>
          <Typography
            variant={isMobile ? 'body1' : 'subtitle1'}
            style={{ fontWeight: 400, lineHeight: '2rem' }}
          >
            {descriptionSplit?.map((line, index) => (
              <React.Fragment key={index}>
                <>
                  {line}
                  {showExpandLink && index === descriptionSplit.length - 1
                    ? '...'
                    : ''}
                </>
                <br />
              </React.Fragment>
            ))}
          </Typography>
          {showExpandLink && (
            <Link
              className={`bold small ${classes.linkContent}`}
              onClick={() => setShowAllDescription(!showAllDescription)}
            >
              {intl.formatMessage({
                id: showAllDescription
                  ? 'spider.common.see_less'
                  : 'spider.common.see_more',
              })}
              {showAllDescription ? (
                <ArrowUp2 size='16' color={systemColors.infoRegular} />
              ) : (
                <ArrowDown2 size='16' color={systemColors.infoRegular} />
              )}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export { Banner };
