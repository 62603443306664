import React, { useState } from 'react';
import {
  DialogContent,
  Drawer,
  Paper,
  Tab,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import { ImportContent } from './components/ImportContent';
import { DialogIndicator } from '@spider:components/DialogIndicator';
import { neutralColors } from '@src/Spider/themes';
import { createTheme } from '@material-ui/core/styles';
import { ImportLogsTypes } from '@src/Spider/enums/importLogsTypes';
import { useParams } from 'react-router-dom';
import { useAuth } from '@src/auth';
import { CloseButtonIcon } from '@spider:components/CloseButtonIcon';
import useSystemFileRefactor from '@src/Spider/hooks/useSystemFileRefactor';
import { useCreateImportLogMutation } from '@async-calls/importLogs';

const styles = {
  dialogTitle: {
    display: 'flex !important',
    flexDirection: 'column !important',
    padding: '1rem !important',
    width: '100%',
  },
  closeTitleDialog: {
    position: 'absolute',
    right: '1rem',
    top: '2rem',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: '1rem',
    overflow: 'auto',
    backgroundColor: neutralColors.neutralBg,
    [createTheme().breakpoints.up('md')]: {
      padding: '0rem 2rem 2rem 2rem !important',
    },
  },
  dialogPaperContent: {
    borderRadius: '1rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    '&.MuiPaper-root': {
      boxShadow: 'none',
    },
    [createTheme().breakpoints.up('md')]: {
      padding: '1rem',
      width: '75%',
    },
  },
  drawer: {
    '& .MuiDrawer-paper': {
      borderTopLeftRadius: '0.75rem',
      borderTopRightRadius: '0.75rem',
      border: 'none',
      height: '90vh',
      [createTheme().breakpoints.up('sm')]: {
        height: '85vh',
        border: '4px solid',
        borderBottom: 'none',
        borderColor: neutralColors.neutral300,
      },
    },
  },
  header: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: neutralColors.neutralBg,
    cursor: 'pointer',
    minHeight: '48px',
    [createTheme().breakpoints.down('sm')]: {
      minHeight: '6px',
    },
  },
};

const ImportDialog = ({
  fixedCacheKey,
  open,
  setOpen,
  defaultTab,
  onFileCreated,
  ...props
}) => {
  const intl = useIntl();
  const { contract } = useParams();
  const { hierarchyNodeUser } = useAuth();

  const isMobile = useMediaQuery(createTheme().breakpoints.down('sm'));

  const { updateSystemFile } = useSystemFileRefactor({
    fixedCacheKey,
    onFileUpdated: additionalProps => {
      onFileCreated(additionalProps);
      onClose();
    },
    updateSystemFileMutation: useCreateImportLogMutation,
  });

  const POINTS_TAB = {
    label: intl.formatMessage({ id: 'spider.points.title' }),
    index: '1',
  };
  const PARTICIPANTS_TAB = {
    label: intl.formatMessage({ id: 'spider.participants.title' }),
    index: '2',
  };

  const getDefaultTab = () => {
    return defaultTab ? defaultTab : PARTICIPANTS_TAB.index;
  };

  const [tabValue, setTabValue] = useState(getDefaultTab());

  const handleChange = (event, tab) => {
    setTabValue(tab);
  };

  const onValidate = async (files, importLogType) => {
    const file = new FormData();
    file.append('type', importLogType);
    file.append('file', files[0]);
    file.append('hierarchy_node_user', hierarchyNodeUser.uuid);
    await updateSystemFile(file, {
      hierarchyNodeId: contract,
      type: importLogType,
    });
  };

  const onClose = () => {
    setOpen(false);
    setTabValue(getDefaultTab());
  };

  return (
    <Drawer
      anchor='bottom'
      open={open}
      onClose={onClose}
      className={props.classes.drawer}
    >
      <div className={props.classes.header} onClick={onClose}>
        <DialogIndicator />
        {!isMobile && (
          <CloseButtonIcon
            onClick={onClose}
            className={props.classes.closeTitleDialog}
          />
        )}
      </div>
      <DialogContent className={props.classes.dialogContent}>
        <div className={props.classes.dialogTitle}>
          <div>
            <Typography
              variant={'h1'}
              component={'h1'}
              className={'title underline-center'}
            >
              {intl.formatMessage({ id: 'spider.importDialog.title' })}
            </Typography>
          </div>
        </div>
        <Paper className={props.classes.dialogPaperContent}>
          <TabContext value={tabValue}>
            <TabList
              onChange={handleChange}
              TabIndicatorProps={{ children: <div /> }}
              className={'switch'}
            >
              <Tab
                label={POINTS_TAB.label}
                value={POINTS_TAB.index}
                disableFocusRipple
              />
              <Tab
                label={PARTICIPANTS_TAB.label}
                value={PARTICIPANTS_TAB.index}
                disableFocusRipple
              />
            </TabList>
            <TabPanel value={POINTS_TAB.index}>
              <ImportContent
                key={'imports'}
                importLogType={ImportLogsTypes.POINTS}
                validateImport={onValidate}
              />
            </TabPanel>
            <TabPanel value={PARTICIPANTS_TAB.index}>
              <ImportContent
                key={'participants'}
                importLogType={ImportLogsTypes.USERS}
                validateImport={onValidate}
              />
            </TabPanel>
          </TabContext>
        </Paper>
      </DialogContent>
    </Drawer>
  );
};

export default withStyles(styles)(ImportDialog);
