import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { CheckboxFormsy } from '@spider:components/FormsyComponents/CheckboxFormsy';
import { useIntl } from 'react-intl';

/**
 * @param {{
 *   formData: Object,
 *   name: string,
 *   error: any,
 *   label: string,
 *   handleFormChange: (formData: Object) => void,
 * }} props
 */
const Component = ({
  formData,
  name = 'required_checkbox',
  error,
  label,
  handleFormChange,
}) => {
  const intl = useIntl();
  return (
    <FormControl
      required
      error={Boolean(error)}
      style={{
        display: 'flex',
        justifyContent: 'left',
        flexDirection: 'column',
      }}
    >
      <FormControlLabel
        control={
          <CheckboxFormsy
            name={name}
            checked={formData}
            value={formData}
            className={'root'}
            disableRipple
            checkedIcon={<span className={'icon checkedIcon'} />}
            icon={<span className={'icon'} />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            onChange={handleFormChange}
            validations={{
              isTrue: true,
              customError: Boolean(error),
            }}
            validationErrors={{
              isTrue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              customError: error,
            }}
          />
        }
        label={label}
        style={{ margin: 0 }}
      />
      {Boolean(error) && (
        <FormHelperText>
          {error || intl.formatMessage({ id: 'common.form.required_error' })}
        </FormHelperText>
      )}
    </FormControl>
  );
};

const RequiredCheckboxCheck = Component;

export { RequiredCheckboxCheck };
