import { all, call, put, takeLatest } from 'redux-saga/effects';
import api from '../../../../data/api/api';
import { updateConfigsKeySlice } from './slices';
import { ConfigCodes } from '@src/Spider/enums/configCodes';
import { updateConfigProps } from '@src/Spider/features/main/slices';

function* updateConfigs(slice, endpoint, action) {
  try {
    const updatedConfigs = yield all(
      action.payload.map(config =>
        call(api.configs.update, config.id, config.value),
      ),
    );
    const mainColorConfigUpdate = updatedConfigs.find(
      updatedConfig => updatedConfig.data.code === ConfigCodes.MAIN_COLOR,
    );
    if (mainColorConfigUpdate) {
      yield put(
        updateConfigProps({
          value: mainColorConfigUpdate.data.value,
          props: 'mainDefaultColor',
        }),
      );
    }
    yield put(slice.actions.updateSuccess());
  } catch (error) {
    yield put(
      slice.actions.updateFailure(error?.response?.data?.error ?? 'UNKNOWN'),
    );
  }
}

function* watchUpdateConfigs(slice, endpoint) {
  yield takeLatest(
    slice.actions.updateStart.type,
    updateConfigs,
    slice,
    endpoint,
  );
}

export default function* updateConfigsSaga() {
  yield all([watchUpdateConfigs(updateConfigsKeySlice, api.configs.update)]);
}
