import React, { useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Chip,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Close as CloseIcon } from '@mui/icons-material';
import { formatDate } from '@src/helpers/DateHelper';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { InfoCircle, Sms, Truck, User } from 'iconsax-react';
import { useLazyGetOrderDetailsQuery } from '@src/data/api/endpoints/rewards';
import OrderStatus from '@src/Spider/components/OrderStatus';
import { GeoLocation } from '@src/Spider/components/icons/GeoLocation';
import { InfoCard } from './components';
import { getFullName } from '@src/helpers/StringHelper';
import { neutralColors, systemColors } from '@src/Spider';
import { ProductType } from '../ProductType';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  loading: {
    width: '100%',
    minWidth: '10rem',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    padding: '1.5rem',
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  headerTitle: {
    fontWeight: 'bold',
  },
  fieldWrapper: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  itemValue: {
    fontWeight: '700!important',
    fontSize: '0.8rem!important',
  },
  addressName: {
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '1rem',
    gap: '0.5rem',
  },
  mailBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  tooltip: {
    height: '1rem',
    width: '1rem',
  },
  textWithTooltip: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.25rem',
  },
});

/**
 * @typedef {import('@src/mock/datasets/ordersList').OrderItem} OrderItem
 * @param {{
 *   classes: any,
 *   uuid: string,
 *   onClose: () => void
 * }} props
 */
const OrderDetails = ({ uuid, onClose }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [fetch, { data, isFetching, isError }] =
    useLazyGetOrderDetailsQuery(uuid);
  useEffect(() => {
    if (uuid) {
      fetch({ uuid, include_items: true, include_form: true });
    }
  }, [uuid, fetch]);

  if (!uuid) return null;

  if (isFetching || !data)
    return (
      <Box className={classes.loading}>
        <CircularProgress />
      </Box>
    );

  if (isError) {
    onClose();
    toast.error(
      intl.formatMessage({ id: 'spider.orders.details.errorMessage' }),
    );
  }

  const deliveryAddress = data.form?.delivery_address;

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <IconButton onClick={onClose} size='small'>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.titleSection}>
        <Typography variant='h5' className={classes.headerTitle}>
          {intl.formatMessage({ id: 'spider.orders.details.title' })}
        </Typography>
      </Box>
      <InfoCard title='spider.orders.details.generalInfo' icon={InfoCircle}>
        <Box className={classes.fieldWrapper}>
          <Typography>
            <ProductType
              product={data.items?.[0]?.reward_allocation?.reward?.name}
            />
          </Typography>
          <OrderStatus status={data.status} />
        </Box>
        <Box>
          <Typography className={classes.itemValue}>{data.number}</Typography>
          <Typography variant='body2' className={classes.textWithTooltip}>
            {intl.formatMessage({
              id: 'spider.orders.details.orderNumber',
            })}
            <Tooltip
              title={intl.formatMessage({
                id: 'spider.orders.details.orderNumberTooltip',
              })}
              placement='top'
              arrow
            >
              <InfoCircle
                fill={neutralColors.neutral900}
                className={classes.tooltip}
              />
            </Tooltip>
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.itemValue}>
            {intl.formatMessage(
              {
                id: 'spider.orders.details.facturationValue',
              },
              {
                number: data.number,
                date: formatDate(data.creation_date),
              },
            )}
          </Typography>
          <Typography variant='body2'>
            {intl.formatMessage({
              id: 'spider.orders.details.facturation',
            })}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.itemValue}>
            {data.items[0]?.reward_allocation?.reward?.uuid}
          </Typography>
          <Typography variant='body2' className={classes.textWithTooltip}>
            {intl.formatMessage({ id: 'spider.orders.details.orderRef' })}
            <Tooltip
              title={intl.formatMessage({
                id: 'spider.orders.details.orderRefTooltip',
              })}
              placement='top'
              arrow
            >
              <InfoCircle
                fill={neutralColors.neutral900}
                className={classes.tooltip}
              />
            </Tooltip>
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.itemValue}>
            {formatDate(data.creation_date)} -{' '}
            {formatDate(data.creation_date, {
              hour: '2-digit',
              minute: '2-digit',
            })}
          </Typography>
          <Typography variant='body2'>
            {intl.formatMessage({ id: 'spider.orders.details.orderDate' })}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.itemValue}>
            {data.points
              ? intl.formatMessage(
                  { id: 'spider.orders.details.monetaryPoints' },
                  {
                    points: data.points,
                    monetary_value: data.monetary_value,
                  },
                )
              : '-'}
          </Typography>
          <Typography variant='body2'>
            {intl.formatMessage({ id: 'spider.orders.details.amount' })}
          </Typography>
        </Box>
      </InfoCard>
      <InfoCard
        icon={GeoLocation}
        title='spider.orders.details.deliveryInfo'
        show={deliveryAddress}
      >
        <Box>
          {deliveryAddress.company_name && (
            <Typography className={classes.addressName}>
              {deliveryAddress.company_name}
            </Typography>
          )}
          <Typography>{deliveryAddress.line1}</Typography>
          <Typography>
            {`${deliveryAddress.postal_code} ${deliveryAddress.city}`}
          </Typography>
        </Box>
      </InfoCard>
      <InfoCard
        icon={User}
        title='spider.orders.details.personalInfo'
        show={data.form}
      >
        <Box>
          <Typography className={classes.itemValue}>
            {getFullName(data.form)}
          </Typography>
          <Typography variant='body2'>
            {intl.formatMessage({
              id: 'spider.orders.details.formName',
            })}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.itemValue}>
            {data.form.phone_number}
          </Typography>
          <Typography variant='body2'>
            {intl.formatMessage({
              id: 'spider.orders.details.formPhoneNumber',
            })}
          </Typography>
        </Box>
        <Box className={classes.mailBox}>
          <Typography variant='body2'>
            {intl.formatMessage({
              id: 'spider.orders.details.mailActivation',
            })}
          </Typography>
          <Chip
            className='big width-fit'
            label={data.form.email}
            avatar={<Sms />}
            borderColor={systemColors.infoRegular}
            color={systemColors.infoRegular}
          />
        </Box>
      </InfoCard>
      {data.tracking_url && (
        <InfoCard
          title='spider.orders.details.deliveryTracking'
          icon={Truck}
          show={deliveryAddress}
        >
          <Typography>
            {intl.formatMessage(
              { id: 'spider.orders.details.here' },
              {
                ici: (
                  <Link
                    to={{ pathname: data.tracking_url }}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {intl.formatMessage({
                      id: 'spider.orders.details.hereText',
                    })}
                  </Link>
                ),
              },
            )}
          </Typography>
        </InfoCard>
      )}
    </Box>
  );
};

export { OrderDetails };
