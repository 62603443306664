import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom/';
import { HierarchyNodeStatuses } from '@src/Spider/enums/hierarchyNodeStatuses';
import { useLazyGetHierarchyNodeQuery } from '@async-calls/teamGroups';
import { useAuth } from '@src/auth/AuthContext';
import { UserRoles } from '@src/Spider/enums';

export const MaintenanceProvider = ({ children }) => {
  const { hierarchyNodeUser } = useAuth();
  const { contract } = useParams();

  const [fetch, { data }] = useLazyGetHierarchyNodeQuery();

  useEffect(() => {
    if (!contract) return;
    fetch({
      hierarchyNodeId: contract,
    });
  }, [contract]);

  if (
    hierarchyNodeUser?.role !== UserRoles.SUPER_ADMINISTRATOR &&
    data?.status === HierarchyNodeStatuses.MAINTENANCE
  ) {
    return <Redirect to={`/nodes/${contract}/maintenance`} />;
  }

  return <>{children}</>;
};
