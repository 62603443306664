import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Card, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { usePostActivateAccountMutation } from '@async-calls/auth';
import { getQueryParam } from '@src/helpers/UrlHelper';
import { AccountActivationStep, AccountActivationSteps } from './components';
import { getAccountActivationErrorRedirectPath } from './utils';

const useStyles = makeStyles(() => ({
  root: {
    gap: '24px',
    padding: '48px',
  },
}));

const AccountActivationFinalization = () => {
  const history = useHistory();
  const location = useLocation();
  const { contract } = useParams();
  const classes = useStyles();
  const [postActivateAccount, { error, isError, isSuccess }] =
    usePostActivateAccountMutation();

  useEffect(() => {
    async function activateAccount() {
      const key = getQueryParam(location.search, 'key');

      if (!key) {
        history.push('/');
        return;
      }

      await postActivateAccount({ requestBody: { key, contract } }).unwrap();
    }

    activateAccount();
  }, [contract, history, location.search, postActivateAccount]);

  useEffect(() => {
    if (isSuccess) {
      history.push(
        `/nodes/${contract}/account-activation-status?status=success`,
      );
    }
  }, [contract, history, isSuccess]);

  useEffect(() => {
    if (isError) {
      const key = getQueryParam(location.search, 'key');
      const redirectPath = getAccountActivationErrorRedirectPath(
        error?.data?.error,
        contract,
        key,
      );
      history.push(redirectPath);
    }
  }, [contract, error?.data?.error, history, isError, location.search]);

  return (
    <>
      <div className={'ft-main-top'}>
        <AccountActivationSteps activeStep={AccountActivationStep.Validation} />
      </div>

      <div className={'ft-content'}>
        <Card elevation={0} className={classes.root}>
          <CircularProgress />
        </Card>
      </div>
    </>
  );
};

export default AccountActivationFinalization;
