import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-regular-svg-icons';
import { Badge, Notifications } from './components';
import {
  AccentText,
  DefaultText,
  GridLink,
  InfoText,
  ProgressBar,
  AnimatedCounter,
  Avatar,
} from '../../../../../../../../..';
import * as Resources from '../../../../../../../../../../Resources';
import { useIntl } from 'react-intl';
import '../../../../../../../../../../helpers/NumberHelper';
import '../../../../../../../../../../helpers/StringHelper';
import * as accountDetailActions from '../../../../../../../../../../services/Account/AccountDetail/actions';
import { bindActionCreators } from 'redux';

const styles = {
  root: {
    marginLeft: 32,
    marginTop: 32,
    marginRight: 32,
  },
  avatar: {
    width: 72,
    height: 72,
  },
  infos: {
    marginTop: 8,
  },
  notificationContainer: {
    marginTop: 8,
  },
  notifications: {
    cursor: 'pointer',
  },
  menuLabel: {
    textTransform: 'none',
    fontSize: 14,
  },
};

const Account = ({ onNavigate, ...props }) => {
  const intl = useIntl();
  const { classes } = props;
  const { account } = props.accountDetail;
  const { count } = props.inAppNotificationCount;
  const isCollaboratorOrManager =
    account.role.code === 'C' || account.role.code === 'M';

  const isJti = account.isJtiEnv;
  const photo = account.photo ? account.photo : '/assets/img/user/avatar.svg';

  const percentage =
    isCollaboratorOrManager &&
    account.nextLevel &&
    account.rank &&
    account.level
      ? (
          (account.rank.points - account.level.points) /
          (account.nextLevel.points - account.level.points)
        ).toFullPercentage()
      : isCollaboratorOrManager &&
          !account.nextLevel &&
          account.rank &&
          account.level
        ? 100
        : 0;
  const [initialized, setInitialized] = React.useState(false);
  const [notificationOpen, setNotificationOpen] = React.useState(false);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      props.accountDetailActions.getAccountDetail();
    }
  });

  function handleNotificationClose() {
    setNotificationOpen(false);
    if (onNavigate) onNavigate();
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {isCollaboratorOrManager && !isJti && (
          <GridLink
            item
            component={Link}
            to={`/collaborators/${account.id}/detail`}
          >
            <Avatar
              src={photo}
              className={classes.avatar}
              onClick={onNavigate}
              entityId={account.id}
              fallbackName={account.fullname}
              fontSize={34}
            />
          </GridLink>
        )}
        {isCollaboratorOrManager && isJti && (
          <Grid item>
            <Avatar
              src={photo}
              className={classes.avatar}
              onClick={onNavigate}
              entityId={account.id}
              fallbackName={account.fullname}
              fontSize={34}
            />
          </Grid>
        )}
        {!isCollaboratorOrManager && (
          <Grid item>
            <Avatar
              src={photo}
              className={classes.avatar}
              entityId={account.id}
              fallbackName={account.fullname}
              fontSize={34}
            />
          </Grid>
        )}
        <Grid item xs zeroMinWidth>
          <div>
            <Grid container>
              {isCollaboratorOrManager && !isJti && (
                <Grid item xs>
                  <InfoText>
                    {intl
                      .formatMessage({ id: 'menu.level_label' })
                      .format(account?.level?.number)}
                  </InfoText>
                </Grid>
              )}
              {isCollaboratorOrManager && !isJti && (
                <Grid item>
                  <AccentText>
                    <AnimatedCounter
                      counter={account?.rank?.points || 0}
                      timer={750}
                      resource={intl.formatMessage({ id: 'menu.points_label' })}
                    />
                  </AccentText>
                </Grid>
              )}
              {isCollaboratorOrManager && !isJti && (
                <Grid item xs={12}>
                  <ProgressBar value={percentage} animate />
                </Grid>
              )}
              <GridLink
                item
                xs={12}
                component={Link}
                to='/account'
                onClick={onNavigate}
                className={classes.infos}
              >
                <AccentText>
                  <FontAwesomeIcon icon={faEdit} />
                  &nbsp;&nbsp;
                  <span className={classes.menuLabel}>
                    {intl.formatMessage({ id: 'menu.infos_button' })}
                  </span>
                </AccentText>
              </GridLink>
              <Grid
                item
                xs={12}
                className={classes.notificationContainer}
                onClick={() => setNotificationOpen(true)}
              >
                <DefaultText className={classes.notifications}>
                  <Badge badgeContent={count} color='secondary'>
                    <FontAwesomeIcon icon={faBell} />
                  </Badge>
                  &nbsp;&nbsp;&nbsp;
                  <span className={classes.menuLabel}>
                    {intl.formatMessage({ id: 'menu.notifications_button' })}
                  </span>
                </DefaultText>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Notifications
        open={notificationOpen}
        onClose={handleNotificationClose}
      />
    </div>
  );
};

const mapStateToProps = ({ accountDetail, inAppNotificationCount }) => ({
  accountDetail,
  inAppNotificationCount,
});

const mapDispatchToProps = dispatch => ({
  accountDetailActions: bindActionCreators(accountDetailActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Account));
