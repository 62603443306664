import React, { useState, useEffect } from 'react';
import { Button, Typography, withStyles } from '@material-ui/core';
import Formsy from 'formsy-react';
import { useIntl } from 'react-intl';
import {
  useUpdateContactsMutation,
  useGetContactsQuery,
} from '@src/data/api/endpoints/contacts';
import { useParams } from 'react-router-dom';
import { EmailInput } from './components';
import { toast } from 'react-toastify';

const styles = {
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '1.5rem',
  },
};

const emailTypes = ['SAV', 'TECHNICAL', 'SAV_EDENRED', 'TECHNICAL_EDENRED'];

const ContactsParameters = ({ classes }) => {
  const intl = useIntl();
  const [updateContacts] = useUpdateContactsMutation();

  const { contract } = useParams();

  const [emails, setEmails] = useState({
    SAV: [],
    TECHNICAL: [],
    SAV_EDENRED: [],
    TECHNICAL_EDENRED: [],
  });

  const { data: contactsData, isLoading: contactsIsLoading } =
    useGetContactsQuery({
      hierarchyNodeId: contract,
    });

  useEffect(() => {
    if (contactsData) {
      const fetchedEmails = {
        SAV: [],
        TECHNICAL: [],
        SAV_EDENRED: [],
        TECHNICAL_EDENRED: [],
      };
      contactsData.forEach(contact => {
        if (emailTypes.includes(contact.type)) {
          fetchedEmails[contact.type].push(contact.email);
        }
      });
      setEmails(fetchedEmails);
    }
  }, [contactsData]);

  const handleEmailAdd = type => (event, newValue) => {
    setEmails(prevEmails => ({
      ...prevEmails,
      [type]: newValue,
    }));
  };

  const handleEmailDelete = type => emailToDelete => {
    setEmails(prevEmails => ({
      ...prevEmails,
      [type]: prevEmails[type].filter(email => email !== emailToDelete),
    }));
  };

  const handleSubmit = async () => {
    const updatedContacts = [];
    for (const type of emailTypes) {
      const emailList = emails[type];
      emailList.forEach(email => {
        updatedContacts.push({ type, email });
      });
    }

    try {
      await updateContacts({
        hierarchyNodeId: contract,
        contacts: updatedContacts,
      });
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.points.update.success',
        }),
      );
    } catch (error) {
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.points.update.error',
        }),
      );
    }
  };

  return (
    <Formsy onValidSubmit={handleSubmit}>
      <Typography
        variant='h4'
        component={'h4'}
        style={{ marginBottom: '1rem' }}
      >
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.contacts.title',
        })}
      </Typography>
      <Typography variant={'body2'} style={{ marginBottom: '1rem' }}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.contacts.subtitle',
        })}
      </Typography>
      {emailTypes.map(type => (
        <EmailInput
          key={type}
          type={type}
          emailsByTypes={emails[type]}
          onChange={handleEmailAdd(type)}
          onDelete={handleEmailDelete(type)}
          contactsIsLoading={contactsIsLoading}
        />
      ))}
      <div className={classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          type={'submit'}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.saveButtonLabel',
          })}
        </Button>
      </div>
    </Formsy>
  );
};

export default withStyles(styles)(ContactsParameters);
