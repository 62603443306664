import React from 'react';
import { withRouter } from 'react-router-dom';
import Formsy, { withFormsy } from 'formsy-react';
import {
  MainLayoutComponent,
  Card,
  DefaultTitle,
  RoleFilter,
  DefaultText,
  TextField,
  ProgressButton,
  Loader,
  IconButton,
  Chip,
} from '../../../../components';
import { injectIntl } from 'react-intl';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import * as notificationListActions from '../../../../services/Notifications/NotifictionList/actions';
import * as notificationListUpdateActions from '../../../../services/Notifications/NotificationListUpdate/actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
  faBullseye,
  faComment,
  faLandmark,
  faRandom,
  faRocket,
  faTrophy,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import NECH from '../../../../assets/img/system/email/NECH.png';
import SWE from '../../../../assets/img/system/email/SWE.png';
import NPE from '../../../../assets/img/system/email/NPE.png';
import NROE from '../../../../assets/img/system/email/NROE.png';

const Checkbox = withFormsy(MuiCheckbox);
const styles = theme => {
  return {
    dialogCloseIcon: {
      color: 'white',
      width: 25,
      height: 25,
      top: 5,
      right: 5,
      fontSize: 20,
      zIndex: 100,
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
    filterChip: {
      fontSize: 10,
      background: 'transparent',
      color: '#333',
      borderColor: '#333',
      padding: '0 4px',
      height: 'auto',
      marginTop: '-3px',
      textTransform: 'none',
    },
  };
};

class AdminNotificatonsList extends MainLayoutComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChevronDown: false,
      chevrons: {
        openNotifsGoals: false,
        openNotifsChallenges: false,
        openNotifsBadges: false,
        openNotifsNewsFeed: false,
        openNotifsRankings: false,
        openNotifsGenerals: false,
      },
      notificationCheckboxes: {},
      inactivityDays: {},
      zoomedImage: null,
    };
    this.imageRefs = {};
  }
  // Toggles the chevron state for the specified section
  toggleChevron = chevronKey => {
    this.setState(prevState => {
      const newChevrons = {
        ...prevState.chevrons,
        [chevronKey]: !prevState.chevrons[chevronKey],
      };
      localStorage.setItem('chevronsState', JSON.stringify(newChevrons));

      return { chevrons: newChevrons };
    });
  };
  // Loads the list of notifications
  loadNotifications = () => {
    const { notificationListActions } = this.props;
    notificationListActions.getNotificationList();
  };

  toggleZoom = notificationCode => {
    this.setState(prevState => ({
      zoomedImage:
        prevState.zoomedImage === notificationCode ? null : notificationCode,
    }));
  };

  handleClickOutside = event => {
    if (
      this.state.zoomedImage &&
      this.imageRefs[this.state.zoomedImage] &&
      !this.imageRefs[this.state.zoomedImage].contains(event.target)
    ) {
      this.setState({ zoomedImage: null });
    }
  };

  componentDidMount() {
    const { intl } = this.props;

    this.props.handleTitle(intl.formatMessage({ id: 'admin.title' }));
    this.props.handleSubHeader(<RoleFilter />);
    this.props.handleMaxWidth('md');
    this.props.activateReturn();

    document.addEventListener('mousedown', this.handleClickOutside);

    const savedChevrons = localStorage.getItem('chevronsState');
    if (savedChevrons) {
      this.setState({ chevrons: JSON.parse(savedChevrons) });
    }
    this.loadNotifications();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  // Handles the change of the checkbox
  handleCheckboxChange = (notificationId, channel, isChecked) => {
    const { notifications } = this.props.notificationList;

    const EWM_IDS = notifications
      .filter(
        notificationValue =>
          notificationValue.notification.code === 'EWM' &&
          notificationValue.channel.code === 'P',
      )
      .map(v => v.id);

    this.setState(prevState => {
      let newState = { ...prevState.notificationCheckboxes };

      if (EWM_IDS.includes(notificationId)) {
        EWM_IDS.forEach(id => {
          newState[id] = {
            ...newState[id],
            [channel]: isChecked,
          };
        });
      } else {
        newState[notificationId] = {
          ...newState[notificationId],
          [channel]: isChecked,
        };
      }

      return { notificationCheckboxes: newState };
    });

    // Ensure inactivityDays is kept in sync
    const notification = notifications.find(n => n.id === notificationId);
    if (notification) {
      this.setState(prevState => ({
        inactivityDays: {
          ...prevState.inactivityDays,
          [notificationId]: notification.inactivity_days,
        },
      }));
    }
  };
  // Handles the change of inactivity days
  handleInactivityDaysChange = (notificationId, value) => {
    const { notifications } = this.props.notificationList;
    const notificationToUpdate = notifications.find(
      n => n.id === notificationId,
    );

    if (
      !notificationToUpdate ||
      notificationToUpdate.notification.code !== 'UINC'
    ) {
      return;
    }

    const relatedNotifications = notifications
      .filter(
        notification =>
          notification.notification.code === 'UINC' &&
          notification.notification.id === notificationToUpdate.notification.id,
      )
      .map(notification => notification.id);

    this.setState(prevState => {
      const updatedInactivityDays = { ...prevState.inactivityDays };
      relatedNotifications.forEach(id => {
        updatedInactivityDays[id] = value;
      });

      return {
        inactivityDays: updatedInactivityDays,
      };
    });
  };

  // Creates form control labels for the channels
  createFormControlLabels = (channels, notificationCode) => {
    const { intl } = this.props;
    const orderedKeys = ['IA', 'P', 'E'];
    const checkBoxNameMap = {
      IA: intl.formatMessage({
        id: 'admin.notifications_rights.title_in_app',
      }),
      P: intl.formatMessage({ id: 'admin.notifications_rights.title_push' }),
      E: intl.formatMessage({ id: 'admin.notifications_rights.title_email' }),
    };

    return (
      <>
        {orderedKeys
          .map(key => {
            const channel = channels[key];
            if (!channel) return null;

            const isDisabled = !channel.enabled;
            const currentCheck = this.state.notificationCheckboxes[channel.id];
            const defaultChecked = currentCheck
              ? currentCheck[key]
              : channel.value;
            return (
              <FormControlLabel
                label={checkBoxNameMap[key]}
                labelPlacement='top'
                control={
                  <Checkbox
                    key={`notification_value_${channel.id}`}
                    name={channel.id}
                    defaultChecked={defaultChecked}
                    onChange={e =>
                      this.handleCheckboxChange(
                        channel.id,
                        key,
                        e.target.checked,
                      )
                    }
                    disabled={isDisabled}
                  />
                }
              />
            );
          })
          .filter(Boolean)}
      </>
    );
  };
  // Renders notifications for a specific chevron section
  renderNotificationsForChevron = chevronKey => {
    const { intl } = this.props;
    const { notifications } = this.props.notificationList;
    const currentRole = new URLSearchParams(this.props.location.search).get(
      'current',
    );
    const filteredNotificationsByRole = notification => {
      const roleMap = ['C', 'M', 'A'];

      return notification.role.code === roleMap[currentRole];
    };

    const notificationCodeMappings = {
      // openNotifsGoals: [],
      openNotifsChallenges: ['NECH', 'NLP'],
      openNotifsBadges: ['NLB'],
      openNotifsNewsFeed: ['NPIN', 'UMN'],
      openNotifsRankings: ['RFR', 'RFCR', 'RFGR', 'TRFR', 'TRCR', 'TRGR'],
      openNotifsGenerals: ['MNL', 'EWM', 'NPE', 'SWE', 'NROE', 'UINC'],
    };
    const notificationExamples = {
      NLB: {
        text: [
          <>
            {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_NLB_part1' })}
  <Chip
    size='small'
    label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_badgeName' })}
    variant='outlined'
    className={this.props.classes.filterChip}
  />
  {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_NLB_part2' })}
  <Chip
    size='small'
    label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_rankLevel' })}
    variant='outlined'
    className={this.props.classes.filterChip}
  />
  {' '}!
          </>,
          <>
            {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_NLB_winner' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_winnerName' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_NLB_winnerPart2' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_badgeName' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_NLB_part2' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_rankLevel' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        {' '}!
          </>,
        ],
      },
      RFR: {
        text: [
          <>
           {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_RFR_part1' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_ranking_type' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        !
          </>,
          <>
            🏆 {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_RFR_winner' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_winnerName' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_RFR_part2' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_ranking_type' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        !
          </>,
        ],
      },
      RFCR: {
        text: [
          intl.formatMessage({ id: 'admin.notifications_rights.notification_text_RFCR' }),
          <>

             {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_RFCR_winner' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_winnerName' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_RFCR_part2' })}
      
          </>,
        ],
      },
      RFGR: {
        text: [
          intl.formatMessage({ id: 'admin.notifications_rights.notification_text_RFGR' }),

          <>

            {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_RFGR_winner' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_winnerName' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_RFGR_part2' })}
      
          </>,
        ],
      },
      MNL: {
        text: [
          <>
              {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_MNL_part1' })}
              <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_level' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        🔥

          </>,
          <>

             {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_MNL_winner' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_winnerName' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_MNL_part2' })}
        <Chip
          size='small'
          label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_level' })}
          variant='outlined'
          className={this.props.classes.filterChip}
        />
        🔥
          </>,
        ],
      },
      TRFR: {
        text: [
          <>
            {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_TRFR' })}
            <Chip
              size='small'
              label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_ranking_type' })}
              variant='outlined'
              className={this.props.classes.filterChip}
            />

          </>,
          <>
            {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_TRFR_winner' })}
            <Chip
              size='small'
              label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_teamName' })}
              variant='outlined'
              className={this.props.classes.filterChip}
            />
            » qui prend la tête du classement «
            <Chip
              size='small'
              label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_ranking_type' })}
              variant='outlined'
              className={this.props.classes.filterChip}
            />
            » 

          </>,
        ],
      },
      TRCR: {
        text: [
          <>
          {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_TRCR' })},
          </>,
          <>
          {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_TRCR_winner' })},
          <Chip
              size='small'
              label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_teamName' })}
              variant='outlined'
              className={this.props.classes.filterChip}
            />
            {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_TRCR_part2' })},
            </>
         

        ],
      },
      TRGR: {
        text: [
          <>
          {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_TRGR' })},
          </>,
          <>
          {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_TRGR_winner' })},
            <Chip
              size='small'
              label={intl.formatMessage({ id: 'admin.notifications_rights.notification_text_teamName' })}
              variant='outlined'
              className={this.props.classes.filterChip}
            />
                      {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_TRGR_part2' })},
          </>,
        ],
      },
      NECH: {
        text: [
          <>
          {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_NECH' })},
          </>,
        ],
        image: NECH,
      },
      NPIN: { text: [<>
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_NPIN' })},
        </>] },
      NLP: {
        text: [
          <>
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_NLP' })},
        </>,
        <>
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_NLP_winner' })},
        </>
        ],
      },
      UMN: { text: [       <>
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_UMN' })},
        </>] },
      EWM: { text: [<>
        {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_EWM' })},
        </>] },
      NPE: { image: NPE },
      UINC: {
        text: [<>
          {intl.formatMessage({ id: 'admin.notifications_rights.notification_text_UINC' })},
          </>],
      },
      SWE: {
        image: SWE,
      },
      NROE: {
        image: NROE,
      },
    };

    const notificationsByChevron = notifications
      ?.filter(filteredNotificationsByRole)
      ?.filter(notification =>
        notificationCodeMappings[chevronKey]?.includes(
          notification.notification.code,
        ),
      );

    const notificationCodes = [];
    let notificationValues = notificationsByChevron?.reduce(
      (acc, notification) => {
        if (!(notification.notification.code in acc)) {
          acc[notification.notification.code] = Object.assign(
            {},
            { description: notification.notification.description },
            { channels: {}, inactivity_days: notification.inactivity_days },
          );

          notificationCodes.push(notification.notification.code);
        }

        if (acc[notification.notification.code].inactivity_days === null) {
          acc[notification.notification.code].inactivity_days =
            notification.inactivity_days;
        }
        const new_channels = Object.assign(
          {},
          acc[notification.notification.code].channels,
          {
            [notification.channel.code]: {
              id: notification.id,
              value: notification.value,
              enabled: notification.enabled,
              inactivity_days: notification.inactivity_days,
            },
          },
        );

        const new_notification = Object.assign(
          {},
          acc[notification.notification.code],
          {
            channels: new_channels,
          },
        );
        return Object.assign({}, acc, {
          [notification.notification.code]: new_notification,
        });
      },
      {},
    );
    const { loading } = this.props.notificationList;
    if (!notificationsByChevron) {
      return <Loader centered />;
    }
    if (notificationsByChevron.length === 0) {
      return (
        <DefaultText lowercase>
          {intl.formatMessage({
            id: `admin.notifications_rights.no_notifications`,
          })}
        </DefaultText>
      );
    }
    const isMobile = isWidthDown('xs', this.props.width);
    return (
      <>
        {loading && <Loader centered />}
        <Grid container>
          {notificationCodes.map((notificationCode, index) => {
            const notification = notificationValues[notificationCode];
            const example = notificationExamples[notificationCode];
            return (
              <React.Fragment key={notificationCode}>
                <Grid
                  container
                  item
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <Grid
                    item
                    lg={8}
                    md={6}
                    sm={12}
                    style={{ position: 'relative', width: '100%' }}
                  >
                    <DefaultText lowercase style={{ fontSize: 16 }}>
                      {intl.formatMessage({
                        id: `admin.notifications_rights.notification_${notificationCode}`,
                      })}
                    </DefaultText>
                    {example && (
                      <>
                        {example.text && example.text.length > 0 && (
                          <DefaultText
                            lowercase
                            style={{ fontSize: 12, color: 'gray' }}
                          >
                            {intl.formatMessage({
                              id: `admin.notifications_rights.example_inapp_push_notification`,
                            })}
                            {Array.isArray(example.text) ? (
                              <ul>
                                {example.text.map((item, index) => (
                                  <li key={index}>{item}</li>
                                ))}
                              </ul>
                            ) : (
                              <p>{example.text}</p>
                            )}
                          </DefaultText>
                        )}
                        {example.image && (
                          <>
                            <DefaultText
                              lowercase
                              style={{ fontSize: 12, color: 'gray' }}
                            >
                              {intl.formatMessage({
                                id: `admin.notifications_rights.example_email`,
                              })}
                            </DefaultText>
                            <Grid
                              style={{
                                position: 'relative',
                                display: 'inline-block',
                              }}
                            >
                              <img
                                ref={el => {
                                  this.imageRefs[notificationCode] = el;
                                }}
                                src={example.image}
                                alt={`${notificationCode} example`}
                                style={{
                                  width:
                                    this.state.zoomedImage === notificationCode
                                      ? isMobile
                                        ? '100%'
                                        : '500px'
                                      : '100px',
                                  transform: 'scale(1)',
                                  height:
                                    this.state.zoomedImage === notificationCode
                                      ? 'auto'
                                      : '100px',

                                  objectFit: 'cover',
                                  overflow: 'hidden',
                                  transition: 'height 0.3s ease-in-out',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  this.toggleZoom(notificationCode)
                                }
                              />
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '0px',
                                  right: '0px',
                                  cursor: 'pointer',
                                  color: 'white',
                                }}
                                onClick={() =>
                                  this.toggleZoom(notificationCode)
                                }
                              >
                                {this.state.zoomedImage === notificationCode ? (
                                  <IconButton
                                    className={
                                      this.props.classes.dialogCloseIcon
                                    }
                                  >
                                    <FullscreenExitIcon />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    className={
                                      this.props.classes.dialogCloseIcon
                                    }
                                  >
                                    <FullscreenIcon />
                                  </IconButton>
                                )}
                              </div>
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                  <Grid item>
                    {this.createFormControlLabels(
                      notification.channels,
                      notificationCode,
                    )}
                  </Grid>
                  {notificationCode === 'UINC' && (
                    <Grid item lg={2} md={3} sm={12}>
                      <TextField
                        name={'inactivity_days'}
                        // name={`inactivity_days_${notification.channels.P.id}`}
                        type='number'
                        initial={notification.inactivity_days}
                        onChange={value =>
                          this.handleInactivityDaysChange(
                            notification.channels.P.id,
                            value,
                          )
                        }
                        label='jours'
                        style={{ marginLeft: '10px' }}
                      />
                    </Grid>
                  )}
                </Grid>
                {index < notificationCodeMappings[chevronKey].length - 1 && (
                  <hr
                    style={{
                      margin: '20px 0',
                      borderColor: '#FFFFFF',
                      width: '100%',
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Grid>
      </>
    );
  };
  // Handles the form submit action
  handleSubmit = () => {
    const { intl } = this.props;

    const combinedUpdates = {};

    Object.entries(this.state.notificationCheckboxes).forEach(
      ([id, channels]) => {
        combinedUpdates[id] = combinedUpdates[id] || {};
        combinedUpdates[id].value = Object.values(channels).some(
          value => value,
        );
      },
    );

    Object.entries(this.state.inactivityDays).forEach(([id, days]) => {
      combinedUpdates[id] = combinedUpdates[id] || {};
      combinedUpdates[id].inactivity_days = days;
    });

    // Ensure value is set for all updates
    Object.keys(combinedUpdates).forEach(id => {
      if (combinedUpdates[id].value === undefined) {
        const notification = this.props.notificationList.notifications.find(
          n => n.id.toString() === id,
        );
        if (notification) {
          combinedUpdates[id].value = notification.value;
        }
      }
    });
    const notificationToUpdate = Object.entries(combinedUpdates).map(
      ([id, updates]) => ({
        id,
        ...updates,
      }),
    );

    this.props.notificationListUpdateActions.updateNotificationList(
      notificationToUpdate,
    );
    this.props.history.replace('/admin');
    toast.success(
      intl.formatMessage({
        id: 'admin.notifications_rights.notification_update_success',
      }),
    );
  };

  render() {
    const { loading } = this.props.notificationList;
    const { intl } = this.props;
    const { chevrons } = this.state;
    const notificationTypes = [
      { icon: faBullseye, key: 'openNotifsGoals', titleId: 'admin.goal.title' },
      {
        icon: faRocket,
        key: 'openNotifsChallenges',
        titleId: 'challenge.title_plural',
      },
      {
        icon: faTrophy,
        key: 'openNotifsBadges',
        titleId: 'admin.home.badge_link',
      },
      {
        icon: faComment,
        key: 'openNotifsNewsFeed',
        titleId: 'newsfeed.title',
      },
      {
        icon: faRandom,
        key: 'openNotifsRankings',
        titleId: 'ranking.title',
      },
      {
        icon: faLandmark,
        key: 'openNotifsGenerals',
        titleId: 'admin.notifications_rights.global',
      },
    ];

    return (
      <>
        <Formsy onValidSubmit={this.handleSubmit}>
          <div>
            <DefaultTitle isContrast>
              {intl.formatMessage({ id: 'admin.notifications_rights.title' })}
            </DefaultTitle>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card>
                <DefaultTitle lowercase bold>
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.title_explication',
                  })}
                </DefaultTitle>
                <DefaultText lowercase>
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.explication_type',
                  })}
                </DefaultText>
                <DefaultText lowercase>
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.explication_push',
                  })}
                </DefaultText>
                <DefaultText lowercase>
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.explication_in_app',
                  })}
                </DefaultText>
                <DefaultText lowercase>
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.explication_email',
                  })}
                </DefaultText>
                <DefaultTitle lowercase bold>
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.remark_explication',
                  })}
                </DefaultTitle>
                <DefaultText lowercase style={{ paddingBottom: 10 }}>
                  {intl.formatMessage({
                    id: 'admin.notifications_rights.remark_explication_checkbox',
                  })}
                </DefaultText>
                {notificationTypes.map(
                  ({ icon, key, titleId}) => (
                    <Accordion
                      expanded={chevrons[key]}
                      onChange={() => this.toggleChevron(key)}
                      key={key}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container item alignItems='center'>
                          <FontAwesomeIcon
                            icon={icon}
                            style={{ paddingRight: 10 }}
                          />
                          <DefaultTitle lowercase bold>
                            {intl.formatMessage({ id: titleId })}
                          </DefaultTitle>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails>
                        {this.renderNotificationsForChevron(key)}
                      </AccordionDetails>
                    </Accordion>
                  ),
                )}
                <Grid item xs={12} style={{ paddingTop: 10 }}>
                  <ProgressButton
                    type='submit'
                    text={intl.formatMessage({ id: 'common.submit' })}
                    loading={loading}
                    centered
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Formsy>
      </>
    );
  }
}
const mapStateToProps = ({ notificationList, notificationListUpdate }) => ({
  notificationList,
  notificationListUpdate,
});

const mapDispatchToProps = dispatch => ({
  notificationListActions: bindActionCreators(
    notificationListActions,
    dispatch,
  ),
  notificationListUpdateActions: bindActionCreators(
    notificationListUpdateActions,
    dispatch,
  ),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withStyles(styles)(withWidth()(injectIntl(AdminNotificatonsList)))),
);
