import { useEffect, useState } from 'react';

/**
 * @param {{
 *   key: string,
 *   contextValue: any,
 *   changeContextValue: (formData: Object) => void,
 *   defaultFormData: Object,
 *   automaticInit: boolean,
 *   automaticSave: boolean,
 * }} props
 */
const UseSessionStorageSliceForm = ({
  key = 'sessionStorageData',
  contextValue,
  changeContextValue,
  defaultFormData = { onHandleFormChange: false },
  automaticInit = false,
  automaticSave = false,
}) => {
  const sessionStorageData = sessionStorage.getItem(key);
  const data =
    sessionStorageData !== null
      ? JSON.parse(sessionStorageData) !== null
        ? JSON.parse(sessionStorageData)
        : null
      : null;
  const [formData, setFormData] = useState(
    contextValue !== null
      ? contextValue
      : data !== null
        ? data
        : defaultFormData,
  );

  const setData = formData => {
    if (!formData) return;
    const { onHandleFormChange, ...formDataRest } = formData;
    sessionStorage.setItem(key, JSON.stringify(formDataRest));
  };

  const handleFormChange = e => {
    const { name, value, checked, type } = e.target;
    const nameParts = name.split('.');

    const targetValue = type === 'checkbox' ? checked : value;

    setFormData(prevState => {
      if (nameParts.length > 1) {
        return {
          ...prevState,
          [nameParts[0]]: {
            ...prevState[nameParts[0]],
            [nameParts[1]]: targetValue,
          },
          onHandleFormChange: true,
        };
      }

      return {
        ...prevState,
        [name]: targetValue,
        onHandleFormChange: true,
      };
    });
  };

  const initDefault = () => {
    // fill up the slice if the session storage is on
    if (contextValue === null && data !== null) {
      changeContextValue({ ...data, onHandleFormChange: false });
    } else if (contextValue !== null && data === null) {
      setData(contextValue);
    }
  };

  const saveStateData = (data, force = false) => {
    if (!force && (!automaticSave || !data || !data.onHandleFormChange)) return;

    changeContextValue(data);
    setData(data);
  };

  useEffect(() => {
    if (automaticInit) {
      initDefault();
    }
  }, [automaticInit]);

  useEffect(() => {
    saveStateData(formData);
  }, [formData, automaticSave]);

  const clearData = () => {
    sessionStorage.removeItem(key);
  };

  const clearSavedState = () => {
    changeContextValue(null);
    clearData();
  };

  return {
    handleFormChange,
    formData,
    initDefault,
    saveStateData,
    clearSavedState,
  };
};

export default UseSessionStorageSliceForm;
