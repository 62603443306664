import { useMemo } from 'react';
import { useAuth } from '../../auth';
/** @typedef {import('@async-calls/hierarchyNodeUsers').UserBody} UserBody */

export const useCurrentUser = () => {
  const auth = useAuth();
  if (!auth) {
    throw new Error('useCurrentUser doit être utilisé dans un AuthProvider');
  }
  /** @type {UserBody} */
  const user = useMemo(() => {
    if (!auth.hierarchyNodeUser) {
      throw new Error('Utilisateur non connecté');
    }
    return auth.hierarchyNodeUser;
  }, [auth.hierarchyNodeUser]);

  return user;
};
