import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';

import { GuestRoutes, UserRoutes } from './components';
import { UserRoles } from './enums';
import { IS_MAINTENANCE_MODE_ENABLED } from './global.constants';
import * as scenes from './scenes';
import { MaintenancePage, UnknownPage } from './scenes/ErrorPages';
import { AuthCallback } from '../auth';

export default () => {
  return (
    <BrowserRouter>
      <Switch>
        {IS_MAINTENANCE_MODE_ENABLED && (
          <GuestRoutes
            path='*'
            component={() => <MaintenancePage isPartialMaintenance={false} />}
          />
        )}
        {/* Auth routes */}
        <Redirect exact path='/' to='/nodes' />
        <GuestRoutes exact path='/callback' component={AuthCallback} />
        <UserRoutes
          exact
          path='/nodes'
          component={scenes.ContractSelection}
          useGuestLayout
          authorizedRoles={[
            UserRoles.COLLABORATOR,
            UserRoles.MANAGER,
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />

        {/* Home routes */}
        <UserRoutes
          exact
          path='/nodes/:contract/home'
          component={scenes.Home}
          authorizedRoles={[
            UserRoles.COLLABORATOR,
            UserRoles.MANAGER,
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />

        {/* Convert Point routes */}
        <UserRoutes
          exact
          path='/nodes/:contract/conversion-points'
          component={scenes.ConvertPointWrapper}
          authorizedRoles={[UserRoles.COLLABORATOR, UserRoles.MANAGER]}
        />
        <UserRoutes
          exact
          path='/nodes/:contract/informations-personnelles'
          component={scenes.ConvertPointWrapper}
          authorizedRoles={[UserRoles.COLLABORATOR, UserRoles.MANAGER]}
        />
        <UserRoutes
          exact
          path='/nodes/:contract/declaration-urssaf'
          component={scenes.ConvertPointWrapper}
          authorizedRoles={[UserRoles.COLLABORATOR, UserRoles.MANAGER]}
        />
        <UserRoutes
          exact
          path='/nodes/:contract/recapitulatif-commande'
          component={scenes.ConvertPointWrapper}
          authorizedRoles={[UserRoles.COLLABORATOR, UserRoles.MANAGER]}
        />

        {/* Users routes */}
        <UserRoutes
          path='/nodes/:contract/users/:user_id/infos'
          component={scenes.Users}
          authorizedRoles={[
            UserRoles.ADMINISTRATOR,
            UserRoles.MANAGER,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />
        <UserRoutes
          path='/nodes/:contract/users'
          component={scenes.Participants}
          authorizedRoles={[
            UserRoles.ADMINISTRATOR,
            UserRoles.MANAGER,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />
        <UserRoutes
          path='/nodes/:contract/users/liste'
          component={scenes.Participants}
          authorizedRoles={[
            UserRoles.ADMINISTRATOR,
            UserRoles.MANAGER,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />
        <UserRoutes
          path='/nodes/:contract/users/historique'
          component={scenes.Participants}
          authorizedRoles={[
            UserRoles.ADMINISTRATOR,
            UserRoles.MANAGER,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />
        {/* Orders routes */}
        <UserRoutes
          path='/nodes/:contract/orders'
          component={scenes.Orders}
          authorizedRoles={[
            UserRoles.COLLABORATOR,
            UserRoles.MANAGER,
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />
        <UserRoutes
          path='/nodes/:contract/orders/liste'
          component={scenes.Orders}
          authorizedRoles={[
            UserRoles.COLLABORATOR,
            UserRoles.MANAGER,
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />
        <UserRoutes
          path='/nodes/:contract/orders/exports'
          component={scenes.Orders}
          authorizedRoles={[
            UserRoles.COLLABORATOR,
            UserRoles.MANAGER,
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />
        {/* Points routes */}
        <UserRoutes
          path='/nodes/:contract/points'
          component={scenes.Points}
          authorizedRoles={[
            UserRoles.COLLABORATOR,
            UserRoles.MANAGER,
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />
        <UserRoutes
          path='/nodes/:contract/points/liste'
          component={scenes.Points}
          authorizedRoles={[
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />
        <UserRoutes
          path='/nodes/:contract/points/historique'
          component={scenes.Points}
          authorizedRoles={[
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />

        {/* Challenges routes */}
        <UserRoutes
          exact
          path='/nodes/:contract/challenges'
          component={scenes.Challenges}
          authorizedRoles={[UserRoles.COLLABORATOR, UserRoles.MANAGER]}
        />

        {/* Help routes */}
        <UserRoutes
          exact
          path='/nodes/:contract/help'
          component={scenes.Help}
          authorizedRoles={[UserRoles.COLLABORATOR, UserRoles.MANAGER]}
        />

        {/* Administration routes */}
        <UserRoutes
          path='/nodes/:contract/administration'
          component={scenes.Admin}
          authorizedRoles={[
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ]}
        />

        {/* Account activation routes */}
        <GuestRoutes
          exact
          path='/nodes/:contract/activate'
          component={scenes.AccountActivation}
        />
        <UserRoutes
          exact
          path='/nodes/:contract/finalize-activation'
          component={scenes.AccountActivationFinalization}
          useGuestLayout={true}
          hierarchyNodeUserRequired={false}
        />
        <GuestRoutes
          exact
          path='/nodes/:contract/account-activation-key-expired'
          component={scenes.AccountActivationKeyExpired}
        />
        <GuestRoutes
          exact
          path='/nodes/:contract/account-activation-status'
          component={scenes.AccountActivationStatus}
        />
        <GuestRoutes
          exact
          path='/nodes/:contract/inaccessible'
          component={scenes.ContractInaccessible}
        />
        <GuestRoutes
          exact
          path='/nodes/:contract/maintenance'
          component={() => <MaintenancePage isPartialMaintenance />}
        />

        {/* To keep at last position (404) */}
        <GuestRoutes path='*' component={UnknownPage} />
      </Switch>
    </BrowserRouter>
  );
};
