import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { useEffect, useMemo } from 'react';
import { useAuth } from '@src/auth';

export const useStepperDialogs = ({ goHome, currentBalanceTooLow }) => {
  const { hierarchyNodeUser } = useAuth();

  const pointBalanceRest = useMemo(() => {
    if (
      !hierarchyNodeUser.point_balance ||
      !hierarchyNodeUser.point_balance.available
    )
      return 0;
    return hierarchyNodeUser.point_balance.available;
  }, [hierarchyNodeUser]);

  const {
    open: openErrorBalanceDialog,
    onOpen: onOpenErrorBalanceDialog,
    onClose: onCloseErrorBalanceDialog,
  } = useDisclosure();
  const {
    open: openSuccessOrderDialog,
    onOpen: onOpenSuccessOrderDialog,
    onClose: onCloseSuccessOrderDialog,
  } = useDisclosure();

  const closeErrorBalanceDialog = () => {
    goHome();
    onCloseErrorBalanceDialog();
  };

  const closeSuccessOrderDialog = () => {
    goHome();
    onCloseSuccessOrderDialog();
  };

  useEffect(() => {
    if (currentBalanceTooLow) onOpenErrorBalanceDialog();
  }, [currentBalanceTooLow]);

  return {
    openErrorBalanceDialog,
    onOpenErrorBalanceDialog,
    closeErrorBalanceDialog,
    openSuccessOrderDialog,
    onOpenSuccessOrderDialog,
    closeSuccessOrderDialog,
    pointBalanceRest,
  };
};
