import React from 'react';
import { InformationContentBlock } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/InformationContentBlock';
import { useIntl } from 'react-intl';
import { InformationDetail } from '@src/Spider/scenes/ConvertPoints/components/components/SummaryComponents/InformationDetail';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';

const DeclarationSummary = () => {
  const intl = useIntl();
  const convertPointsContext = useConvertPoints();

  return (
    <>
      <InformationContentBlock
        title={intl.formatMessage({
          id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.title',
        })}
        first={true}
        showDivider={true}
      >
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.last_name',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.last_name)}
          value={convertPointsContext.declarationStepData?.last_name}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.first_name',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.first_name)}
          value={convertPointsContext.declarationStepData?.first_name}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.birth_name',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.birth_name)}
          value={convertPointsContext.declarationStepData?.birth_name}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.identification.email',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.email)}
          value={convertPointsContext.declarationStepData?.email}
        />
      </InformationContentBlock>
      <InformationContentBlock
        title={intl.formatMessage({
          id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.title',
        })}
        showDivider={true}
      >
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.line1',
          })}
          isError={Boolean(
            convertPointsContext.error?.lfss_form?.personal_address?.line1,
          )}
          value={
            convertPointsContext.declarationStepData?.personal_address?.line1
          }
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.line2',
          })}
          isError={Boolean(
            convertPointsContext.error?.lfss_form?.personal_address?.line2,
          )}
          value={
            convertPointsContext.declarationStepData?.personal_address?.line2
          }
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.postal_code',
          })}
          isError={Boolean(
            convertPointsContext.error?.lfss_form?.personal_address
              ?.postal_code,
          )}
          value={
            convertPointsContext.declarationStepData?.personal_address
              ?.postal_code
          }
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_address.city',
          })}
          isError={Boolean(
            convertPointsContext.error?.lfss_form?.personal_address?.city,
          )}
          value={
            convertPointsContext.declarationStepData?.personal_address?.city
          }
        />
      </InformationContentBlock>
      {convertPointsContext.declarationStepData?.employer_address && (
        <InformationContentBlock
          title={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.title',
          })}
          showDivider={true}
        >
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.company_name',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address
                ?.company_name,
            )}
            value={
              convertPointsContext.declarationStepData.employer_address
                ?.company_name
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.line1',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address?.line1,
            )}
            value={
              convertPointsContext.declarationStepData.employer_address?.line1
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.line2',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address?.line2,
            )}
            value={
              convertPointsContext.declarationStepData.employer_address?.line2
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.postal_code',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address
                ?.postal_code,
            )}
            value={
              convertPointsContext.declarationStepData.employer_address
                ?.postal_code
            }
          />
          <InformationDetail
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.declaration.employer_address.city',
            })}
            isError={Boolean(
              convertPointsContext.error?.lfss_form?.employer_address?.city,
            )}
            value={
              convertPointsContext.declarationStepData?.employer_address?.city
            }
          />
        </InformationContentBlock>
      )}
      <InformationContentBlock
        title={intl.formatMessage({
          id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.title',
        })}
      >
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.birth_date',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.birth_date)}
          value={convertPointsContext.declarationStepData?.birth_date}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.birth_department',
          })}
          isError={Boolean(
            convertPointsContext.error?.lfss_form?.birth_department,
          )}
          value={convertPointsContext.declarationStepData?.birth_department}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.birth_city',
          })}
          isError={Boolean(convertPointsContext.error?.lfss_form?.birth_city)}
          value={convertPointsContext.declarationStepData?.birth_city}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.birth_country',
          })}
          isError={Boolean(
            convertPointsContext.error?.lfss_form?.birth_country,
          )}
          value={convertPointsContext.declarationStepData?.birth_country}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.nationality_country',
          })}
          isError={Boolean(
            convertPointsContext.error?.lfss_form?.nationality_country,
          )}
          value={convertPointsContext.declarationStepData?.nationality_country}
        />
        <InformationDetail
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.summaryStep.declaration.personal_information.social_security_number',
          })}
          isError={Boolean(
            convertPointsContext.error?.lfss_form?.social_security_number,
          )}
          value={
            convertPointsContext.declarationStepData?.social_security_number
          }
        />
      </InformationContentBlock>
    </>
  );
};

export { DeclarationSummary };
