import instance from '../instance';
import PaginationHelper from './helper/paginationHelper';
import { baseApi } from '@src/store/api-query';
import { tagTypes } from '@src/store/api-tags';

const baseUrl = 'import-logs/';

const importLogsOld = {
  list() {
    return instance.get(baseUrl);
  },
  export(id) {
    return instance.get(`${baseUrl}${id}/export`);
  },
  hierarchyNodeList(teamId, types, pagination) {
    const params = new URLSearchParams();
    if (types && types.length > 0) {
      types.forEach(type => {
        params.append('types', type);
      });
    }
    return instance.get(`hierarchy-nodes/${teamId}/import-logs`, {
      params: PaginationHelper(pagination, params),
    });
  },
  createImport(teamId, importFile) {
    return instance.post(`hierarchy-nodes/${teamId}/import-logs/`, importFile);
  },
  exportLog(teamId, importLogId) {
    const params = new URLSearchParams();

    params.append('import_log', importLogId);

    return instance.get(`hierarchy-nodes/${teamId}/export-logs`, { params });
  },
};
/**@typedef {import('@spider:src/enums/importLogsTypes').ImportLogsTypesType} ImportLogsTypesType*/
/**
 * @typedef {Object} ListImportParams
 * @property {string} [hierarchyNodeId] Id du hierarchy node user qui fait la commande
 * @property {ImportLogsTypesType[]} [types] Les types d'import à récupérer
 * @property {Object} [pagination] Objet de pagination servant à la geston de la pagination avec PaginationHelper
 */

/**
 * @typedef {Object} CreateImportParams
 * @property {string} [hierarchyNodeId] Id du hierarchy node user qui fait la commande
 * @property {Object} [importFile] Le fichier à importer
 */

/**
 * @typedef {Object} ExportImportParams
 * @property {string} [hierarchyNodeId] Id du hierarchy node user qui fait la commande
 * @property {string} [importLogId] L'id du fichier à exporter'
 */

const importLogs = {
  /** @param {ListImportParams} queryParams */
  hierarchyNodeList({ hierarchyNodeId, types, pagination }) {
    const params = new URLSearchParams();
    if (types && types.length > 0) {
      types.forEach(type => {
        params.append('types', type);
      });
    }
    return instance.get(`hierarchy-nodes/${hierarchyNodeId}/import-logs`, {
      params: PaginationHelper(pagination, params),
    });
  },
  /** @param {CreateImportParams} queryParams */
  createImport({ hierarchyNodeId, fileFormData }) {
    return instance.post(
      `hierarchy-nodes/${hierarchyNodeId}/import-logs/`,
      fileFormData,
    );
  },
  /** @param {ExportImportParams} queryParams */
  exportLog({ hierarchyNodeId, fileId }) {
    const params = new URLSearchParams();

    params.append('import_log', fileId);

    return instance.get(`hierarchy-nodes/${hierarchyNodeId}/export-logs`, {
      params,
    });
  },
};

const importLogsQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<ListImportParams, typeof builder.query, 'api-query', {}>}*/
    getImportLogsList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => importLogs.hierarchyNodeList(params));
      },
      providesTags: result =>
        result.results
          ? result.results.map(importLog => ({
              type: tagTypes.importLog,
              id: importLog.uuid,
            }))
          : [tagTypes.importLog],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<CreateImportParams, typeof builder.mutation, 'api-query', {}>}*/
    createImportLog: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => importLogs.createImport(params));
      },
      invalidatesTags: [tagTypes.importLog],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<ExportImportParams, typeof builder.query, 'api-query', {}>}*/
    getExportImportLog: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => importLogs.exportLog(params));
      },
      extraOptions: { includeHeaders: true },
    }),
  }),
});

export const {
  useLazyGetImportLogsListQuery,
  useCreateImportLogMutation,
  useLazyGetExportImportLogQuery,
} = importLogsQueries;

export default importLogsOld;
