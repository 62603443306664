import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import useDirtyPristine from '@src/Spider/hooks/UseDirtyPristine';
import Button from '@spider:components/Button';
import Formsy from 'formsy-react';
import { useParams } from 'react-router-dom';
import { createTheme } from '@material-ui/core/styles';
import { Loader } from '@src/components';
import { toast } from 'react-toastify';
import {
  useGetContractDetailQuery,
  usePatchContractDetailMutation,
} from '@async-calls/contractDetails';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  platformId: {
    display: 'flex',
    flexDirection: 'column',
  },
  platformCode: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    [createTheme().breakpoints.up('md')]: {
      alignItems: 'center',
      flexDirection: 'row',
    },
  },
  platformCodeResult: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const Component = ({ ...props }) => {
  const intl = useIntl();
  const { dirtyChange, resetPristinePage } = useDirtyPristine();
  const { contract } = useParams();

  const { data, isFetching } = useGetContractDetailQuery({
    hierarchyNodeId: contract,
  });

  const [patchContractDetails, mutateResult] = usePatchContractDetailMutation({
    hierarchyNodeId: contract,
  });

  const [contractDetailsName, setContractDetailsName] = useState(data?.name);
  const contractDetailsNameLength = 128;

  const contractDetailsNameFieldChange = e => {
    dirtyChange();
    setContractDetailsName(e.target.value);
  };

  const savePlatformParameters = async () => {
    resetPristinePage();

    try {
      await patchContractDetails({
        hierarchyNodeId: contract,
        contractDetail: {
          customer_name: contractDetailsName,
        },
      }).unwrap();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.platform.update.success',
        }),
      );
    } catch (error) {
      console.error('Failed to update code:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.platform.update.error',
        }),
      );
    }
  };

  useEffect(() => {
    setContractDetailsName(data?.customer_name ?? '');
  }, [data]);

  return isFetching || patchContractDetails.isFetching ? (
    <Loader centered />
  ) : (
    <Formsy
      name={'parameter-platform'}
      onValidSubmit={savePlatformParameters}
      className={props.classes.root}
    >
      <Typography variant={'h4'} component={'h4'}>
        {intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.customerTitle',
        })}
      </Typography>

      <TextFieldFormsy
        name={'contractDetailsName'}
        value={contractDetailsName}
        label={intl.formatMessage({
          id: 'spider.hierarchy_node.platform_customization.parameters.contractDetails.customerNameLabel',
        })}
        variant={'outlined'}
        required
        style={{ width: '60%' }}
        validations={{
          maxLength: contractDetailsNameLength,
        }}
        validationErrors={{
          isDefaultRequiredValue: intl.formatMessage({
            id: 'common.form.required_error',
          }),
          maxLength: intl
            .formatMessage({
              id: 'common.form.max_length_custom_error',
            })
            .format(contractDetailsNameLength),
        }}
        onChange={contractDetailsNameFieldChange}
      />

      <div className={props.classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'small'}
          type={'submit'}
        >
          {intl.formatMessage({
            id: 'spider.hierarchy_node.platform_customization.parameters.platform.saveButtonLabel',
          })}
        </Button>
      </div>
    </Formsy>
  );
};

const ContractDetailsParameters = withStyles(styles)(Component);

export { ContractDetailsParameters };
