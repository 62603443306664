import React, { useState } from 'react';
import { PointCommandIds } from '@spider:src/enums';
import { MainRewardConvertCard } from '../MainRewardConvertCard';
import { RewardConvertCard } from '../RewardConvertCard';
import useCommandReward from '@spider:src/hooks/CommandReward/UseCommandReward';

const RewardCard = ({ reward, ...props }) => {
  const [isOpenRewardHelperAbout, setIsOpenRewardHelperAbout] = useState(false);
  const { ComponentDecoration, cardDetails, HelperAbout } =
    useCommandReward(reward);

  return (
    <React.Fragment>
      {reward.uuid === PointCommandIds.KSC && (
        <MainRewardConvertCard
          reward={reward}
          setOpenHelperAbout={setIsOpenRewardHelperAbout}
          {...props}
        />
      )}
      {reward.uuid !== PointCommandIds.KSC && (
        <RewardConvertCard
          reward={reward}
          cardDetails={cardDetails}
          ComponentDecoration={ComponentDecoration}
          setOpenHelperAbout={setIsOpenRewardHelperAbout}
          {...props}
        />
      )}

      {/*<RewardHelperAboutDialog title={'test'} setOpen={setIsOpenRewardHelperAbout} open={isOpenRewardHelperAbout}*/}
      {/*                         disableConvert={disableConvert} onConvert={props.openConvertPointView} AboutComponent={HelperAbout} />*/}
    </React.Fragment>
  );
};

export default RewardCard;
