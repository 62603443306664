import React, { useEffect, useMemo } from 'react';
import { withStyles } from '@mui/styles';
import { ConvertPointsStepper } from './components/ConvertPointsStepper';
import { Paper } from '@material-ui/core';
import { PointsStep } from './components/PointsStep';
import { InformationStep } from './components/InformationStep';
import { DeclarationStep } from './components/DeclarationStep';
import { SummaryStep } from './components/SummaryStep';
import useConvertPointsSteps from '@spider:src/hooks/UseConvertPointsSteps';
import { useHistory, useParams } from 'react-router-dom';
import useSessionStorageSliceForm from '@spider:src/hooks/UseSessionStorageSliceForm';
import useConvertPointsHelper from '@spider:src/hooks/CommandReward/UseConvertPointsHelper';
import { Loader } from '@src/components';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { SuccessOrderDialog } from '@src/Spider/scenes/ConvertPoints/components/components/SuccessOrderDialog';
import { ErrorBalanceOrderDialog } from '@src/Spider/scenes/ConvertPoints/components/components/ErrorBalanceOrderDialog';
import { useStepperSimulation } from '@src/Spider/hooks/useStepperSimulation';
import { useStepperValidity } from '@src/Spider/hooks/useStepperValidity';
import { useStepperDialogs } from '@src/Spider/hooks/useStepperDialogs';
import { ConversionStepsUrls } from '@src/Spider/enums/conversionStepsUrls';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
    marginRight: '1rem',
  },
  rootContent: {
    '&.MuiPaper-root': {
      boxShadow: 'none',
      padding: '1.5rem',
      borderRadius: '1.5rem',
    },
  },
};

const Component = ({ ...props }) => {
  const history = useHistory();
  const convertPointsContext = useConvertPoints();

  const { steps, baseUrl } = useConvertPointsSteps();

  const goHome = () => {
    history.push(`${baseUrl}home`);
  };

  const { currentBalanceTooLow, minimumPoints, maximumPoints } =
    useConvertPointsHelper();

  const { initDefault: initPointByDefault, saveStateData: savePointStateData } =
    useSessionStorageSliceForm({
      key: 'convertPointsStep',
      contextValue: convertPointsContext.pointStepData,
      changeContextValue: convertPointsContext.setPointStepData,
      automaticInit: true,
    });

  const { initDefault: initInformationByDefault } = useSessionStorageSliceForm({
    key: 'informationStep',
    contextValue: convertPointsContext.informationStepData,
    changeContextValue: convertPointsContext.setInformationStepData,
    automaticInit: true,
  });

  const { initDefault: initDeclarationByDefault } = useSessionStorageSliceForm({
    key: 'declarationStep',
    contextValue: convertPointsContext.declarationStepData,
    changeContextValue: convertPointsContext.setDeclarationStepData,
    automaticInit: true,
  });

  const { initDefault: initSummaryByDefault } = useSessionStorageSliceForm({
    key: 'summaryStep',
    contextValue: convertPointsContext.summaryStepData,
    changeContextValue: convertPointsContext.setSummaryStepData,
    automaticInit: true,
  });

  const {
    openSuccessOrderDialog,
    onOpenSuccessOrderDialog,
    closeSuccessOrderDialog,
    openErrorBalanceDialog,
    closeErrorBalanceDialog,
    pointBalanceRest,
  } = useStepperDialogs({ goHome, currentBalanceTooLow });
  const { saveInit } = useStepperSimulation({
    maximumPoints,
    savePointStateData,
  });
  const {
    previousStep,
    activeStep,
    checkStepValidity,
    orderRewardResult,
    goStep,
  } = useStepperValidity({ steps, goHome, onOpenSuccessOrderDialog });

  useEffect(() => {
    initPointByDefault();
    initInformationByDefault();
    initDeclarationByDefault();
    initSummaryByDefault();

    return () => {
      sessionStorage.removeItem('activeStep');
      sessionStorage.removeItem('reward');
      sessionStorage.removeItem('convertPointsStep');
      sessionStorage.removeItem('informationStep');
      sessionStorage.removeItem('declarationStep');
      sessionStorage.removeItem('summaryStep');
    };
  }, []);

  return (
    <div className={props.classes.root}>
      <ConvertPointsStepper
        steps={steps}
        activeStep={activeStep}
        previousStep={previousStep}
      />

      {saveInit &&
      activeStep !== null &&
      steps?.length > 0 &&
      steps[activeStep] &&
      !orderRewardResult.isFetching ? (
        <Paper className={props.classes.rootContent}>
          {steps[activeStep].src ===
            `${baseUrl}${ConversionStepsUrls.POINTS}` && (
            <PointsStep nextStep={checkStepValidity} />
          )}
          {steps[activeStep].src ===
            `${baseUrl}${ConversionStepsUrls.INFORMATION}` && (
            <InformationStep
              nextStep={checkStepValidity}
              previousStep={previousStep}
            />
          )}
          {steps[activeStep].src ===
            `${baseUrl}${ConversionStepsUrls.DECLARATION}` && (
            <DeclarationStep
              nextStep={checkStepValidity}
              previousStep={previousStep}
            />
          )}
          {steps[activeStep].src ===
            `${baseUrl}${ConversionStepsUrls.SUMMARY}` && (
            <SummaryStep
              nextStep={checkStepValidity}
              previousStep={previousStep}
              goStep={goStep}
            />
          )}
        </Paper>
      ) : (
        <Loader centered />
      )}
      <ErrorBalanceOrderDialog
        open={openErrorBalanceDialog}
        onClose={closeErrorBalanceDialog}
        minimumPoints={minimumPoints}
        rewardName={convertPointsContext.reward?.name}
      />
      <SuccessOrderDialog
        open={openSuccessOrderDialog}
        onClose={closeSuccessOrderDialog}
        points={convertPointsContext.pointStepData?.points}
        rewardName={convertPointsContext.reward?.name}
        pointBalanceRest={pointBalanceRest}
      />
    </div>
  );
};

const ConvertPoints = withStyles(styles)(Component);

export { ConvertPoints };
