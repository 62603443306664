import instance from '../instance';
import { baseApi } from '@src/store/api-query';

/**
 * @param {string} id ID du noeud hiérarchique en base de données
 */
const baseUrl = id => `reward-allocations/${id}/`;

const rewardAllocationsOld = {
  simulate(id, points, hierarchyNodeUserId) {
    return instance.post(`${baseUrl(id)}simulate/`, {
      points,
      hierarchy_node_user: hierarchyNodeUserId,
    });
  },
};

/**
 * @typedef {Object} RewardAllocationUpdateDto
 * @property {string} [status] Status de la reward allocation
 * @property {Array} [individuals_config] Configuration individuelle de la reward allocation
 * @property {Array} [legal_entities_config] Configuration individuelle de la reward allocation
 * @property {Array} [individuals_custom_image_id] Image de la configuration individuelle de la reward allocation
 * @property {Array} [legal_entities_custom_image_id] Image de la configuration individuelle de la reward allocation
 */

/**
 * @typedef {Object} RewardAllocationUpdate
 * @property {string} id ID de la reward allocation en base de données
 * @property {RewardAllocationUpdateDto} [rewardAllocation] Contenu de la reward allocation à modifier
 */

/**
 * @typedef {Object} RewardAllocationSimulation
 * @property {string} id ID de la reward allocation en base de données
 * @property {number} [points] Nombre de points pour simuler une commande
 * @property {string} [hierarchyNodeUserId] ID du user pour lequel faire la simulation
 */

/**
 * @typedef {Object} UpdateRewardAllocationCustomImage
 * @property {string} id ID de la reward allocation en base de données
 * @property {Object} file FormData de l'image a POST/PUT contenant le code et la src de l'image
 * @property {string} code Le code de l'image
 */

/**
 * @typedef {Object} DeleteRewardAllocationCustomImage
 * @property {string} id ID de la reward allocation en base de données
 * @property {string} imageId ID de l'image a supprimer
 */

/**
 * @typedef {Object} SimulateRewardAllocation
 * @property {string} id ID de la reward allocation en base de données
 * @property {number} points Le nombre de points à simuler
 * @property {string} hierarchyNodeUserId L'id du user à utiliser pour simuler les points
 */

const rewardAllocations = {
  /** @param {RewardAllocationUpdate} queryParams */
  update({ id, rewardAllocation }) {
    return instance.put(baseUrl(id), rewardAllocation);
  },
  /** @param {RewardAllocationUpdate} queryParams */
  patch({ id, rewardAllocation }) {
    return instance.patch(baseUrl(id), rewardAllocation);
  },
  /** @param {UpdateRewardAllocationCustomImage} queryParams */
  updateImage({ id, file, code }) {
    return instance.post(`${baseUrl(id)}custom-image/`, file, {
      params: {
        code,
      },
    });
  },
  /** @param {DeleteRewardAllocationCustomImage} queryParams */
  deleteImage({ id, imageId }) {
    return instance.delete(`${baseUrl(id)}custom-image/`, imageId);
  },
  /** @param {SimulateRewardAllocation} queryParams */
  simulate({ id, points, hierarchyNodeUserId }) {
    return instance.post(`${baseUrl(id)}simulate/`, {
      points,
      hierarchy_node_user: hierarchyNodeUserId,
    });
  },
};

const rewardAllocationsQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<SimulateRewardAllocation, typeof builder.query, 'api-query', {}>}*/
    simulateRewardAllocation: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => rewardAllocations.simulate(params));
      },
    }),
  }),
});

export const { useLazySimulateRewardAllocationQuery } =
  rewardAllocationsQueries;

export const { update, updateImage, patch, deleteImage, simulate } =
  rewardAllocations;
export default rewardAllocationsOld;
