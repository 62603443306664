import { createApi } from '@reduxjs/toolkit/query/react';
import { tagTypes } from './api-tags';
/**
 * @typedef {Object} UserDetails
 * @property {string} id
 * @property {string} name
 * // ... other properties
 */

export const baseApi = createApi({
  reducerPath: 'api-query',
  tagTypes: Object.values(tagTypes),
  /**
   * @param {Function} args
   * @param {any} api
   * @param {any} extraOptions
   */
  baseQuery: async (args, api, extraOptions) => {
    try {
      // args will be passed from the queryFn
      const result = await args();
      if (!extraOptions) {
        return { data: result.data };
      } else {
        return { data: { data: result.data, headers: result.headers } };
      }
    } catch (error) {
      /** @type {import('axios').AxiosError} */
      const axiosError = error;
      return {
        error: {
          status: axiosError.response?.status,
          data: axiosError.response?.data || axiosError.message,
        },
      };
    }
  },
  endpoints: () => ({}),
});
