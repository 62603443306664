import React from 'react';
import { Chip, Divider, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Building, InfoCircle } from 'iconsax-react';
import { withStyles } from '@mui/styles';
import { systemColors } from '@spider:src/themes';
import { Alert } from '../Alert';

const styles = {
  category: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
  },
  requirement: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  requirementGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1rem 0rem 1rem 0rem',
  },
  requirementWrap: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
  requirementWrapCategory: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingTop: '1rem',
  },
};

/**
 * @typedef {Object} Requirement
 * @property {string} title - Title of the requirement
 * @property {string} description - Description of the requirement
 * @property {boolean} required - Is the requirement required or not
 * @property {string} [category] - Is the requirement required or not
 */

/** @param {{helpAlertInformation: Array<string>, requirements: Array<Requirement>}} */
const RequirementHelp = ({ helpAlertInformation, requirements, ...props }) => {
  const intl = useIntl();

  const RenderRequirementContent = ({ requirement, index }) => {
    return (
      <div>
        {index > 0 && <Divider />}
        <div className={props.classes.requirementGroup}>
          <div className={props.classes.requirement}>
            <Typography style={{ fontWeight: 700 }}>
              {`${intl.formatMessage({ id: 'spider.importDialog.column' })} "${requirement.title}"`}
            </Typography>
            {requirement.required && (
              <Chip
                label={intl.formatMessage({
                  id: 'spider.importDialog.requiredStatus',
                })}
                className={'partial'}
              />
            )}
          </div>
          <Typography style={{ textAlign: 'left' }}>
            {requirement.description}
          </Typography>
        </div>
      </div>
    );
  };

  const RenderRequirements = () => {
    return requirements.map((requirement, index) => {
      if (
        requirements[index].category &&
        requirements[index].category !== requirements[index - 1]?.category
      ) {
        return (
          <div key={index} className={props.classes.requirementWrapCategory}>
            <div className={props.classes.category}>
              <Building color={systemColors.errorRegular} />
              {requirement.category}
            </div>
            <RenderRequirementContent requirement={requirement} index={0} />
          </div>
        );
      } else {
        return (
          <div key={index} className={props.classes.requirementWrap}>
            <RenderRequirementContent requirement={requirement} index={index} />
          </div>
        );
      }
    });
  };

  const AlertContent = () => {
    return (
      <div style={{ textAlign: 'left' }}>
        <ul>
          {helpAlertInformation.map((info, index) => {
            return <li key={index}>{info}</li>;
          })}
        </ul>
        {intl.formatMessage({
          id: 'spider.participants.helpImport.helpInfo.info',
        })}
      </div>
    );
  };

  return (
    <div>
      <Alert
        Icon={() => <InfoCircle />}
        title={intl.formatMessage({ id: 'spider.importDialog.helpInfo' })}
      >
        <AlertContent />
      </Alert>

      <RenderRequirements />
    </div>
  );
};

export default withStyles(styles)(RequirementHelp);
