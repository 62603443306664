import React from 'react';
import { Box, Typography } from '@material-ui/core';

/** @param {{date: Date}} props */
export const TimeAndDay = ({ date }) => {
  return (
    <Box>
      <Typography>
        {date
          .toDate()
          .toLocaleString('fr-FR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          })
          .replaceAll('/', '.')}
      </Typography>
      <Typography variant='h6'>
        {date.toDate().toLocaleString('fr-FR', {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Typography>
    </Box>
  );
};
