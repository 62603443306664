import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { Button } from '@spider:src/components';
import { ImportDialog } from '@spider:src/components/ImportDialog';
import { ImportLogsTypes } from '@spider:src/enums/importLogsTypes';
import { ImportLogsHistory } from '@spider:src/components/importLogsHistory';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { TitleContainer } from '@spider:src/components/TitleContainer';
import { PointsList } from '@spider:src/components/PointsList/PointsList';
import { PointsAdminUrl } from '@src/Spider/enums/pointsAdminUrl';
import { ParticipantsAdminUrl } from '@src/Spider/enums/participantsAdminUrl';
import { OwnerEnum } from '@src/Spider/enums/ownerType';
import { TabRoutes } from '@spider:src/components/TabRoutes';

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
    marginRight: '0.5rem',
  },
};

const AdminPoints = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const match = useRouteMatch();
  const { contract } = useParams();
  const [openImport, setOpenImport] = useState(false);

  const tabPaths = Object.values(PointsAdminUrl);

  const importPoints = () => {
    setOpenImport(true);
  };

  const redirect = options => {
    if (
      options.type === ImportLogsTypes.USERS &&
      history.location.pathname.includes('points')
    ) {
      history.push(`/nodes/${contract}/users/${ParticipantsAdminUrl.HISTORY}`);
    }
  };

  const routes = [
    {
      path: PointsAdminUrl.LIST,
      component: (
        <PointsList
          queryParams={{
            id: contract,
            owner_types: [OwnerEnum.HIERARCHY_NODE_USER],
          }}
        />
      ),
    },
    {
      path: PointsAdminUrl.HISTORY,
      component: (
        <ImportLogsHistory
          fixedCacheKey={'points'}
          queryParams={{
            hierarchyNodeId: contract,
            types: [ImportLogsTypes.POINTS],
          }}
          emptyLabelParams={intl.formatMessage({
            id: 'spider.importLog.dataTable.no_data_points',
          })}
        />
      ),
    },
  ];

  return (
    <Box className={props.classes.pointsBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.points.title' })}
        </Typography>
        <Button color={'primary'} variant={'outlined'} onClick={importPoints}>
          {intl.formatMessage({ id: 'spider.points.import' })}
        </Button>
      </TitleContainer>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
        }}
      >
        <TabRoutes
          basePath={match.path}
          baseUrl={match.url}
          tabPaths={tabPaths}
          tabNamespace='spider.points.tabs'
          routes={routes}
          defaultTab={PointsAdminUrl.LIST}
        />
      </Box>
      <ImportDialog
        open={openImport}
        setOpen={setOpenImport}
        onFileCreated={redirect}
        defaultTab={'1'}
        fixedCacheKey={'points'}
      />
    </Box>
  );
};

const Component = withStyles(styles)(AdminPoints);
export { Component as AdminPoints };
