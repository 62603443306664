import React from 'react';
import Button from '../Button';
import CloseIcon from '@mui/icons-material/Close';
const CloseButtonIcon = ({ onClick, className, ...props }) => {
  return (
    <Button
      variant={'contained'}
      color={'primary'}
      size={'small'}
      className={`icon light ${className}`}
      onClick={onClick}
      {...props}
    >
      <CloseIcon />
    </Button>
  );
};

export default CloseButtonIcon;
