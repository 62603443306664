import { useAuth } from '@src/auth';
import { useMemo } from 'react';
import { ParticipantType } from '../enums/participantType';

/**
 * @param {{
 *   reward: any,
 * }} props
 */
const UseRewardConfigAccess = ({ reward }) => {
  const { hierarchyNodeUser } = useAuth();

  const propConfigRequired = useMemo(() => {
    return hierarchyNodeUser.type === ParticipantType.MORALE
      ? 'legal_entities_required_config'
      : 'individuals_required_config';
  }, [reward, hierarchyNodeUser]);

  const propConfig = useMemo(() => {
    return hierarchyNodeUser.type === ParticipantType.MORALE
      ? 'legal_entities_config'
      : 'individuals_config';
  }, [reward, hierarchyNodeUser]);

  const isRewardAllocationExist = useMemo(
    () =>
      !!reward &&
      !!reward._context &&
      !!reward._context.allocation &&
      !!reward._context.allocation[propConfig],
    [reward],
  );

  const getContext = (required = false) => {
    return required
      ? reward[propConfigRequired]
      : reward._context.allocation[propConfig];
  };

  const getConfigByCode = (configCode, required = false) => {
    if (!isRewardAllocationExist) return null;

    return getContext(required).find(config => config.code === configCode);
  };

  const getConfigByCallback = (callback, required) => {
    if (!isRewardAllocationExist) return null;

    return getContext(required).find(callback);
  };

  return { isRewardAllocationExist, getConfigByCode, getConfigByCallback };
};

export default UseRewardConfigAccess;
