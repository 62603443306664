import React from 'react';
import { withStyles } from '@mui/styles';
import { createTheme, Link, Paper, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { mainKeyClear } from '../../../../features/main/slices';
import { useGetHierarchyNodeQuery } from '@async-calls/teamGroups';
import { Loader } from '@src/components';
import { useGetContractDetailQuery } from '@async-calls/contractDetails';

const styles = {
  root: {
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      boxShadow: 'none !important',
      width: '100%',
      overflow: 'auto',
      position: 'relative',
      padding: '0.5rem 1rem',
      borderRadius: 16,
      [createTheme().breakpoints.up('sm')]: {
        width: 288,
      },
    },
  },
  hierarchyNodeLogo: {
    height: '3.5rem',
    width: '3.5rem',
    objectFit: 'cover',
  },
  hierarchyNodeInfo: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  hierarchyNodeName: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  hierarchyNodeDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  hierarchyNodeAction: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const OperationDialog = ({ ...props }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const { contract } = useParams();

  const { data, isFetching } = useGetHierarchyNodeQuery({
    hierarchyNodeId: contract,
  });

  const contractDetailResult = useGetContractDetailQuery({
    hierarchyNodeId: contract,
  });

  const toNodes = () => {
    history.push('/nodes');
    dispatch(mainKeyClear());
  };

  return isFetching || contractDetailResult.isFetching ? (
    <Loader centered />
  ) : (
    <div className={props.classes.root}>
      <Paper>
        <div className={props.classes.hierarchyNodeInfo}>
          <img
            src={data?._context?.logo?.src ?? null}
            alt='platform-logo'
            className={props.classes.hierarchyNodeLogo}
          />
          <div className={props.classes.hierarchyNodeDetails}>
            <Typography
              variant={'h6'}
              component={'h4'}
              className={props.classes.hierarchyNodeName}
            >
              {data?.name}
            </Typography>
            <Typography>{contractDetailResult?.data?.customer_name}</Typography>
          </div>
        </div>

        <div className={props.classes.hierarchyNodeAction}>
          <Link
            className={'bold small'}
            onClick={toNodes}
            style={{ textDecoration: 'none' }}
          >
            {intl.formatMessage({
              id: 'spider.org.contract_selection.switch',
            })}
          </Link>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(OperationDialog);
