import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { ReactComponent as ErrorIcon } from '@spider:src/assets/img/error-icon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useIntl } from 'react-intl';

/**
 * @param {{
 *   open: boolean,
 *   onClose: () => {},
 *   points: string,
 *   rewardName: string,
 *   minimumPoints: string,
 * }} props
 */
const Component = ({ open, onClose, minimumPoints, rewardName }) => {
  const intl = useIntl();
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle disableTypography style={{ alignItems: 'baseline' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant={'contained'}
            color={'primary'}
            size={'small'}
            className={'icon light'}
            onClick={onClose}
          >
            <CloseIcon />
          </Button>
        </div>
        <ErrorIcon style={{ margin: 'auto' }} />
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-center'}
          style={{ textAlign: 'center' }}
        >
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.pointBalanceDialog.title',
          })}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {intl
            .formatMessage({
              id: 'spider.convertPoints.stepper.pointBalanceDialog.message',
            })
            .format(minimumPoints, rewardName ?? '')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          size={'small'}
          color={'primary'}
          variant={'contained'}
          onClick={onClose}
        >
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.pointBalanceDialog.closeAction',
          })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ErrorBalanceOrderDialog = Component;

export { ErrorBalanceOrderDialog };
