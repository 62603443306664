import React from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { useParams, useRouteMatch } from 'react-router-dom';
import { TitleContainer } from '@spider:src/components/TitleContainer';
import { OrdersList } from '@spider:src/components/OrdersList/OrdersList';
import { OwnerEnum } from '@spider:src/enums/ownerType';
import { OrdersAdminUrl } from '@spider:src/enums/ordersAdminUrl';
import { TabRoutes } from '@spider:src/components/TabRoutes';
import { ExportLogsList } from '@src/Spider/components/ExportLogsList/ExportLogsList';

const styles = {
  ordersBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
    marginRight: '0.5rem',
  },
};

const AdminOrders = ({ ...props }) => {
  const intl = useIntl();
  const match = useRouteMatch();
  const { contract } = useParams();

  const tabPaths = Object.values(OrdersAdminUrl);

  const routes = [
    {
      path: OrdersAdminUrl.LIST,
      component: (
        <OrdersList
          queryParams={{
            id: contract,
            owner_types: [OwnerEnum.HIERARCHY_NODE_USER],
          }}
        />
      ),
    },
    {
      path: OrdersAdminUrl.EXPORT,
      component: (
        <ExportLogsList
          queryParams={{
            hierarchyNodeId: contract,
          }}
        />
      ),
    },
  ];

  return (
    <Box className={props.classes.ordersBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.orders.title' })}
        </Typography>
      </TitleContainer>

      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
        }}
      >
        <TabRoutes
          basePath={match.path}
          baseUrl={match.url}
          tabPaths={tabPaths}
          tabNamespace='spider.orders.tabs.titles'
          routes={routes}
          defaultTab={OrdersAdminUrl.LIST}
        />
      </Box>
    </Box>
  );
};

const Component = withStyles(styles)(AdminOrders);

export { Component as AdminOrders };
