import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Button } from '@src/Spider/components';
import { getQueryParam } from '@src/helpers/UrlHelper';

import SuccessImg from '@src/Spider/assets/img/success.png';
import WarningImg from '@src/Spider/assets/img/warning.png';

const useStyles = makeStyles(() => ({
  root: {
    gap: '24px',
    padding: '48px',
  },
}));

export const AccountActivationStatus = () => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const location = useLocation();

  const status = getQueryParam(location.search, 'status');

  if (status !== 'success' && status !== 'error') {
    history.push('/404');
  }

  const success = status === 'success';
  const image = success ? SuccessImg : WarningImg;
  const titleId = success
    ? 'spider.auth.account_activation_status.success.title'
    : 'spider.auth.account_activation_status.error.title';
  const messageId = success
    ? 'spider.auth.account_activation_status.success.message'
    : 'spider.auth.account_activation_status.error.message';

  const goToContractSelection = () => {
    history.push('/nodes');
  };

  return (
    <>
      <Card elevation={0} className={classes.root}>
        <img src={image} alt={status} />

        <Typography variant='h1' component='h1' align={'center'}>
          {intl.formatMessage({
            id: titleId,
          })}
        </Typography>

        <Typography align={'center'}>
          {intl.formatMessage({
            id: messageId,
          })}
        </Typography>

        {success && (
          <Button
            color={'primary'}
            variant={'contained'}
            size={'large'}
            onClick={goToContractSelection}
          >
            {intl.formatMessage({
              id: 'spider.auth.account_activation_status.success.submit',
            })}
          </Button>
        )}
      </Card>
    </>
  );
};
