import React from 'react';
import { createTheme, Typography, useMediaQuery } from '@material-ui/core';
import { Loader } from '@src/components';
import { gradients, neutralColors } from '@src/Spider';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useIntl } from 'react-intl';
import { withStyles } from '@material-ui/styles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    justifyContent: 'center',
  },
  priceConverted: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '0.25rem',
    margin: 'auto',
    background: gradients.gradientLightRedRoseBackground,
  },
};

const Component = ({ classes }) => {
  const convertPointsContext = useConvertPoints();
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));
  const intl = useIntl();
  return (
    <div
      className={classes.root}
      style={{ paddingTop: isMobile ? '0rem' : '1.5rem' }}
    >
      {convertPointsContext.pointStepData && (
        <Typography
          variant={isMobile ? 'h4' : 'h2'}
          component={isMobile ? 'h4' : 'h2'}
          style={{ fontWeight: 'normal', textAlign: 'center' }}
        >
          {intl
            .formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.points.convertInfo',
            })
            .format(
              parseInt(
                convertPointsContext.pointStepData.points,
              ).toFormatNumber(false),
            )}
        </Typography>
      )}

      <div className={classes.priceConverted}>
        {convertPointsContext.isSimulateFetching ? (
          <Loader centered />
        ) : (
          <Typography
            variant={'h1'}
            component={'h1'}
            style={{
              fontSize: '3.5rem',
              fontWeight: 'bold',
              color: neutralColors.neutralWhite,
            }}
          >
            {intl
              .formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.price',
              })
              .format(
                convertPointsContext.simulation.order_suggested.monetary_value.toFormatNumber(),
              )}
          </Typography>
        )}
      </div>

      {convertPointsContext.reward && (
        <Typography
          variant={isMobile ? 'h4' : 'h2'}
          component={isMobile ? 'h4' : 'h2'}
          style={{ fontWeight: 'normal', textAlign: 'center' }}
        >
          {intl
            .formatMessage({
              id: 'spider.convertPoints.stepper.summaryStep.inConvert',
            })
            .format(convertPointsContext.reward?.name)}
        </Typography>
      )}
    </div>
  );
};

const PointsSummary = withStyles(styles)(Component);

export { PointsSummary };
