import React, { useMemo } from 'react';
import {
  useLocation,
  useHistory,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Tab, Tabs, Box } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useIntl } from 'react-intl';
import { useCurrentUser } from '@src/Spider/hooks/useCurrentUser';

export const TabRoutes = ({
  basePath,
  baseUrl,
  tabPaths,
  tabNamespace,
  routes,
  defaultTab,
}) => {
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { role } = useCurrentUser();

  const isAuthorizedForRole = route =>
    !route.authorizedRoles || route.authorizedRoles.includes(role);

  const tabs = useMemo(
    () =>
      tabPaths
        .filter((tab, i) => isAuthorizedForRole(routes[i]))
        .map((el, i) => ({
          label: intl.formatMessage({
            id: `${tabNamespace}.${el}`,
          }),
          index: i,
          url: el,
        })),
    [intl, tabPaths, tabNamespace, role, routes],
  );

  const pathSegments = location.pathname.split('/').filter(Boolean);
  const currentTab = pathSegments.pop();
  const currentTabIndex = tabPaths.indexOf(currentTab);
  const value = String(currentTabIndex !== -1 ? currentTabIndex : 0);

  const handleChange = (_, newValue) => {
    history.push(`${baseUrl}/${tabPaths[Number(newValue)]}`);
  };

  if (!tabPaths.includes(currentTab)) {
    return <Redirect to={`${baseUrl}/${defaultTab}`} />;
  }

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs onChange={handleChange} value={value}>
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              value={tab.index.toString()}
              disableFocusRipple
            />
          ))}
        </Tabs>
      </Box>
      <Switch>
        {routes
          .filter(route => isAuthorizedForRole(route))
          .map((route, index) => (
            <Route
              key={index}
              path={`${basePath}/${route.path}`}
              render={() => (
                <TabPanel value={tabs[index].index.toString()}>
                  {route.component}
                </TabPanel>
              )}
            />
          ))}
        <Route
          path='*'
          render={() => <Redirect to={`${baseUrl}/${defaultTab}`} />}
        />
      </Switch>
    </TabContext>
  );
};
