import React, { useState } from 'react';
import { Chip, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from '@mui/material';
import { Loader } from '@src/components';

const EmailInput = ({
  type,
  emailsByTypes,
  onChange,
  onDelete,
  contactsIsLoading,
}) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);
  const [errorDuplicated, setErrorDuplicated] = useState(false);

  const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

  const validateEmail = email => {
    if (!email) return false;
    return emailRegex.test(email);
  };
  const isDuplicate = email => {
    return emailsByTypes.includes(email);
  };
  const addEmail = email => {
    const updatedValue = Array.isArray(emailsByTypes) ? emailsByTypes : [];
    onChange(null, [...updatedValue, email]);
    setInputValue('');
    setError(false);
  };
  const handleKeyDown = event => {
    if (event.key === ' ' || event.key === 'Enter' || event.key === ';') {
      event.preventDefault();
      const trimmedValue = inputValue.trim();
      if (!trimmedValue) return;

      if (isDuplicate(trimmedValue)) {
        setErrorDuplicated(true);
        return;
      }

      if (validateEmail(trimmedValue)) {
        addEmail(trimmedValue);
      } else {
        setError(true);
      }
    }
  };

  const handleInputChange = (_, newValue) => {
    setInputValue(newValue);
    const trimmedValue = newValue.trim();
    if (emailRegex.test(trimmedValue)) {
      setError(false);
    }
  };

  const handleOnChange = (event, newValue) => {
    onChange(event, newValue);

    const hasInvalidEmails = newValue.some(email => !emailRegex.test(email));
    if (!hasInvalidEmails || newValue.length === 0) {
      setError(false);
    }
  };
  return (
    <>
      {contactsIsLoading ? (
        <Loader centered />
      ) : (
        <Autocomplete
          clearIcon={false}
          options={[]}
          freeSolo
          multiple
          value={emailsByTypes}
          onChange={handleOnChange}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={index}
                {...getTagProps({ index })}
                label={option}
                className='secondary-outline'
                deleteIcon={<CloseIcon fontSize='small' />}
                onDelete={() => onDelete(option)}
                style={{ margin: '0.25rem' }}
              />
            ))
          }
          renderInput={params => (
            <TextField
              {...params}
              style={{
                marginBottom: '1rem',
              }}
              variant={'outlined'}
              fullWidth
              error={error || errorDuplicated}
              helperText={
                error
                  ? intl.formatMessage({
                      id: 'spider.hierarchy_node.platform_customization.parameters.contacts.invalidEmail',
                    })
                  : errorDuplicated
                    ? intl.formatMessage({
                        id: 'spider.hierarchy_node.platform_customization.parameters.contacts.invalidEmailDuplicated',
                      })
                    : ''
              }
              onKeyDown={handleKeyDown}
              label={intl.formatMessage({
                id: `spider.hierarchy_node.platform_customization.parameters.contacts.${type}`,
              })}
            />
          )}
        />
      )}
    </>
  );
};

export default EmailInput;
