import React from 'react';
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { ArrowDown2 } from 'iconsax-react';
import { useIntl } from 'react-intl';

const RowsPerPageSelector = ({
  rowsPerPage,
  handleChangeRowsPerPage,
  rowsPerPageOptions = [5, 10, 25],
}) => {
  const intl = useIntl();

  return (
    <Box display='flex' justifyContent='flex-end' alignItems='center'>
      <Typography>
        {intl.formatMessage({
          id: 'spider.data_table.number_of_lines_displayed',
        })}
      </Typography>
      <FormControl>
        <Select
          labelId='rows-per-page-label'
          value={rowsPerPage}
          onChange={handleChangeRowsPerPage}
          disableUnderline
          IconComponent={ArrowDown2}
        >
          {rowsPerPageOptions.map((option, index) => {
            return (
              <MenuItem value={option} key={index}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RowsPerPageSelector;
