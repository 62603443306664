import React from 'react';
import { amountStyles } from '../helper/styles';
import { Typography } from '@material-ui/core';

const formatter = new Intl.NumberFormat('fr-FR', {
  useGrouping: true,
}).format;

export const Amount = ({ number }) => {
  const formattedNumber = formatter(number);
  return (
    <Typography style={amountStyles(number > 0)}>
      {number > 0 && '+'}
      {formattedNumber}
    </Typography>
  );
};
