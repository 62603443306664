import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  infoBox: {
    backgroundColor: '#EFF6FF',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    marginBottom: '1.5rem',
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1rem',
    marginBottom: '1rem',
  },
  iconTitle: {
    display: 'flex',
    gap: '0.5rem',
  },
  itemValue: {
    fontWeight: '700!important',
    fontSize: '1rem!important',
  },
});

/** @param {{title: string, icon: React.ReactNode, children: React.ReactNode, show: boolean}} props */
export function InfoCard({ title, icon: Icon, children, show = true }) {
  const intl = useIntl();
  const classes = useStyles();
  if (!show) return null;
  return (
    <Box className={classes.infoBox}>
      <Box className={classes.infoSection}>
        <Box className={classes.iconTitle}>
          <Icon />
          <Typography variant='h3'>
            {intl.formatMessage({
              id: title,
            })}
          </Typography>
        </Box>
        {children}
      </Box>
    </Box>
  );
}
