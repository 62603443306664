import React, { useEffect } from 'react';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { toast } from 'react-toastify';
import { useAuth } from '@src/auth';
import { useLazySimulateRewardAllocationQuery } from '@async-calls/rewardAllocations';
import { useIntl } from 'react-intl';

/**
 * @param {{
 *   maximumPoints: number,
 *   savePointStateData: (formData: Object, force: boolean) => void,
 * }} props
 */
export const useStepperSimulation = ({ maximumPoints, savePointStateData }) => {
  const intl = useIntl();
  const convertPointsContext = useConvertPoints();
  const { hierarchyNodeUser } = useAuth();

  const [saveInit, setSaveInit] = React.useState(false);

  const [simulate, simulationResult] = useLazySimulateRewardAllocationQuery();

  /**
   * @param {number} pointToConvert
   */
  const triggerSimulation = async pointToConvert => {
    if (!convertPointsContext.reward || !hierarchyNodeUser) return;

    try {
      const simulation = await simulate({
        id: convertPointsContext.reward._context.allocation.uuid,
        points: pointToConvert,
        hierarchyNodeUserId: hierarchyNodeUser.uuid,
      }).unwrap();
      convertPointsContext.setSimulation(simulation);
      convertPointsContext.setInitializeSimulation(true);
    } catch (error) {
      console.error('Failed to update status:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.convertPoints.stepper.simulate.error',
        }),
      );
      convertPointsContext.setSimulation(null);
    }
  };

  useEffect(() => {
    convertPointsContext.setIsSimulateFetching(simulationResult.isFetching);
  }, [simulationResult]);

  useEffect(() => {
    if (
      convertPointsContext.initializeSimulation ||
      convertPointsContext.simulation !== null
    )
      return;
    triggerSimulation(maximumPoints);
  }, [convertPointsContext.reward]);

  const handleSimulationReset = () => {
    if (
      saveInit &&
      (!convertPointsContext.pointStepData ||
        !parseInt(convertPointsContext.pointStepData.points))
    ) {
      convertPointsContext.setSimulation(null);
      return true;
    }
    return false;
  };

  const getPointToConvert = () => {
    if (convertPointsContext.pointStepData) {
      return parseInt(convertPointsContext.pointStepData.points);
    } else {
      return !convertPointsContext.simulation
        ? maximumPoints
        : convertPointsContext.simulation.max_points_usable;
    }
  };

  const handleInitialSave = pointToConvert => {
    if (!saveInit && !convertPointsContext.pointStepData) {
      savePointStateData({ points: pointToConvert }, true);
      setSaveInit(true);
    } else if (convertPointsContext.pointStepData) {
      setSaveInit(true);
    }
  };

  useEffect(() => {
    if (!convertPointsContext.initializeSimulation) return;

    if (handleSimulationReset()) return;

    let pointToConvert = getPointToConvert();
    handleInitialSave(pointToConvert);

    triggerSimulation(pointToConvert);
  }, [
    convertPointsContext.initializeSimulation,
    convertPointsContext.pointStepData,
  ]);

  return { saveInit };
};
