export const ConversionStepsUrls = Object.freeze({
  POINTS: 'conversion-points',
  INFORMATION: 'informations-personnelles',
  DECLARATION: 'declaration-urssaf',
  SUMMARY: 'recapitulatif-commande',
});

/**
 *  @typedef { ConversionStepsUrls[keyof ConversionStepsUrls] } ConversionStepsUrlsType - Urls for conversions steps
 * @exports { ConversionStepsUrlsType };
 */
