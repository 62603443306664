import React from 'react';
import { Box, withStyles, Typography, Link } from '@material-ui/core';
import { getIntlEnumLabel } from '@src/Spider/enums/utils';
import { useIntl } from 'react-intl';
/** @typedef {import('@async-calls/pointsTransactions').PointTransaction} PointTransaction */

const styles = {
  link: {
    color: 'blue',
    textDecoration: 'underline',
    backgroundColor: 'transparent',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
  },
};

/**
 *
 * @param {{transaction: PointTransaction, onOpenDrawer: (transaction: PointTransaction) => void}} props
 */
const Component = ({ transaction, onOpenDrawer }) => {
  const { formatMessage } = useIntl();
  const { sub_type, source } = transaction;
  switch (sub_type) {
    case 'EXPIRATION':
      return '-';
    case 'LOAD':
      return (
        <Box>
          <Typography>
            {formatMessage({
              id: getIntlEnumLabel('operation_type', sub_type.toLowerCase()),
            })}
          </Typography>
          {Boolean(source?.comment) && (
            <Typography>{source.comment}</Typography>
          )}
        </Box>
      );
    case 'ORDER':
      return (
        <Box>
          <Typography>
            {formatMessage({
              id: getIntlEnumLabel('operation_type', sub_type.toLowerCase()),
            })}
          </Typography>
          {Boolean(source?.name) && (
            <Typography
              style={styles.link}
              component='button'
              variant='subtitle1'
              onClick={() => onOpenDrawer(transaction)}
            >
              <span>Ref </span>
              <span>{source.name}</span>
            </Typography>
          )}
        </Box>
      );
    case 'ORDER_CANCELLATION':
      return (
        <Box>
          <Typography>
            {formatMessage({
              id: getIntlEnumLabel('operation_type', sub_type.toLowerCase()),
            })}
          </Typography>
          {Boolean(source?.name) && (
            <Link>
              <Typography
                style={styles.link}
                component='button'
                variant='subtitle1'
                onClick={() => onOpenDrawer(transaction)}
              >
                <span>Ref </span>
                <span>{source.name}</span>
              </Typography>
            </Link>
          )}
        </Box>
      );
    case 'WITHDRAWAL':
      return (
        <Box>
          <Typography>
            {formatMessage({
              id: getIntlEnumLabel('operation_type', sub_type.toLowerCase()),
            })}
          </Typography>
        </Box>
      );
    default:
      return <Typography>{sub_type}</Typography>;
  }
};

const SubType = withStyles(styles)(Component);
export { SubType };
