import { createTheme } from '@material-ui/core/styles';
import { neutralColors } from '../colors';

const typographyStyles = configObject => {
  return {
    typography: {
      color: neutralColors.neutral900,
      fontFamily: 'Ubuntu',
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      h1: {
        fontWeight: 700,
        fontSize: '2rem',
        lineHeight: '40px',
        color: neutralColors.neutral900,
        position: 'relative',
        display: 'inline-block',
        [createTheme().breakpoints.down('xs')]: {
          fontSize: 24,
        },
        '&.underline-center::after': {
          content: '""',
          background: configObject.mainColor.value,
          borderRadius: '90px',
          display: 'block',
          height: '4px',
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '8px',
          width: '30px',
        },
        '&.underline-left::after': {
          content: '""',
          position: 'absolute',
          left: 0,
          bottom: '-4px',
          width: '80px',
          height: '2px',
          background: configObject.mainColor.value,
        },
      },
      h2: {
        color: neutralColors.neutral900,
        fontFamily: 'Ubuntu',
        fontSize: '1.5rem',
        fontStyle: 'normal',
        fontWeight: 700,
      },
      h3: {
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: '24px',
        color: neutralColors.neutral900,
        position: 'relative',
        display: 'inline-block',
      },
      h4: {
        fontWeight: 700,
        fontSize: '1.125rem',
        lineHeight: '16px',
        color: neutralColors.neutral900,
        position: 'relative',
        display: 'inline-block',
        '&.underline-left::after': {
          content: '""',
          background: configObject.mainColor.value,
          borderRadius: '40px',
          display: 'block',
          height: '4px',
          marginRight: 'auto',
          marginTop: '8px',
          width: '30px',
        },
      },
      h5: {
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '16px',
        color: neutralColors.neutral900,
      },
      h6: {
        fontWeight: 400,
        fontSize: '0.625rem',
        lineHeight: '12px',
        color: neutralColors.neutral900,
      },
      subtitle1: {
        fontSize: '1rem',
      },
      subtitle2: {
        color: neutralColors.neutral700,
        fontSize: '1rem',
      },
      body1: {
        fontSize: '0.875rem',
      },
      body2: {
        fontSize: '0.75rem',
      },
    },
  };
};

export default typographyStyles;
