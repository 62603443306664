import React from 'react';
import image from '../../../assets/img/avatar.png';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { getFullName } from '@src/helpers/StringHelper';

/** @typedef {import('@async-calls/hierarchyNodeUsers').UserBody} UserBody */

const styles = {
  container: {
    display: 'flex',
    gap: '.75rem',
    alignItems: 'center',
  },
  image: {
    width: '2.8rem',
    height: '2.8rem',
  },
};

/**  @param {{owner: UserBody}} props */
const ParticipantAvatar = ({ owner, classes }) => {
  const { first_identifier, email } = owner;

  return (
    <Box className={classes.container}>
      <img src={image} className={classes.image} />
      <div>
        <Typography variant='h5'>{first_identifier}</Typography>
        <Typography variant='h6'>{getFullName(owner)}</Typography>
        <Typography variant='h6'>{email}</Typography>
      </div>
    </Box>
  );
};

const Participant = withStyles(styles)(ParticipantAvatar);

export { Participant };
