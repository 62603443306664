import React from 'react';
import avatarPhysique from '@spider:src/assets/img/avatar.png';
import avatarMorale from '@spider:src/assets/img/avatar-personne-morale.png';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { getFullName } from '@src/helpers/StringHelper';
/** @typedef {import('@async-calls/pointsTransactions').PointTransaction} PointTransaction */

const styles = {
  container: {
    display: 'flex',
    gap: '.75rem',
    alignItems: 'center',
  },
  image: {
    width: '2.8rem',
    height: '2.8rem',
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  email: {
    fontSize: '0.75rem',
  },
};
/**
 * @param {{transaction: PointTransaction}} props
 */
const ParticipantAvatar = ({ transaction, classes }) => {
  const { owner } = transaction || {};
  const image =
    owner.owner_type === 'HIERARCHY_NODE_USER' ? avatarPhysique : avatarMorale;

  return (
    <Box className={classes.container}>
      <img src={image} className={classes.image} />
      <div>
        <Typography variant='h5' style={{ fontSize: '1rem' }}>
          {owner?.first_identifier}
        </Typography>
        <Typography>{getFullName(owner)}</Typography>
        <Typography className={classes.email}>{owner?.email}</Typography>
      </div>
    </Box>
  );
};

const Participant = withStyles(styles)(ParticipantAvatar);

export { Participant };
