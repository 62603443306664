import React, { useEffect, useMemo, useState } from 'react';
import {
  PointCommandIds,
  RewardAllocationConfigurationCodes,
} from '@spider:src/enums';
import { useAuth } from '@src/auth';
import useRewardConfigAccess from '@spider:src/hooks/UseRewardConfigAccess';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';

const UseConvertPointsHelper = () => {
  const { hierarchyNodeUser } = useAuth();
  const convertPointsContext = useConvertPoints();

  const { isRewardAllocationExist, getConfigByCode, getConfigByCallback } =
    useRewardConfigAccess({
      reward: convertPointsContext.reward,
    });

  const [minimumPoints, setMinimumPoints] = useState(null);
  const [maximumPoints, setMaximumPoints] = useState(
    hierarchyNodeUser.point_balance
      ? hierarchyNodeUser.point_balance.available
      : null,
  );
  const [currentBalanceTooLow, setCurrentBalanceTooLow] = React.useState(false);

  const isMultipleCard = useMemo(() => {
    return (
      convertPointsContext.simulation?.order_suggested?.items &&
      convertPointsContext.simulation?.order_suggested?.items?.length > 1
    );
  }, [convertPointsContext.simulation]);

  const maximumPerCard = useMemo(() => {
    const configuration = getConfigByCode(
      RewardAllocationConfigurationCodes.MAXIMUM_AMOUNT,
    );
    return configuration ? configuration.value : null;
  }, [convertPointsContext.reward]);

  useEffect(() => {
    if (!isRewardAllocationExist || !convertPointsContext.simulation) return;
    let minimumPointsChanged = 0;

    const ckuCallback = config =>
      convertPointsContext.reward?.uuid === PointCommandIds.CKU &&
      !hierarchyNodeUser.cku
        ? config.code ===
          RewardAllocationConfigurationCodes.MINIMUM_CREATION_AMOUNT
        : config.code ===
          RewardAllocationConfigurationCodes.MINIMUM_LOADING_AMOUNT;

    if (getConfigByCallback(ckuCallback, true)) {
      minimumPointsChanged += getConfigByCallback(ckuCallback).value;
    }

    if (
      getConfigByCode(RewardAllocationConfigurationCodes.MINIMUM_AMOUNT, true)
    ) {
      minimumPointsChanged += getConfigByCode(
        RewardAllocationConfigurationCodes.MINIMUM_AMOUNT,
      ).value;
    }

    setMinimumPoints(minimumPointsChanged);
    setMaximumPoints(convertPointsContext.simulation.max_points_usable);
  }, [
    convertPointsContext.reward,
    hierarchyNodeUser,
    convertPointsContext.simulation,
  ]);

  useEffect(() => {
    if (!isRewardAllocationExist) return;

    if (
      !hierarchyNodeUser.point_balance ||
      hierarchyNodeUser.point_balance.available < minimumPoints
    ) {
      setCurrentBalanceTooLow(true);
      return;
    }

    setCurrentBalanceTooLow(false);
  }, [hierarchyNodeUser, minimumPoints]);

  return {
    minimumPoints,
    maximumPoints,
    isMultipleCard,
    maximumPerCard,
    currentBalanceTooLow,
    isRewardAllocationExist,
  };
};

export default UseConvertPointsHelper;
