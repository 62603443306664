import React from 'react';
import { Checkbox } from '@material-ui/core';
import { withFormsy } from 'formsy-react';

/**
 * @param {{
 *   isPristine: boolean,
 *   isValid: boolean,
 *   errorMessage: string,
 *   helperText: string,
 * }} props
 */
const Component = ({ ...props }) => {
  const hasError = !props.isPristine && !props.isValid;
  const helperText = hasError ? props.errorMessage : props.helperText;
  return <Checkbox {...props} error={hasError} helperText={helperText} />;
};

const CheckboxFormsy = withFormsy(Component);

export { CheckboxFormsy };
