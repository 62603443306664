/**
 * @template T
 * @typedef {Object} RoleOptions
 * @property {T} [super_administrator] - Value to return when user role is super_administrator
 * @property {T} [administrator] - Value to return when user role is admin
 * @property {T} [collaborator] - Value to return when user role is collaborator
 * @property {T} [manager] - Value to return when user role is manager
 * @property {T} [default] - Default value to return when role doesn't match or is undefined
 */

import { useCurrentUser } from './useCurrentUser';

/**
 * A custom hook that returns different values based on the current user's role
 *
 * @template T
 * @param {RoleOptions<T>} roleOptions - An object containing values for different roles
 * @returns {T | undefined} The value corresponding to the current user's role, or the default value if specified
 *
 * @example
 * // Example usage:
 * const value = useSelectDataFromRole({
 *   super_administrator: 'Super Admin Value',
 *   administrator: 'Admin Value',
 *   collaborator: 'Collaborator Value',
 *   manager: 'Manager Value',
 *   default: 'Default Value'
 * });
 */
export const useSelectDataFromRole = roleOptions => {
  const { role } = useCurrentUser();

  return roleOptions[role.toLowerCase()] ?? roleOptions.default;
};
