import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import Button from '@spider:src/components/Button';
import { useIntl } from 'react-intl';
import { CloseButtonIcon } from '@spider:src/components/CloseButtonIcon';

const PreventLeavingWithoutSavingDialog = ({
  leaveWithoutRegister,
  submitModifications,
  onDialogClose,
  ...props
}) => {
  const intl = useIntl();
  return (
    <Dialog {...props} style={{ width: '550px', margin: 'auto' }}>
      <DialogTitle style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <CloseButtonIcon onClick={onDialogClose} />
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
          padding: '0rem 2rem 2rem 2rem',
          marginX: 'auto',
          gap: '1rem',
        }}
      >
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-center'}
        >
          {intl.formatMessage({ id: 'spider.preventSaveDialog.title' })}
        </Typography>

        <Typography variant={'subtitle2'}>
          {intl.formatMessage({ id: 'spider.preventSaveDialog.subtitle' })}
        </Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            gap: '1rem',
          }}
        >
          <Button
            variant={'outlined'}
            size={'small'}
            onClick={leaveWithoutRegister}
          >
            {intl.formatMessage({ id: 'spider.preventSaveDialog.leaveAction' })}
          </Button>
          <Button
            variant={'contained'}
            color={'primary'}
            size={'small'}
            onClick={submitModifications}
          >
            {intl.formatMessage({ id: 'spider.preventSaveDialog.saveAction' })}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PreventLeavingWithoutSavingDialog;
