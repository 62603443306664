import React from 'react';
import { Box, createTheme, makeStyles, Typography } from '@material-ui/core';
import { neutralColors } from '@src/Spider';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  title: {
    fontSize: '1.25rem',
    fontWeight: '700',
  },
  value: {
    fontWeight: '700',
  },
  label: {
    fontSize: '0.75rem',
  },
  addressCity: { display: 'flex', gap: '0.25rem' },
});

/**
 * @typedef {Object} Props
 * @property {string} title
 * @property {import('react').ReactNode} [icon]
 * @property {import('react').ReactNode} [action]
 * @property {boolean} [column]
 */

/**
 * @typedef {import('react').PropsWithChildren<Props>} Cardprops
 */

/**
 * @param {Cardprops} props
 * @returns {JSX.Element}
 */
const Card = ({ children, action, title, icon, column = false }) => {
  const intl = useIntl();
  const styles = useStyles();

  return (
    <Box
      sx={{
        backgroundColor: neutralColors.neutralColdWhite,
        padding: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          {icon}
          <Typography className={styles.title}>
            {intl.formatMessage({ id: title })}
          </Typography>
        </Box>
        {action}
      </Box>
      <Box
        sx={
          !column
            ? {
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                [createTheme().breakpoints.down('md')]: {
                  gridTemplateColumns: 'repeat(2, 1fr)',
                },
                [createTheme().breakpoints.down('sm')]: {
                  gridTemplateColumns: 'repeat(1, 1fr)',
                },
                gap: '1rem',
                gridAutoRows: 'auto',
              }
            : {
                display: 'flex',
                flexDirection: 'column',
                gap: '0.75rem',
              }
        }
      >
        {children}
      </Box>
    </Box>
  );
};

/** @param {{label: string, value: React.ReactNode}} props */
const Item = ({ label, value: Value }) => {
  const styles = useStyles();
  return (
    <Box>
      {typeof Value === 'string' ? (
        <Typography className={styles.value}>{Value}</Typography>
      ) : (
        Value || '--'
      )}
      <Typography>{label}</Typography>
    </Box>
  );
};

/** @param {{address: import('@async-calls/hierarchyNodeUsers').LegalEntityAddress}} props */
const AddressValue = ({ address }) => {
  const styles = useStyles();
  if (!address) return '--';
  return (
    <Box>
      {address?.line1 && (
        <Typography className={styles.value}>{address?.line1}</Typography>
      )}
      {address?.line2 && (
        <Typography className={styles.value}>{address?.line2}</Typography>
      )}
      <Box className={styles.addressCity}>
        <Typography className={styles.value}>{address?.postal_code}</Typography>
        <Typography className={styles.value}>{address?.city}</Typography>
      </Box>
    </Box>
  );
};

export const Info = {
  Card,
  Item,
  AddressValue,
};
