import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import UsePagination from '../../hooks/UsePagination';
import { Participant } from './components/Participant';
import { PointsBalance } from './components/PointsBalance';
import HierarchyNodeUserStatus from '../../components/HierarchyNodeUserStatus';
import EmptyTableImage from '../../assets/img/empty-table-participants.png';
import { EmptyTableView } from '../EmptyTableView';
import { Button } from '@src/Spider/components';
import { ArrowRight } from 'iconsax-react';
import { Link } from 'react-router-dom';
import { useLazyGetHierarchyNodeUsersListQuery } from '@src/data/api/endpoints/participants';
import { ParticipantUrl } from '@src/Spider/enums/participantUrl';
import { useSelectDataFromRole } from '@src/Spider/hooks/useSelectDataFromRole';
import { AuthorizedRolesWrapper } from '../AuthorizedRolesWrapper';
import { UserRoles } from '@src/Spider/enums';
import { DataList } from '../DataList';
import { TimeAndDay } from './components/TimeAndDay';
/** @typedef {import('@async-calls/participants').ParticipantListParams} ParticipantListParams */

/** @param {{queryParams: ParticipantListParams}} props */
const ParticipantsList = ({ queryParams }) => {
  const intl = useIntl();
  const [fetch, { data, isFetching, error }] =
    useLazyGetHierarchyNodeUsersListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });
  const columns = useSelectDataFromRole({
    super_administrator: [
      'creation_participant',
      'participant',
      'points',
      'approved_rules_validation',
      'status',
      'responsable',
    ],
    administrator: [
      'creation_participant',
      'participant',
      'points',
      'approved_rules_validation',
      'status',
      'responsable',
    ],
    manager: [
      'creation_participant',
      'participant',
      'approved_rules_validation',
      'status',
      'responsable',
    ],
  }).map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.participants.tabs.list.table.${el}`,
    }),
  }));

  useEffect(() => {
    fetch({
      ...queryParams,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const ParticipantRow = React.memo(({ item: user, classes }) => (
    <TableRow key={user.uuid}>
      <TableCell>
        <TimeAndDay date={user.created_at} />
      </TableCell>
      <TableCell>
        <Participant owner={user} />
      </TableCell>
      <AuthorizedRolesWrapper roles={[UserRoles.ADMINISTRATOR]}>
        <TableCell>
          <PointsBalance point_balance={user.point_balance} />
        </TableCell>
      </AuthorizedRolesWrapper>
      <TableCell>
        {user?.approval ? <TimeAndDay date={user.approval} /> : '--'}
      </TableCell>
      <TableCell>
        <HierarchyNodeUserStatus status={user.status} />
      </TableCell>
      <TableCell>
        {user?.manager ? <Participant owner={user.manager} /> : '--'}
      </TableCell>
      <TableCell className={classes.stickyColumn}>
        <Button
          variant='text'
          color='primary'
          size='small'
          component={Link}
          to={`/nodes/${queryParams.id}/users/${user.uuid}/infos/${ParticipantUrl.DETAILS}`}
        >
          <ArrowRight />
        </Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <DataList
      hasAction
      columns={columns}
      data={data?.results}
      count={data?.count}
      RowComponent={ParticipantRow}
      isFetching={isFetching}
      error={error}
      pagination={pagination}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      paginationCount={paginationCount}
      emptyState={
        <EmptyTableView
          imageSrc={EmptyTableImage}
          imageAlt='spider.participants.tabs.list.table.no-data.image-alt'
          title='spider.participants.tabs.list.table.no-data.title'
          description='spider.participants.tabs.list.table.no-data.description'
        />
      }
    />
  );
};

export { ParticipantsList };
