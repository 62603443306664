import { FileUploader } from 'react-drag-drop-files';
import React, { useState } from 'react';
import Button from '../Button';
import { DocumentUpload, Document as DocumentIcon } from 'iconsax-react';
import { useIntl } from 'react-intl';
import { systemColors } from '../../themes';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  customFileInput: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    border: 'dashed 2px',
    borderColor: systemColors.infoDark,
    borderRadius: '1rem',
    padding: '3rem',
    textAlign: 'center',
    cursor: 'pointer',
    '&:focus-within': {
      outline: 'none !important',
    },
    opacity: ({ disabled }) => (disabled ? 0.3 : 1),
  },
  customFileInputSuccess: {
    backgroundColor: systemColors.successBg,
    borderColor: '#025A35 !important',
  },
  dropZoneSuccess: {
    color: '#025A35 !important',
  },
  customFileInputError: {
    backgroundColor: '#FFEEED',
    borderColor: '#BD0D00 !important',
  },
  dropZoneError: {
    color: '#BD0D00 !important',
  },
  customFileInputIcon: {
    color: systemColors.infoDark,
    marginBottom: '1rem',
  },
  customFileInputLabel: {
    fontWeight: 600,
    color: systemColors.infoDark,
    marginBottom: '0.5rem',
  },
  customFileInputFileType: {
    marginBottom: '1rem',
  },
  customInfoError: {
    marginBottom: '1rem',
  },
});

const FileInputDragAndDrop = ({
  fileOrFilesChanged,
  multiple = false,
  authorizedFileTypes = ['JPG', 'PNG'],
  maxSize = 2,
  minSize = 0,
  customInfo = null,
  customError = null,
  disabled = false,
}) => {
  const intl = useIntl();
  const [fileOrFiles, setFileOrFiles] = useState(multiple ? [] : null);
  const [error, setError] = useState(null);
  const usableFileType = authorizedFileTypes;

  const classes = useStyles({
    disabled,
  });

  const usableFileTypeStringify = usableFileType
    .map(fileType => `.${fileType.toLowerCase()}`)
    .join(', ');

  const handleChange = fileOrFilesItem => {
    setError(null);
    const newFileOrFiles = multiple ? [...fileOrFilesItem] : [fileOrFilesItem];
    fileOrFilesChanged(newFileOrFiles);
    setFileOrFiles(previousFiles => {
      return multiple ? [...previousFiles, ...newFileOrFiles] : newFileOrFiles;
    });
  };

  const handleErrorType = error => {
    setFileOrFiles([]);
    fileOrFilesChanged(null);
    setError(error);
  };

  const handleSizeError = sizeError => {
    setFileOrFiles([]);
    fileOrFilesChanged(null);
    setError(sizeError);
  };

  const dropZoneClassName = () => {
    const baseClass = classes.customFileInputIcon;

    if (error) {
      return `${baseClass} ${classes.dropZoneError}`;
    }

    if (fileOrFiles && fileOrFiles.length > 0) {
      return `${baseClass} ${classes.dropZoneSuccess}`;
    }

    return baseClass;
  };

  const CustomDropZoneArea = () => {
    return (
      <div style={{ opacity: disabled ? 0.3 : 1 }}>
        <div className={dropZoneClassName()}>
          {fileOrFiles && fileOrFiles.length > 0 ? (
            <DocumentUpload />
          ) : (
            <DocumentIcon />
          )}
        </div>

        {!error && (!fileOrFiles || fileOrFiles.length === 0) && (
          <div className={classes.customFileInputLabel}>
            {intl.formatMessage({
              id: 'spider.systemFile.fileUpload.dragAndDropLabel',
            })}
          </div>
        )}

        {fileOrFiles && fileOrFiles.length > 0 && (
          <div style={{ color: '#025A35', fontStyle: 'italic' }}>
            {fileOrFiles.map(file => file.name).join(', ')}
          </div>
        )}

        {fileOrFiles && fileOrFiles.length > 0 && (
          <div style={{ color: '#025A35', fontWeight: 'bold' }}>
            {intl.formatMessage({
              id: 'spider.systemFile.fileUpload.documentImportedSuccess',
            })}
          </div>
        )}

        {error && (
          <div style={{ color: '#BD0D00', fontWeight: 'bold' }}>{error}</div>
        )}

        {(((!fileOrFiles || fileOrFiles.length === 0) && customInfo) ||
          (error && customError)) && (
          <div className={classes.customInfoError}>
            {error ? customError : customInfo}
          </div>
        )}

        {!customInfo && (
          <div className={classes.customFileInputFileType}>
            {intl.formatMessage({
              id: 'spider.systemFile.fileUpload.fileTypesLabel',
            })}{' '}
            {usableFileTypeStringify}
          </div>
        )}

        <Button color={'primary'} variant={'contained'} size={'small'}>
          {error || (fileOrFiles && fileOrFiles.length > 0)
            ? intl.formatMessage({
                id: 'spider.systemFile.fileUpload.importActionLabelOnExistingFile',
              })
            : intl.formatMessage({
                id: 'spider.systemFile.fileUpload.importActionLabel',
              })}
        </Button>
      </div>
    );
  };

  return (
    <FileUploader
      name='file'
      classes={`${classes.customFileInput} ${error ? classes.customFileInputError : fileOrFiles && fileOrFiles.length > 0 ? classes.customFileInputSuccess : ''}`}
      fileOrFiles={fileOrFiles}
      types={usableFileType}
      multiple={multiple}
      maxSize={maxSize}
      minSize={minSize}
      disabled={disabled}
      onTypeError={handleErrorType}
      onSizeError={handleSizeError}
      handleChange={handleChange}
    >
      <CustomDropZoneArea />
    </FileUploader>
  );
};

export default FileInputDragAndDrop;
