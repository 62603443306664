import React from 'react';
import { createTheme, withStyles } from '@material-ui/core/styles';
import Formsy from 'formsy-react';
import { Button } from '@src/Spider/components';
import { useIntl } from 'react-intl';
import { neutralColors, systemColors } from '@src/Spider/themes';
import { Typography } from '@material-ui/core';
import { ReactComponent as PointDeclaration } from '@src/Spider/assets/img/points-declaration.svg';
import useSessionStorageSliceForm from '@src/Spider/hooks/UseSessionStorageSliceForm';
import { useAuth } from '@src/auth';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { useFormHelper } from '@src/Spider/hooks/useFormHelper';
import { BaseAddressForm } from '@spider:components/Form/BaseAddressForm';
import { LFSSPersonalInformationForm } from '@src/Spider/scenes/ConvertPoints/components/components/DeclarationComponents/LFSSPersonalInformationForm';
import { IdentificationForm } from '@src/Spider/scenes/ConvertPoints/components/components/DeclarationComponents/IdentificationForm';
import { RequiredCheckboxCheck } from '@spider:components/RequiredCheckboxCheck';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formFields: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [createTheme().breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  informationCard: {
    display: 'flex',
    padding: '1rem',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'center',
    borderRadius: '1rem',
    overflow: 'clip',
    position: 'relative',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  block: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
};

/**
 * @param {{
 *   nextStep: (stepKeys: Array<string>) => void,
 *   previousStep: () => void,
 *   classes: any
 * }} props
 */
const Component = ({ nextStep, previousStep, classes }) => {
  const intl = useIntl();
  const { hierarchyNodeUser } = useAuth();
  const convertPointsContext = useConvertPoints();
  const stepKeys = ['lfss_form'];

  const defaultFormData = {
    honored_attestation: false,
    first_name: hierarchyNodeUser.firstname,
    last_name: hierarchyNodeUser.lastname,
    birth_name: '',
    email: hierarchyNodeUser.email,

    birth_date: '',
    birth_department: '',
    birth_city: '',
    birth_country: '',
    nationality_country: '',
    social_security_number: '',

    personal_address: {
      line1: '',
      line2: '',
      postal_code: '',
      city: '',
    },

    employer_address: {
      line1: '',
      line2: '',
      postal_code: '',
      city: '',
      company_name: '',
    },
  };

  const { formData, handleFormChange, saveStateData, clearSavedState } =
    useSessionStorageSliceForm({
      key: 'declarationStep',
      defaultFormData,
      contextValue: convertPointsContext.declarationStepData,
      changeContextValue: convertPointsContext.setDeclarationStepData,
    });

  const { formRef, isSubmitButtonDisabled, onValid, onInvalid, formChange } =
    useFormHelper({
      isFormDefaultDirty: !!convertPointsContext.declarationStepData,
      clearSavedState,
      saveStateData,
      onFormChange: convertPointsContext.setErrorOnFormChange,
      errorHook: useConvertPoints,
    });

  return (
    <Formsy
      ref={formRef}
      name={'declaration-form'}
      className={classes.root}
      onValidSubmit={() => nextStep(stepKeys)}
      onValid={() => onValid(formData, true)}
      onInvalid={onInvalid}
      onChange={() => formChange(stepKeys)}
    >
      <div className={classes.rootTitle}>
        <PointDeclaration
          fill={neutralColors.neutral900}
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
        <Typography variant={'h3'} component={'h3'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.title',
          })}
        </Typography>
      </div>

      <Typography variant={'body1'} style={{ fontStyle: 'italic' }}>
        {intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.subtitle',
        })}
      </Typography>

      <div className={classes.informationCard}>
        <Typography
          variant={'subtitle1'}
          style={{ color: systemColors.infoRegular, fontWeight: 'bold' }}
        >
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.infoLfssTitle',
          })}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant={'body2'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageOne',
            })}
          </Typography>
          <Typography variant={'body2'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageTwo',
            })}
          </Typography>
          <Typography variant={'body2'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageThird',
            })}
          </Typography>
        </div>
      </div>

      <div className={classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.identification.title',
          })}
        </Typography>
        <IdentificationForm
          formData={formData}
          error={convertPointsContext?.error?.lfss_form}
          classes={classes}
          handleFormChange={handleFormChange}
        />
      </div>
      <div className={classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.personalAddress.title',
          })}
        </Typography>
        <BaseAddressForm
          formData={formData.personal_address}
          baseProps={'personal_address'}
          error={convertPointsContext?.error?.lfss_form?.personal_address}
          classes={classes}
          handleFormChange={handleFormChange}
        />
      </div>
      <div className={classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.personalInformation.title',
          })}
        </Typography>
        <LFSSPersonalInformationForm
          formData={formData}
          error={convertPointsContext?.error?.lfss_form}
          classes={classes}
          handleFormChange={handleFormChange}
        />
      </div>
      <div className={classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.employerAddress.title',
          })}
        </Typography>
        <BaseAddressForm
          formData={formData.employer_address}
          baseProps={'employer_address'}
          error={convertPointsContext?.error?.lfss_form?.employer_address}
          classes={classes}
          handleFormChange={handleFormChange}
          showCompanyName={true}
        />
      </div>

      <RequiredCheckboxCheck
        name={'honored_attestation'}
        formData={formData.honored_attestation}
        error={convertPointsContext.error?.lfss_form?.honored_attestation}
        handleFormChange={handleFormChange}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.honorAttestation',
        })}
      />

      <div className={classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'large'}
          onClick={previousStep}
        >
          {intl.formatMessage({ id: 'spider.common.back' })}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'large'}
          type={'submit'}
          disabled={isSubmitButtonDisabled}
        >
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </Button>
      </div>
    </Formsy>
  );
};

const DeclarationStep = withStyles(styles)(Component);

export { DeclarationStep };
