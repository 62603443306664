import React from 'react';
import { ActionModal } from '@src/Spider/components/ActionModal';
import { OrderStatuses } from '@src/Spider/enums';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { useUpdateOrderStatusMutation } from '@src/data/api/endpoints/rewards';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { Close } from '@material-ui/icons';
import { Button } from '@spider:src/components';

/**
 * @typedef {Object} ChangeStatusDialogProps
 * @property {{}} order
 */

/**
 * Dialog component for changing user status
 * @param {ChangeStatusDialogProps} props
 * @returns {JSX.Element}
 */
export const CancelOrderDialog = ({ order }) => {
  const intl = useIntl();
  const { onClose, onOpen, open } = useDisclosure();
  const [updateStatus, { isLoading }] = useUpdateOrderStatusMutation();
  if (
    [OrderStatuses.CANCELLED, OrderStatuses.COMPLETED].includes(order?.status)
  )
    return null;

  /**
   * Handles the status update operation
   * @returns {Promise<void>}
   */
  const handleUpdate = async () => {
    try {
      await updateStatus({
        uuid: order?.uuid,
        status: OrderStatuses.DELETED,
      }).unwrap();
      onClose();
      toast.success(
        intl.formatMessage({
          id: 'spider.orders.tabs.list.modal.update.success',
        }),
      );
    } catch (error) {
      // Handle error
      console.error('Failed to update status:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.orders.tabs.list.modal.update.error',
        }),
      );
      onClose();
    }
  };
  return (
    <>
      <Button variant='text' size='small' onClick={onOpen}>
        <Close color='error' />
      </Button>
      <ActionModal
        open={open}
        handleClose={onClose}
        title='spider.orders.tabs.list.modal.title'
        description='spider.orders.tabs.list.modal.description'
        descriptionVariables={{
          productName: order?.items?.[0]?.reward_allocation.reward.name,
          orderRef: order?.number,
        }}
        confirmButtonText='spider.orders.tabs.list.modal.confirm'
        cancelButtonText='spider.orders.tabs.list.modal.cancel'
        onConfirm={handleUpdate}
        isLoading={isLoading}
      />
    </>
  );
};
