import React from 'react';
import { Route } from 'react-router-dom';

import { GuestLayout } from './index';

const GuestRoutes = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => <GuestLayout component={Component} {...props} />}
    />
  );
};

export default GuestRoutes;
