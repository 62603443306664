import { baseApi } from '@src/store/api-query';
import instance from '../instance';

const baseUrl = 'auth/';

const auth = {
  verifyAccountActivationKey(key, contract) {
    const url = `${baseUrl}verify-account-activation-key/`;
    return instance.post(url, {
      key: key,
      contract: contract,
    });
  },
  /**
   * @typedef {{key: string, contract:string}} AccountActivationBody
   * @typedef {{requestBody: AccountActivationBody}} AccountActivationParams
   * @param {AccountActivationParams} params
   * @returns any
   */
  activateAccount({ requestBody }) {
    return instance.post(`${baseUrl}activate-account/`, {
      ...requestBody,
    });
  },
  resendAccountActivationKey(key, contract) {
    const url = `${baseUrl}resend-account-activation-key/`;
    return instance.post(url, {
      key: key,
      contract: contract,
    });
  },
};

const authQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<AccountActivationParams, typeof builder.mutation, 'api-query', AccountActivationResult>}*/
    postActivateAccount: builder.mutation({
      queryFn: async (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => auth.activateAccount(params));
      },
    }),
  }),
});

export const { usePostActivateAccountMutation } = authQueries;

export default auth;

// TYPES

/**
 * @typedef {Object} AccountActivationResult
 * @property {string} message Message de retour en cas de succès
 * */
