import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactComponent as PointCup } from '@spider:src/assets/img/points-cup.svg';
import { ReactComponent as PointSummary } from '@spider:src/assets/img/points-summary.svg';
import { ReactComponent as PointUser } from '@spider:src/assets/img/points-user.svg';
import { ReactComponent as PointDeclaration } from '@spider:src/assets/img/points-declaration.svg';
import { neutralColors } from '../themes';
import { useAuth } from '@src/auth';
import { PointCommandIds } from '../enums';
import { useConvertPoints } from '@src/Spider/hooks/useConvertPoints';
import { ConversionStepsUrls } from '@src/Spider/enums/conversionStepsUrls';

const UseConvertPointsSteps = () => {
  const { hierarchyNodeUser } = useAuth();
  const { contract } = useParams();
  const convertPointsContext = useConvertPoints();
  const baseUrl = useMemo(() => `/nodes/${contract}/`, [contract]);

  const [steps, setSteps] = useState([]);

  const pointsStep = {
    label: 'Points',
    src: `${baseUrl}${ConversionStepsUrls.POINTS}`,
    icon: <PointCup fill={neutralColors.neutralWhite} />,
  };
  const informationStep = {
    label: 'Informations',
    src: `${baseUrl}${ConversionStepsUrls.INFORMATION}`,
    icon: <PointUser fill={neutralColors.neutralWhite} />,
  };
  const declarationStep = {
    label: 'Declaration',
    src: `${baseUrl}${ConversionStepsUrls.DECLARATION}`,
    icon: <PointDeclaration fill={neutralColors.neutralWhite} />,
  };
  const summaryStep = {
    label: 'Récapitulatif',
    src: `${baseUrl}${ConversionStepsUrls.SUMMARY}`,
    icon: <PointSummary fill={neutralColors.neutralWhite} />,
  };

  useEffect(() => {
    if (!convertPointsContext.simulation) return;

    const steps = [pointsStep];

    if (
      convertPointsContext.reward &&
      ((!hierarchyNodeUser.cku.last_active_cku &&
        convertPointsContext.reward.uuid === PointCommandIds.CKU) ||
        convertPointsContext.reward.uuid !== PointCommandIds.CKU)
    ) {
      steps.push(informationStep);
    }

    if (convertPointsContext.simulation?.order_suggested?.lfss_form_required) {
      steps.push(declarationStep);
    }

    steps.push(summaryStep);

    setSteps(steps);
  }, [
    hierarchyNodeUser,
    convertPointsContext.reward,
    convertPointsContext.simulation,
  ]);

  return {
    steps,
    sources: steps.map(step => step.src),
    pointsStep,
    informationStep,
    declarationStep,
    summaryStep,
    baseUrl,
  };
};

export default UseConvertPointsSteps;
