import React from 'react';

const ImageView = ({ imageViewClass, imageStyle = {}, image, alt }) => {
  return (
    <div className={imageViewClass}>
      {image && (
        <img
          alt={alt}
          src={image.src}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            ...imageStyle,
          }}
        />
      )}
    </div>
  );
};

export { ImageView };
