import { tagTypes } from '@src/store/api-tags';
import instance from '../instance';
import { baseApi } from '@src/store/api-query';

/**
 * @param {string} id ID du noeud hiérarchique en base de données
 */
const baseUrl = id => `hierarchy-nodes/${id}/contract-detail/`;

const contractDetails = {
  /** @param {ContractDetailsParams} queryParams */
  get({ hierarchyNodeId }) {
    return instance.get(baseUrl(hierarchyNodeId));
  },
  /** @param {ContractDetailsPatchParams} queryParams */
  patch({ hierarchyNodeId, contractDetail }) {
    return instance.patch(baseUrl(hierarchyNodeId), contractDetail);
  },
};

const contractDetailsQueries = baseApi.injectEndpoints({
  tagTypes: [tagTypes.contractDetails],
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<ContractDetailsParams, typeof builder.query, 'api-query', {}>}*/
    getContractDetail: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => contractDetails.get(params));
      },
      providesTags: [tagTypes.contractDetails],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<ContractDetailsPatchParams, typeof builder.mutation, 'api-query', {}>}*/
    patchContractDetail: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => contractDetails.patch(params));
      },
      invalidatesTags: [tagTypes.contractDetails],
    }),
  }),
});

export const {
  useGetContractDetailQuery,
  useLazyGetContractDetailQuery,
  usePatchContractDetailMutation,
} = contractDetailsQueries;

// Types
/**
 * @typedef {Object} ContractDetailsParams
 * @property {string} hierarchyNodeId Id du nœud hiérarchique
 */

/**
 * @typedef {Object} ContractDetail
 * @property {boolean} [lfss_form_status] - LFSS form activation of the contract detail
 * @property {string} [title] - Title of the contract detail
 * @property {string} [description] - Description of the contract detail
 * @property {string} [customer_name] - Customer name of the contract detail
 * @property {number} [additional_points_validity_months] - Additional points validity of the contract detail
 * @property {number} [point_value] - Points value of the contract detail
 */

/**
 * @typedef {Object} ContractDetailsPatchParams
 * @property {string} hierarchyNodeId Id du nœud hiérarchique
 * @property {ContractDetail} contractDetail Data du noeud hiérarchique
 */

const ContractDetailsOld = {
  get(code) {
    return instance.get(`hierarchy-nodes/${code}/contract-detail`);
  },

  update(code, contractDetail) {
    return instance.patch(
      `hierarchy-nodes/${code}/contract-detail/`,
      contractDetail,
    );
  },
};

export default ContractDetailsOld;
