import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { usePostLinkAccountMutation } from '@src/data/api/endpoints/hierarchyNodes';
import { getQueryParam } from '@src/helpers/UrlHelper';
import {
  signInRedirectCallback,
  signOutSilent,
  signUpRedirectCallback,
} from './oidc';

export const AuthCallback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [postLinkAccount, { data, isError, isSuccess }] =
    usePostLinkAccountMutation();

  const node = getQueryParam(location.search, 'node');

  useEffect(() => {
    const key = getQueryParam(location.search, 'key');
    const mode = getQueryParam(location.search, 'mode');

    async function handleCallback() {
      const validateModeIfExists = () => {
        if (mode && !['register', 'implicit'].includes(mode)) {
          console.error('Invalid mode');
          history.push('/404');
          return false;
        }
        return true;
      };

      const finalizeImplicitSignIn = async () => {
        if (!node) {
          console.error('Invalid node for implicit login');
          signOutSilent(dispatch);
          history.push('/404');
          return;
        }

        await postLinkAccount({ code: node }).unwrap();
      };

      const finalizeSignUp = async () => {
        await signUpRedirectCallback();

        if (!node || !key) {
          console.error('Invalid node or key for registration');
          signOutSilent(dispatch);
          history.push('/404');
          return false;
        }

        const encodedKey = encodeURIComponent(key);

        redirectPath = `/nodes/${node}/finalize-activation?key=${encodedKey}`;

        return true;
      };

      const isValidRedirectPath = path => {
        return (
          path.startsWith('/') &&
          !path.startsWith('//') &&
          !path.includes('://')
        );
      };

      if (!validateModeIfExists()) {
        return;
      }

      let redirectPath = getQueryParam(location.search, 'redirect');

      if (mode === 'register') {
        if (!(await finalizeSignUp())) {
          return;
        }
      } else {
        await signInRedirectCallback();

        if (mode === 'implicit') {
          await finalizeImplicitSignIn();
          return;
        }
      }

      if (redirectPath && isValidRedirectPath(redirectPath)) {
        history.push(redirectPath);
      } else {
        history.push('/nodes');
      }
    }

    handleCallback();
  }, [dispatch, history, location.search, node, postLinkAccount]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.link_made) {
        history.push(`/nodes/${node}/account-activation-status?status=success`);
      } else {
        history.push(`/nodes`);
      }
    }
  }, [data?.link_made, history, isSuccess, node]);

  useEffect(() => {
    if (isError) {
      history.push(`/nodes/${node}/account-activation-status?status=error`);
    }
  }, [history, isError, node]);

  return (
    <>
      <div className={'ft-content'}>
        <CircularProgress />
      </div>
    </>
  );
};
