export const HierarchyNodeStatuses = Object.freeze({
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  MAINTENANCE: 'MAINTENANCE',
  DELETED: 'DELETED',
});

/**
 *  @typedef { HierarchyNodeStatuses[keyof HierarchyNodeStatuses] } HierarchyNodeStatusesType - Statuses for a hierarchyNode, should use the enum in `HierarchyNodeStatuses.jsxÌ
 * @exports { HierarchyNodeStatusesType };
 */
