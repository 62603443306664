import React from 'react';
import { neutralColors } from '@src/Spider';
import { createTheme, makeStyles, Typography } from '@material-ui/core';
import { Button } from '@src/Spider/components';
import { Edit2 } from 'iconsax-react';
import { ReactComponent as ErrorIcon } from '@spider:src/assets/img/close-circle.svg';
import { ReactComponent as WarningIcon } from '@spider:src/assets/img/warning-icon.svg';
import { useIntl } from 'react-intl';
import { Alert } from '@spider:components/Alert';
import { SystemCode } from '@src/Spider/enums/systemCode';

const useStyle = makeStyles({
  block: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
  informationCard: {
    display: 'flex',
    padding: '1rem 1rem 1rem 1rem',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '1rem',
    overflow: 'clip',
    position: 'relative',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  buttonStyle: {
    color: neutralColors.neutral900,
    backgroundColor: neutralColors.neutralWhite,
  },
  topRightButtonWrapper: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    [createTheme().breakpoints.down('md')]: {
      position: ({ relativeButton }) =>
        relativeButton ? 'relative' : 'absolute',
      display: 'flex',
      top: ({ relativeButton }) => (relativeButton ? '0rem' : '1rem'),
      right: ({ relativeButton }) => (relativeButton ? '0rem' : '1rem'),
      justifyContent: ({ relativeButton }) =>
        relativeButton ? 'right' : 'unset',
    },
  },
});

/** @param {{ isError: boolean, showWarningAlert: boolean, relativeButton: boolean, title: string, onClick: () => void, children: React.ReactNode }} props */
const InformationWrapper = ({
  isError = false,
  showWarningAlert = false,
  relativeButton = false,
  title,
  onClick,
  children,
}) => {
  const intl = useIntl();
  const classes = useStyle({ relativeButton });
  return (
    <div className={classes.block}>
      <Typography variant={'h4'} component={'h4'}>
        {title}
      </Typography>

      {(isError || (!isError && showWarningAlert)) && (
        <Alert
          type={isError ? SystemCode.ERROR : SystemCode.WARNING}
          Icon={() => (isError ? <ErrorIcon /> : <WarningIcon />)}
          title={intl.formatMessage({
            id: `spider.convertPoints.stepper.summaryStep.${isError ? 'error' : 'warning'}.alert.title`,
          })}
          titleVariant={'subtitle1'}
          titleComponent={'span'}
        >
          <Typography variant={'body2'}>
            {intl.formatMessage({
              id: `spider.convertPoints.stepper.summaryStep.${isError ? 'error' : 'warning'}.alert.message`,
            })}
          </Typography>
        </Alert>
      )}

      <div className={classes.informationCard}>
        <div className={classes.topRightButtonWrapper}>
          <Button
            variant={'contained'}
            size={'small'}
            className={isError ? 'error' : classes.buttonStyle}
            onClick={onClick}
          >
            <Edit2 />
            {intl.formatMessage({ id: 'spider.common.modify' })}
          </Button>
        </div>

        {children}
      </div>
    </div>
  );
};

export { InformationWrapper };
