import React from 'react';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import { useIntl } from 'react-intl';
import { useBaseFormsyValidation } from '@src/Spider/hooks/useBaseFormsyValidation';

/**
 * @param {{
 *   formData: Object,
 *   classes: any,
 *   error: any,
 *   handleFormChange: (formData: Object) => void,
 * }} props
 */
const Component = ({ formData, classes, error, handleFormChange }) => {
  const intl = useIntl();
  const originalLastnameMaxLength = 50;

  const { baseFormsyValidationErrors, baseFormsyValidations } =
    useBaseFormsyValidation();

  return (
    <div className={classes.formFields}>
      <TextFieldFormsy
        name={'first_name'}
        value={formData.first_name}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.identification.firstnameField',
        })}
        variant={'outlined'}
        required
        disabled
        validations={{}}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
          }),
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={'last_name'}
        value={formData.last_name}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.identification.lastnameField',
        })}
        variant={'outlined'}
        required
        disabled
        validations={{}}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
          }),
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={'birth_name'}
        value={formData.birth_name}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.identification.originalLastnameField',
        })}
        variant={'outlined'}
        validations={{
          ...baseFormsyValidations({
            maxLength: originalLastnameMaxLength,
            customErrorMessage: error?.birth_name,
          }),
        }}
        validationErrors={{
          ...baseFormsyValidationErrors({
            maxLength: originalLastnameMaxLength,
            customErrorMessage: error?.birth_name,
          }),
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={'email'}
        value={formData.email}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.identification.emailField',
        })}
        variant={'outlined'}
        disabled
        required
        validations={{}}
        validationErrors={{
          ...baseFormsyValidationErrors({
            isRequired: true,
          }),
        }}
        onChange={handleFormChange}
      />
    </div>
  );
};

const IdentificationForm = Component;

export { IdentificationForm };
