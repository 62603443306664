import { useEffect, useMemo, useRef, useState } from 'react';

/**
 * @param {{
 *   isFormDefaultDirty: boolean,
 *   errorHook: () => {},
 *   saveStateData: (formData: Object, force: boolean) => void,
 *   clearSavedState: () => void,
 *   onFormChange: (deleteProps: Array<string>) => void,
 * }} props
 */
export const useFormHelper = ({
  isFormDefaultDirty = false,
  saveStateData,
  clearSavedState,
  onFormChange,
  errorHook,
}) => {
  const formRef = useRef(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(isFormDefaultDirty);
  const isSubmitButtonDisabled = useMemo(
    () => !canSubmit || !isFormDirty,
    [canSubmit, isFormDirty],
  );
  const errorHookResult = errorHook();

  const validateForm = () => {
    if (formRef.current) {
      formRef.current.validateForm();
      formRef.current.setFormPristine(false);
    }
  };

  const disableSubmit = () => setCanSubmit(false);
  const enableSubmit = () => setCanSubmit(true);

  const dirtyForm = () => setIsFormDirty(true);
  const pristineForm = () => setIsFormDirty(false);

  const onInvalid = e => {
    clearSavedState();
    disableSubmit();
  };

  const onValid = (formData, force) => {
    saveStateData(formData, force);
    enableSubmit();
  };

  const formChange = deleteProps => {
    onFormChange(deleteProps);
    dirtyForm();
  };

  useEffect(() => {
    if (
      !errorHookResult.error ||
      Object.keys(errorHookResult.error).length === 0
    )
      return;

    validateForm();
  }, [errorHookResult.error]);

  return {
    formRef,
    canSubmit,
    isFormDirty,
    isSubmitButtonDisabled,
    disableSubmit,
    enableSubmit,
    dirtyForm,
    pristineForm,
    validateForm,
    onInvalid,
    onValid,
    formChange,
  };
};
