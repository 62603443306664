import { neutralColors } from '../colors';

const textFieldStyles = {
  MuiOutlinedInput: {
    notchedOutline: {
      borderRadius: '12px',
      borderColor: neutralColors.neutral450,
      border: '2px solid',
      padding: '16px 4px 4px 16px',
    },
  },
  MuiInputLabel: {
    root: {
      fontWeight: 700,
      color: '#64748B',
      fontFamily: 'Ubuntu',
    },
    outlined: {
      '&.MuiInputLabel-shrink': {
        transform: 'translate(20px, -6px) scale(0.75)',
      },
    },
  },
  MuiTextField: {
    root: {
      marginTop: '0.5rem',
    },
  },
  MuiInputBase: {
    root: {
      background: `${neutralColors.neutralWhite} !important`,
      alignItems: 'baseline !important',
    },
    input: {
      fontWeight: 400,
      color: '#0F172A',
      fontFamily: 'Ubuntu',
      backgroundColor: neutralColors.neutralWhite,
    },
  },
  MuiFormHelperText: {
    root: {
      marginTop: '0px',
      fontWeight: 400,
      color: neutralColors.neutral600,
      fontFamily: 'Ubuntu',
    },
  },
  // todo: should add this style to counter the input legend missing space, see main color input
  // 'PrivateNotchedOutline-legendLabelled-9 > span': {
  //     marginRight: '5px',
  //     // '& > span': {
  //     //     paddingRight: '10px'
  //     // }
  // }
};

export default textFieldStyles;
