import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Formsy from 'formsy-react';
import { CardMedia, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ImageInput } from '../../components';
import {
  AppBarSubTitle,
  Card,
  DefaultTitle,
  InfoText,
  Loader,
  MainLayoutComponent,
  ProgressButton,
  Select,
  TextField,
  RichTextField,
} from '../../../../components';
import { injectIntl } from 'react-intl';
import * as rewardCategoryListActions from '../../../../services/RewardCategories/RewardCategoryList/actions';
import * as rewardCreationActions from '../../../../services/Rewards/RewardCreation/actions';
import * as rewardDetailActions from '../../../../services/Rewards/RewardDetail/actions';
import * as rewardTypeListActions from '../../../../services/RewardTypes/RewardTypeList/actions';
import {
  convertUrlToFile,
  importLocalFiles,
} from '../../../../helpers/FileHerlper';
import { SystemFileCodes } from '../../../../Spider/enums';

const styles = {
  image: {
    height: '100%',
    width: '100%',
  },
};

class RewardDuplication extends MainLayoutComponent {
  state = { image: null, newImage: [] };

  componentDidMount() {
    const { intl } = this.props;
    this.initialized = false;
    this.props.handleTitle(intl.formatMessage({ id: 'reward.title' }));
    this.props.handleSubHeader(
      <AppBarSubTitle
        title={intl.formatMessage({ id: 'reward.duplication.title' })}
      />,
    );
    this.props.handleMaxWidth('md');
    this.props.activateReturn();
    this.props.rewardCategoryListActions.getActiveRewardCategoryList();
    this.props.rewardDetailActions.getReward(this.props.match.params.id);
    this.props.rewardTypeListActions.getRewardTypeList();
    this.props.rewardCreationActions.clearRewardCreation();

    const imagePromises = importLocalFiles(
      require.context(
        '../../../../assets/img/rewards',
        false,
        /\.(png|jpe?g|svg)$/,
      ),
    );
    Promise.all(imagePromises).then(images => {
      this.setState({ newImages: images });
    });
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    const { reward } = this.props.rewardDetail;
    if (!this.initialized && reward) {
      this.initialized = true;

      if (reward.image) {
        const image = await convertUrlToFile(reward.image);
        const imageUrl = URL.createObjectURL(image);
        this.setState({
          ...this.state,
          image: imageUrl,
        });
      }
    }
  }

  renderLoader() {
    return <Loader centered />;
  }

  setImage(image) {
    const imageUrl = URL.createObjectURL(image);
    this.setState({
      ...this.state,
      image: imageUrl,
    });
  }

  handleImageChange(image) {
    this.setImage(image);
    // const reader = new FileReader();
    // reader.onloadend = function (e) {
    //     this.setImage(reader.result);
    // }.bind(this);
    // reader.readAsDataURL(image);
  }

  handleSubmit(model) {
    const reward = {
      name: model.name,
      description: JSON.stringify(model.description),
      category: model.category,
      type: model.type,
      value: model.value,
      points: model.points,
      deliveryPlace: model.deliveryPlace,
      deliveryMode: model.deliveryMode,
      deliveryTime:
        !model.deliveryTime || model.deliveryTime.trim() === ''
          ? null
          : model.deliveryTime,
      isActive: true,
    };

    const rewardImage = new FormData();
    rewardImage.append('src', model.image, model.image.name);
    rewardImage.append('code', SystemFileCodes.REWARD_IMAGE);

    this.props.rewardCreationActions.createReward(reward, rewardImage);
  }

  renderForm() {
    const { intl } = this.props;
    const { classes } = this.props;
    const { categories } = this.props.rewardCategoryList;
    const { reward } = this.props.rewardDetail;
    const { types } = this.props.rewardTypeList;
    const { loading } = this.props.rewardCreation;

    return (
      <div>
        <Formsy onValidSubmit={this.handleSubmit.bind(this)}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DefaultTitle isContrast>
                    {intl.formatMessage({ id: 'reward.form.infos_area' })}
                  </DefaultTitle>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              lowercase
                              name='name'
                              label={intl.formatMessage({
                                id: 'reward.form.name_label',
                              })}
                              initial={reward.name}
                              fullWidth
                              required
                              validations='maxLength:128'
                              validationErrors={{
                                isDefaultRequiredValue: intl.formatMessage({
                                  id: 'common.form.required_error',
                                }),
                                maxLength: intl.formatMessage({
                                  id: 'common.form.max_length_128_error',
                                }),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <RichTextField
                              fullWidth
                              initial={JSON.parse(reward.description)}
                              label={intl.formatMessage({
                                id: 'challenge.form.info_description_label',
                              })}
                              name='description'
                              required
                              validationErrors={{
                                isDefaultRequiredValue: intl.formatMessage({
                                  id: 'common.form.required_error',
                                }),
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        {!this.state.image && (
                          <Grid
                            container
                            justify={'center'}
                            alignItems={'center'}
                            style={{ height: '100%' }}
                          >
                            <Grid item>
                              <InfoText align={'center'}>
                                {intl.formatMessage({
                                  id: 'reward.form.empty_image_text',
                                })}
                              </InfoText>
                            </Grid>
                          </Grid>
                        )}
                        {this.state.image && (
                          <CardMedia
                            image={this.state.image}
                            className={classes.image}
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Select
                          name='category'
                          label={intl.formatMessage({
                            id: 'reward.form.category_label',
                          })}
                          options={categories}
                          optionValueName={'id'}
                          optionTextName={'name'}
                          initial={reward.category.id}
                          fullWidth
                          required
                          validationErrors={{
                            isDefaultRequiredValue: intl.formatMessage({
                              id: 'common.form.required_error',
                            }),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Select
                          name='type'
                          label={intl.formatMessage({
                            id: 'reward.form.type_label',
                          })}
                          options={types}
                          optionValueName={'id'}
                          optionTextName={'name'}
                          initial={reward.type.id}
                          fullWidth
                          required
                          validationErrors={{
                            isDefaultRequiredValue: intl.formatMessage({
                              id: 'common.form.required_error',
                            }),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          lowercase
                          type='number'
                          name='value'
                          label={intl.formatMessage({
                            id: 'reward.form.value_label',
                          })}
                          endAdornment={intl.formatMessage({
                            id: 'reward.form.value_suffix_label',
                          })}
                          initial={reward.value}
                          fullWidth
                          required
                          validations='isInt'
                          validationErrors={{
                            isDefaultRequiredValue: intl.formatMessage({
                              id: 'common.form.required_error',
                            }),
                            isInt: intl.formatMessage({
                              id: 'common.form.is_int_error',
                            }),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          lowercase
                          type='number'
                          name='points'
                          label={intl.formatMessage({
                            id: 'reward.form.points_label',
                          })}
                          initial={reward.points}
                          fullWidth
                          required
                          validations='isInt'
                          validationErrors={{
                            isDefaultRequiredValue: intl.formatMessage({
                              id: 'common.form.required_error',
                            }),
                            isInt: intl.formatMessage({
                              id: 'common.form.is_int_error',
                            }),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ImageInput
                          name='image'
                          label={intl.formatMessage({
                            id: 'reward.form.image_label',
                          })}
                          images={this.state.newImages}
                          initial={reward.image}
                          onChange={this.handleImageChange.bind(this)}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DefaultTitle isContrast>
                    {intl.formatMessage({ id: 'reward.form.delivery_area' })}
                  </DefaultTitle>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          lowercase
                          name='deliveryPlace'
                          label={intl.formatMessage({
                            id: 'reward.form.delivery_place_label',
                          })}
                          initial={reward.deliveryPlace}
                          fullWidth
                          required
                          validations='maxLength:128'
                          validationErrors={{
                            isDefaultRequiredValue: intl.formatMessage({
                              id: 'common.form.required_error',
                            }),
                            maxLength: intl.formatMessage({
                              id: 'common.form.max_length_128_error',
                            }),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          lowercase
                          name='deliveryMode'
                          label={intl.formatMessage({
                            id: 'reward.form.delivery_mode_label',
                          })}
                          initial={reward.deliveryMode}
                          fullWidth
                          required
                          validations='maxLength:128'
                          validationErrors={{
                            isDefaultRequiredValue: intl.formatMessage({
                              id: 'common.form.required_error',
                            }),
                            maxLength: intl.formatMessage({
                              id: 'common.form.max_length_128_error',
                            }),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          lowercase
                          name='deliveryTime'
                          label={intl.formatMessage({
                            id: 'reward.form.delivery_time_label',
                          })}
                          initial={reward.deliveryTime}
                          fullWidth
                          validations='maxLength:128'
                          validationErrors={{
                            maxLength: intl.formatMessage({
                              id: 'common.form.max_length_128_error',
                            }),
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ProgressButton
                text={intl.formatMessage({ id: 'common.submit' })}
                loading={loading}
                centered
              />
            </Grid>
          </Grid>
        </Formsy>
      </div>
    );
  }

  render() {
    const { categories, loading: rewardCategoryListLoading } =
      this.props.rewardCategoryList;
    const { reward, loading: rewardDetailLoading } = this.props.rewardDetail;
    const { types, loading: rewardTypeListLoading } = this.props.rewardTypeList;
    const { success } = this.props.rewardCreation;
    const loading =
      rewardCategoryListLoading || rewardDetailLoading || rewardTypeListLoading;

    if (!loading && reward && !reward.isActive) {
      this.props.history.push('/');
    }

    if (success) {
      this.props.rewardCreationActions.clearRewardCreation();
      this.props.history.goBack();
    }

    return (
      <div>
        {loading && this.renderLoader()}
        {!loading && categories && reward && types && this.renderForm()}
      </div>
    );
  }
}

const mapStateToProps = ({
  rewardCategoryList,
  rewardCreation,
  rewardDetail,
  rewardTypeList,
}) => ({
  rewardCategoryList,
  rewardCreation,
  rewardDetail,
  rewardTypeList,
});

const mapDispatchToProps = dispatch => ({
  rewardCategoryListActions: bindActionCreators(
    rewardCategoryListActions,
    dispatch,
  ),
  rewardCreationActions: bindActionCreators(rewardCreationActions, dispatch),
  rewardDetailActions: bindActionCreators(rewardDetailActions, dispatch),
  rewardTypeListActions: bindActionCreators(rewardTypeListActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(injectIntl(RewardDuplication)));
