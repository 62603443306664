import React from 'react';
import { Button as MuiButton } from '@material-ui/core';

/**
 * @param {import("@material-ui/core").ButtonProps & {load: boolean}} props
 */
const Button = ({ load = false, children, ...props }) => {
  const Content = () => {
    if (load) {
      return '...';
    }

    return children;
  };

  return (
    <>
      <MuiButton disableRipple {...props}>
        <Content />
      </MuiButton>
    </>
  );
};

export default Button;
