import React from 'react';
import { Typography } from '@material-ui/core';
import { neutralColors } from '../../../../../../themes';
import { Button } from '../../../../../../components';
import { useIntl } from 'react-intl';
import { ReactComponent as ArrowRightThicker } from '../../../../../../assets/img/arrow-right-thicker.svg';
import { withStyles } from '@mui/styles';

const styles = {
  theme: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    cursor: 'pointer',
    border: '2px solid transparent',
    borderRadius: '1rem',
    boxShadow: '0px 6px 10px 2px rgba(10, 31, 94, 0.12)',
    transition: 'box-shadow .3s ease-out',
    '&:hover': {
      boxShadow: '0px 12px 24px 0px rgba(10, 31, 94, 0.20)',
      '& $themeContent button': {
        transform: 'translateX(1rem)',
      },
    },
    '&:active': {
      border: '2px solid #0F172A',
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%) lightgray 50%',
    },
    '&:focus': {
      border: '2px solid #298EFA',
    },
  },
  themeContent: {
    position: 'absolute',
    padding: '1.5rem 1.5rem 1rem 1.5rem',
  },
};

const ThemeCard = ({ theme, onClick, ...props }) => {
  const intl = useIntl();
  return (
    <div className={props.classes.theme} onClick={onClick}>
      <img
        alt={'theme'}
        src={theme.src}
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
      <div className={props.classes.themeContent}>
        <Typography
          variant={'subtitle1'}
          style={{ fontWeight: 'bold', color: neutralColors.neutralWhite }}
        >
          {intl.formatMessage({ id: theme.line1 })}
          <br />
          {intl.formatMessage({ id: theme.line2 })}
        </Typography>
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          className={'icon light'}
          style={{
            borderRadius: '6.25rem',
            minWidth: '1.5rem',
            height: '1.5rem',
            padding: '0.25rem !important',
            color: 'black',
            backgroundColor: 'white',
            transition: 'transform .6s',
          }}
        >
          <ArrowRightThicker />
        </Button>
      </div>
    </div>
  );
};

export default withStyles(styles)(ThemeCard);
