import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, TableCell, TableRow, Typography } from '@material-ui/core';
import UsePagination from '../../hooks/UsePagination';
import { formatDate } from '../../../helpers/DateHelper';
import { Drawer } from '@mui/material';
import OrderStatus from '../OrderStatus';
import { Button } from '@spider:src/components';
import { ArrowRight } from 'iconsax-react';
import { ProductType } from './components/ProductType';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { OrderDetails } from './components/OrderDetails/OrderDetails';
import { Participant } from './components/Participant';
import { useLazyGetHierarchyNodeOrdersListQuery } from '@src/data/api/endpoints/hierarchyNodes';
import { UserRoles } from '@src/Spider/enums';
import { DataList } from '../DataList';
import { AuthorizedRolesWrapper } from '../AuthorizedRolesWrapper';
import { useSelectDataFromRole } from '@src/Spider/hooks/useSelectDataFromRole';
import { EmptyTableView } from '../EmptyTableView';
import EmptyTableImage from '../../assets/img/empty-table-participants.png';
import { CancelOrderDialog } from './CancelOrderDialog';
/** @typedef {import('@async-calls/orders').Order} Order */

/** @param {{queryParams: import("@async-calls/orders").OrdersListParams}} */
export const OrdersList = ({ queryParams }) => {
  const { open, onOpen, onClose } = useDisclosure();
  const [selectedOrderUuid, setSelectedOrderUuid] = useState('');
  const intl = useIntl();
  const [fetch, { data, isFetching, isLoading, error }] =
    useLazyGetHierarchyNodeOrdersListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      ...queryParams,
      include_items: true,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const columns = useSelectDataFromRole({
    super_administrator: [
      'date',
      'participant',
      'product',
      'ref',
      'total',
      'status',
    ],
    administrator: ['date', 'participant', 'product', 'ref', 'total', 'status'],
    manager: ['date', 'product', 'ref', 'total', 'status'],
    collaborator: ['date', 'product', 'ref', 'total', 'status'],
  }).map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.orders.tabs.list.table.${el}`,
    }),
  }));

  const onOpenDrawer = order => {
    onOpen();
    setSelectedOrderUuid(order.uuid);
  };

  const onCloseDrawer = () => {
    onClose();
    setSelectedOrderUuid('');
  };

  const Row = React.memo(({ item, classes }) => (
    <TableRow key={item.uuid}>
      <TableCell>
        <Typography>
          {formatDate(item.creation_date).replaceAll('/', '.')}
        </Typography>
        <Typography variant='h6'>
          {item.creation_date.toDate().toLocaleString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Typography>
      </TableCell>
      <AuthorizedRolesWrapper roles={[UserRoles.ADMINISTRATOR]}>
        <TableCell>
          <Participant owner={item.owner} />
        </TableCell>
      </AuthorizedRolesWrapper>
      <TableCell>
        <ProductType
          product={item.items?.[0]?.reward_allocation?.reward?.uuid}
        />
      </TableCell>
      <TableCell>
        <Typography>{item.number}</Typography>
      </TableCell>
      <TableCell>
        <Box>
          <Typography style={{ fontWeight: '700' }}>
            {item.monetary_value.toFormatNumber()}
          </Typography>
          <Typography style={{ fontSize: '0.75rem', whiteSpace: 'nowrap' }}>
            {intl.formatMessage(
              { id: 'spider.orders.details.points' },
              { points: item.points },
            )}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <OrderStatus status={item.status} />
      </TableCell>
      <TableCell className={classes.stickyColumn}>
        <CancelOrderDialog order={item} />
        <Button
          variant='text'
          color='primary'
          size='small'
          onClick={() => onOpenDrawer(item)}
        >
          <ArrowRight />
        </Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <DataList
        hasAction
        columns={columns}
        data={data?.results}
        count={data?.count}
        RowComponent={Row}
        isFetching={isFetching}
        isLoading={isLoading}
        error={error}
        pagination={pagination}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        paginationCount={paginationCount}
        emptyState={
          <EmptyTableView
            imageSrc={EmptyTableImage}
            imageAlt='spider.orders.tabs.list.table.no-data.image-alt'
            title='spider.orders.tabs.list.table.no-data.title'
          />
        }
      />
      <Drawer anchor='right' open={open} onClose={onCloseDrawer}>
        <OrderDetails uuid={selectedOrderUuid} onClose={onCloseDrawer} />
      </Drawer>
    </>
  );
};
