import { createTheme } from '@material-ui/core/styles';
import { neutralColors } from '../../../../themes/colors';

const cssBaselineStyles = {
  MuiCssBaseline: {
    '@global': {
      html: {
        height: '100%',
        '& body': {
          height: '100%',
          backgroundColor: neutralColors.neutralBg,
          color: neutralColors.neutral900,
          display: 'flex',
          flexDirection: 'column',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          margin: 0,
        },
        '& #root': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          margin: 0,
        },
        '& .ft-wrapper': {
          flex: 1,
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 7rem)',
          overflow: 'scroll',
          paddingBottom: '1rem',
          marginTop: '0.6rem',
          marginLeft: '0.5rem',
          borderRadius: '1rem',
        },
        '& main': {
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          [createTheme().breakpoints.down('md')]: {
            padding: '0px 16px 24px 16px',
          },
          [createTheme().breakpoints.down('xs')]: {
            padding: '0px 0px 24px 0px',
          },
        },
        '& footer': {
          backgroundColor: 'transparent',
          lineHeight: '16px',
          padding: '16px 16px 16px 0px',
          [createTheme().breakpoints.down('xs')]: {
            padding: '0px',
          },
          '& .footer-content': {
            backgroundColor: neutralColors.neutralWhite,
            borderRadius: '16px',
            padding: '16px',
            [createTheme().breakpoints.down('xs')]: {
              borderRadius: '0px',
            },
          },
        },
        '& .tabs-container': {
          backgroundColor: neutralColors.neutralWhite,
          borderRadius: '24px',
        },
        '& .sidebar-root': {
          display: 'flex',
          flexDirection: 'column',
          maxHeight: '100vh',
        },
        '& .sidebar': {
          display: 'flex',
          flex: 1,
        },
        '& .sidebar-content': {
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
          padding: '0.5rem 0.5rem 1rem 1rem',
        },
      },
    },
  },
};

export default cssBaselineStyles;
