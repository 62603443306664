export const OrderStatuses = Object.freeze({
  CREATED: 'CREATED',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  DELETED: 'DELETED',
  PENDING: 'PENDING',
});

/**
 *  @typedef { OrderStatuses[keyof OrderStatuses] } OrderStatusesType - Statuses for an order , should use the enum in `OrderStatuses.jsx`
 * @exports { OrderStatusesType };
 */
