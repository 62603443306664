import React from 'react';
import { Route } from 'react-router-dom';

import { AuthProvider, MaintenanceProvider } from '@src/auth';
import { UserLayout } from './index';
import { GuestLayout } from '../GuestLayout';
import useAuthorizedUser from '../../../hooks/UseAuthorizedUser';

const UserRoutes = ({
  component: Component,
  useGuestLayout = false,
  hierarchyNodeUserRequired = true,
  authorizedRoles = [],
  ...rest
}) => {
  useAuthorizedUser(authorizedRoles);
  const Layout = !useGuestLayout ? UserLayout : GuestLayout;
  return (
    <Route
      {...rest}
      render={props => (
        <AuthProvider hierarchyNodeUserRequired={hierarchyNodeUserRequired}>
          <MaintenanceProvider>
            <Layout
              component={Component}
              authorizedRoles={authorizedRoles}
              {...props}
            />
          </MaintenanceProvider>
        </AuthProvider>
      )}
    />
  );
};

export default UserRoutes;
