export const ExportStatuses = Object.freeze({
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
});

/**
 *  @typedef {ExportStatuses[keyof ExportStatuses]} ExportStatusType - Statutuses for an export, should use the enum in `exportStatus.jsx`
 * @exports { ExportStatusType };
 */
