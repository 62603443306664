import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { RoundedTab, RoundedTabs } from '../Tabs';
import { DefaultText } from '../Texts';

const ParticipantFilter = ({ onParticipantChange }) => {
  const intl = useIntl();
  const [selectedParticipant, setSelectedParticipant] = useState(0);
  const history = useHistory();

  useEffect(() => {
    history.replace(`?current=${selectedParticipant}`);
  }, [selectedParticipant, history]);

  const handleChange = (event, newValue) => {
    setSelectedParticipant(newValue);
    if (onParticipantChange) {
      onParticipantChange(newValue);
    }
  };

  return (
    <div>
      <div style={{ padding: 16 }}>
        <DefaultText align={'center'}>
          {intl.formatMessage({ id: 'admin.badge_config.title' })}
        </DefaultText>
      </div>
      <RoundedTabs
        value={selectedParticipant}
        onChange={handleChange}
        variant='fullWidth'
      >
        <RoundedTab
          label={intl.formatMessage({ id: 'menu.collaborator_label' })}
        />
        <RoundedTab label={intl.formatMessage({ id: 'menu.manager_label' })} />
      </RoundedTabs>
    </div>
  );
};

export default ParticipantFilter;
