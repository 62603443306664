import { Button, Paper, Typography, Box } from '@material-ui/core';
import { TitleContainer } from '@src/Spider/components/TitleContainer';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { withStyles } from '@mui/styles';
import cardKadeosUniversel from '../../assets/img/home/universel-kadeos.png';
import cardKadeos from '../../assets/img/home/card-kadeos.png';
import cardShopKadeos from '../../assets/img/home/shop-kadeos.png';
import cardChequesKadeos from '../../assets/img/home/chequ-kadeos.png';
import { neutralColors } from '@spider:src/themes';
import { useGetRewardsListQuery } from '@async-calls/rewards';
import { CircularProgress } from '@mui/material';
import { useGetContactsQuery } from '@src/data/api/endpoints/contacts';

const styles = {
  pointsBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '1.5rem 1rem',
    borderRadius: '2rem',
  },

  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
    justifyContent: 'space-between',
  },
  card: {
    flex: '1 1 calc(50% - 1rem)',
    minWidth: '250px',
    maxWidth: '528px',
    minHeight: '152px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '1rem',
    backgroundColor: neutralColors.neutralColdWhite,
    padding: '1rem',
    boxShadow: 'none',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  image: {
    width: '4.5rem',
    height: 'auto',
    borderRadius: '0.5rem',
    objectFit: 'contain',
  },
  textContent: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardTitle: {
    fontWeight: 700,
    marginBottom: '0.5rem',
  },
  linkGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '1rem',
  },
  link: {
    display: 'inline-block',
    backgroundColor: neutralColors.neutralWhite,
    color: neutralColors.neutral900,
    borderRadius: '0.5rem',
    padding: '4px 8px',
    textTransform: 'none',
    fontWeight: 600,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  loading: {
    width: '100%',
    minWidth: '10rem',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
  },
};

const Help = ({ classes }) => {
  const intl = useIntl();
  const { contract } = useParams();

  const { data: contacts, isFetching: contactsFetching } = useGetContactsQuery({
    hierarchyNodeId: contract,
  });
  const contractEmail = contacts?.results[0]?.email || 'olga@help.you';

  const { data, isFetching } = useGetRewardsListQuery({
    hierarchyNodeId: contract,
    allocatedOnly: true,
    distributor: 'Edenred',
  });

  const staticCards = [
    {
      name: 'Carte Kadéos Universel',
      image: cardKadeosUniversel,
      links: [
        intl.formatMessage({
          id: 'spider.help.cards_content.universal_card_link1',
        }),
        intl.formatMessage({
          id: 'spider.help.cards_content.universal_card_link2',
        }),
        intl.formatMessage({
          id: 'spider.help.cards_content.universal_card_link3',
        }),
        intl.formatMessage({
          id: 'spider.help.cards_content.universal_card_link4',
        }),
      ],
    },
    {
      name: 'Carte Kadéos',
      image: cardKadeos,
      links: [
        intl.formatMessage({
          id: 'spider.help.cards_content.card_kadeos_link1',
        }),
        intl.formatMessage({
          id: 'spider.help.cards_content.card_kadeos_link2',
        }),
        intl.formatMessage({
          id: 'spider.help.cards_content.card_kadeos_link3',
        }),
      ],
    },
    {
      name: 'Kadéos Connect',
      image: cardShopKadeos,
      links: [
        intl.formatMessage({
          id: 'spider.help.cards_content.card_kadeos_connect_link1',
        }),
        intl.formatMessage({
          id: 'spider.help.cards_content.card_kadeos_connect_link2',
        }),
      ],
    },
    {
      name: 'Chèque Kadéos',
      image: cardChequesKadeos,
      links: [
        intl.formatMessage({
          id: 'spider.help.cards_content.card_kadeos_check_link1',
        }),
        intl.formatMessage({
          id: 'spider.help.cards_content.card_kadeos_check_link2',
        }),
      ],
    },
  ];

  const cards = data?.results.map(reward => {
    const staticCard = staticCards.find(card => card.name === reward.name);

    return {
      name: reward.name,
      image: staticCard?.image || null,
      links: staticCard?.links || [],
    };
  });

  return (
    <div className={classes.pointsBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.help.title' })}
        </Typography>
      </TitleContainer>
      <Paper elevation={0} style={{ borderRadius: '1.5rem' }}>
        <div className={classes.paper}>
          <TitleContainer>
            <Typography variant={'h3'} component={'h3'}>
              {intl.formatMessage({ id: 'spider.help.sub_title' })}
            </Typography>
          </TitleContainer>

          <div className={classes.cardContainer}>
            {isFetching && (
              <Box className={classes.loading}>
                {' '}
                <CircularProgress color='primary' />
              </Box>
            )}
            {cards?.map((card, index) => (
              <div key={index} className={classes.card}>
                <div className={classes.cardContent}>
                  <img
                    src={card.image}
                    alt={card.name}
                    className={classes.image}
                  />
                  <div className={classes.textContent}>
                    <Typography variant='h6' className={classes.cardTitle}>
                      {card.name}
                    </Typography>
                    <div className={classes.linkGroup}>
                      {card.links.map((linkText, i) => (
                        <div key={i} className={classes.link}>
                          {linkText}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={classes.buttonContainer}>
            <Button
              color={'primary'}
              variant={'contained'}
              size={'large'}
              href={`mailto:${contractEmail}?subject=Need Help&body=Please describe your issue...`}
            >
              {' '}
              {intl.formatMessage({
                id: 'spider.help.contact_help_center',
              })}
            </Button>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(Help);
