import { useIntl } from 'react-intl';

/**
 * Use with only with formsy, name validation name followed by ValidationError (Formsy validationErrors={{}}) or Validation (Formsy validations={{}})
 */
export const useBaseFormsyValidation = () => {
  const intl = useIntl();

  const isDefaultRequiredValueValidationError = intl.formatMessage({
    id: 'common.form.required_error',
  });

  const isIntValidationError = intl.formatMessage({
    id: 'common.form.is_int_error',
  });

  /** @param {number} maxLength */
  const maxLengthValidationError = maxLength =>
    intl
      .formatMessage({
        id: 'common.form.required_error',
      })
      .format(maxLength);

  /** @param {string} errorMessage */
  const customErrorValidation = errorMessage => Boolean(errorMessage);

  /** @param {{ isRequired: boolean, isInt: boolean, maxLength: number, customErrorMessage: string }} props */
  const baseFormsyValidationErrors = ({
    isRequired,
    isInt,
    maxLength,
    customErrorMessage,
  }) => {
    const validationErrors = {};
    if (isRequired)
      validationErrors.isDefaultRequiredValue =
        isDefaultRequiredValueValidationError;
    if (isInt) validationErrors.isInt = isIntValidationError;
    if (maxLength)
      validationErrors.maxLength = maxLengthValidationError(maxLength);
    if (customErrorMessage) validationErrors.customError = customErrorMessage;
    return validationErrors;
  };

  /** @param {{ isInt: boolean, maxLength: number, customErrorMessage: string }} props */
  const baseFormsyValidations = ({ isInt, maxLength, customErrorMessage }) => {
    const validations = {};
    if (isInt) validations.isInt = true;
    if (maxLength) validations.maxLength = maxLength;
    if (customErrorMessage)
      validations.customError = customErrorValidation(customErrorMessage);
    return validations;
  };

  return {
    baseFormsyValidationErrors,
    baseFormsyValidations,
  };
};
