import React, { useMemo } from 'react';
import {
  Cup,
  Home2,
  InfoCircle,
  Setting2,
  UserOctagon,
  ShoppingCart,
} from 'iconsax-react';
import { useParams } from 'react-router-dom';
import { UserRoles } from '../enums';

/**
 * Returns the authorized routes for the current user based on their role.
 *  @param {import('@spider:src/enums/userRoles').UserRolesType} role
 * @return {{menus: {text: string, icon: JSX.Element, src: string}[]}} An array of objects representing the authorized routes (menus) for the current user.
 */
const UseAuthorizedRoutes = role => {
  const { contract } = useParams();

  const menus = useMemo(
    () =>
      [
        {
          text: 'home',
          icon: <Home2 />,
          src: `/nodes/${contract}/home`,
          path: `/nodes/:contract/home`,
          authorizedRoles: [
            UserRoles.ADMINISTRATOR,
            UserRoles.COLLABORATOR,
            UserRoles.MANAGER,
            UserRoles.SUPER_ADMINISTRATOR,
          ],
        },
        {
          text: 'participants',
          icon: <UserOctagon />,
          src: `/nodes/${contract}/users`,
          path: `/nodes/:contract/users`,
          authorizedRoles: [
            UserRoles.ADMINISTRATOR,
            UserRoles.MANAGER,
            UserRoles.SUPER_ADMINISTRATOR,
          ],
        },
        {
          text: 'orders',
          icon: <ShoppingCart />,
          src: `/nodes/${contract}/orders`,
          path: `/nodes/:contract/orders`,
          authorizedRoles: [
            UserRoles.ADMINISTRATOR,
            UserRoles.COLLABORATOR,
            UserRoles.MANAGER,
            UserRoles.SUPER_ADMINISTRATOR,
          ],
        },
        {
          text: 'points',
          icon: <Cup />,
          src: `/nodes/${contract}/points`,
          path: `/nodes/:contract/points`,
          authorizedRoles: [
            UserRoles.ADMINISTRATOR,
            UserRoles.COLLABORATOR,
            UserRoles.MANAGER,
            UserRoles.SUPER_ADMINISTRATOR,
          ],
        },
        {
          text: 'administration',
          icon: <Setting2 />,
          src: `/nodes/${contract}/administration`,
          path: `/nodes/:contract/administration`,
          authorizedRoles: [
            UserRoles.ADMINISTRATOR,
            UserRoles.SUPER_ADMINISTRATOR,
          ],
        },
        {
          text: 'help',
          icon: <InfoCircle />,
          src: `/nodes/${contract}/help`,
          path: `/nodes/:contract/help`,
          authorizedRoles: [UserRoles.COLLABORATOR, UserRoles.MANAGER],
        },
      ].filter(nav => nav.authorizedRoles.includes(role)),
    [contract, role],
  );

  return { menus };
};

export default UseAuthorizedRoutes;
