import React from 'react';
import { TextFieldFormsy } from '@spider:components/FormsyComponents/TextFieldFormsy';
import { useIntl } from 'react-intl';

/**
 * @param {{
 *   formData: Object,
 *   classes: any,
 *   error: any,
 *   handleFormChange: (formData: Object) => void,
 * }} props
 */
const Component = ({ formData, classes, error, handleFormChange }) => {
  const intl = useIntl();

  const maxDate = new Date(),
    birthDepartmentMaxLength = 5,
    birthCommuneMaxLength = 3,
    birthCountryMaxLength = 60,
    nationalityCountryMaxLength = 60,
    socialSecurityNumberMaxLength = 15;

  return (
    <div className={classes.formFields}>
      <TextFieldFormsy
        name={'birth_date'}
        value={formData.birth_date}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.informationStep.personalInformation.dateOfBirthField',
        })}
        variant={'outlined'}
        type='date'
        required
        InputLabelProps={{
          shrink: true,
        }}
        validations={{
          isDateLessThan: maxDate,
          customError: Boolean(error?.birth_date),
        }}
        validationErrors={{
          isDefaultRequiredValue: intl.formatMessage({
            id: 'common.form.required_error',
          }),
          isDateLessThan: intl
            .formatMessage({
              id: 'common.form.is_date_less_than',
            })
            .format(
              maxDate.toLocaleString('fr-FR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
              }),
            ),
          customError: error?.birth_date,
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={'birth_department'}
        type={'number'}
        value={formData.birth_department}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.depsOfBirth',
        })}
        required
        variant={'outlined'}
        validations={{
          isInt: true,
          maxLength: birthDepartmentMaxLength,
          customError: Boolean(error?.birth_department),
        }}
        validationErrors={{
          isDefaultRequiredValue: intl.formatMessage({
            id: 'common.form.required_error',
          }),
          isInt: intl.formatMessage({
            id: 'common.form.is_int_error',
          }),
          maxLength: intl
            .formatMessage({
              id: 'common.form.max_length_custom_error',
            })
            .format(birthDepartmentMaxLength),
          customError: error?.birth_department,
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={'birth_city'}
        value={formData.birth_city}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.communeOfBirth',
        })}
        variant={'outlined'}
        required
        validations={{
          maxLength: birthCommuneMaxLength,
          customError: Boolean(error?.birth_city),
        }}
        validationErrors={{
          isDefaultRequiredValue: intl.formatMessage({
            id: 'common.form.required_error',
          }),
          maxLength: intl
            .formatMessage({
              id: 'common.form.max_length_custom_error',
            })
            .format(birthCommuneMaxLength),
          customError: error?.birth_city,
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={'birth_country'}
        value={formData.birth_country}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.countryOfBirth',
        })}
        variant={'outlined'}
        required
        validations={{
          maxLength: birthCountryMaxLength,
          customError: Boolean(error?.birth_country),
        }}
        validationErrors={{
          isDefaultRequiredValue: intl.formatMessage({
            id: 'common.form.required_error',
          }),
          maxLength: intl
            .formatMessage({
              id: 'common.form.max_length_custom_error',
            })
            .format(birthCountryMaxLength),
          customError: error?.birth_country,
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={'nationality_country'}
        value={formData.nationality_country}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.nationality',
        })}
        variant={'outlined'}
        required
        validations={{
          maxLength: nationalityCountryMaxLength,
          customError: Boolean(error?.nationality_country),
        }}
        validationErrors={{
          isDefaultRequiredValue: intl.formatMessage({
            id: 'common.form.required_error',
          }),
          maxLength: intl
            .formatMessage({
              id: 'common.form.max_length_custom_error',
            })
            .format(nationalityCountryMaxLength),
          customError: error?.nationality_country,
        }}
        onChange={handleFormChange}
      />
      <TextFieldFormsy
        name={'social_security_number'}
        type={'number'}
        value={formData.social_security_number}
        label={intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.personalInformation.socialSecurityNumber',
        })}
        variant={'outlined'}
        required
        validations={{
          isInt: true,
          securitySocialSecondRange: formData.birth_date,
          securitySocialThirdRange: formData.birth_date,
          securitySocialFourthRange: formData.birth_department,
          hasMoreCharactersThan: socialSecurityNumberMaxLength,
          maxLength: socialSecurityNumberMaxLength,
          customError: Boolean(error?.social_security_number),
        }}
        validationErrors={{
          isDefaultRequiredValue: intl.formatMessage({
            id: 'common.form.required_error',
          }),
          isInt: intl.formatMessage({
            id: 'common.form.is_int_error',
          }),
          securitySocialSecondRange: intl.formatMessage({
            id: 'common.form.securitySocialSecondRange',
          }),
          securitySocialThirdRange: intl.formatMessage({
            id: 'common.form.securitySocialThirdRange',
          }),
          securitySocialFourthRange: intl.formatMessage({
            id: 'common.form.securitySocialFourthRange',
          }),
          securitySocialFifthRange: intl.formatMessage({
            id: 'common.form.securitySocialFifthRange',
          }),
          hasMoreCharactersThan: intl
            .formatMessage({
              id: 'common.form.has_more_characters_than',
            })
            .format(socialSecurityNumberMaxLength),
          maxLength: intl
            .formatMessage({
              id: 'common.form.max_length_custom_error',
            })
            .format(socialSecurityNumberMaxLength),
          customError: error?.social_security_number,
        }}
        onChange={handleFormChange}
      />
    </div>
  );
};

const LFSSPersonalInformationForm = Component;

export { LFSSPersonalInformationForm };
