import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { neutralColors } from '@src/Spider';
import { useIntl } from 'react-intl';
import systemColors from '@src/Spider/themes/colors/systemColors';
import { ReactComponent as ErrorIcon } from '@spider:src/assets/img/close-circle.svg';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.25rem',
  },
  error: {
    width: '16px',
    height: '16px',
    marginTop: '2px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
});

/** @param {{ isError: boolean, label: string, value: string }} props */
const InformationDetail = ({ isError, label, value }) => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {isError && (
        <ErrorIcon fill={systemColors.errorDark} className={classes.error} />
      )}
      <div className={classes.content}>
        <Typography
          variant={'body1'}
          style={{
            fontWeight: '700',
            color: isError ? systemColors.errorDark : neutralColors.neutral900,
          }}
        >
          {value?.length > 0
            ? value
            : intl.formatMessage({
                id: 'spider.common.undefined',
              })}
        </Typography>
        <Typography
          variant={'body1'}
          style={{
            color: isError ? systemColors.errorDark : neutralColors.neutral700,
          }}
        >
          {label}
        </Typography>
      </div>
    </div>
  );
};

export { InformationDetail };
