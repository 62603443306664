import React, { useContext, useState } from 'react';
import { createContext } from 'react';

const ConvertPointsContext = createContext({
  reward: null,
  setReward: () => {},
  initializeSimulation: false,
  setInitializeSimulation: () => {},
  isSimulateFetching: false,
  setIsSimulateFetching: () => {},
  simulation: null,
  setSimulation: () => {},
  pointStepData: null,
  setPointStepData: () => {},
  informationStepData: null,
  setInformationStepData: () => {},
  declarationStepData: null,
  setDeclarationStepData: () => {},
  summaryStepData: null,
  setSummaryStepData: () => {},
  error: null,
  setError: () => {},
  setErrorOnFormChange: () => {},
});

export const ConvertPointsProvider = ({ children }) => {
  const [reward, setReward] = useState(null);
  const [initializeSimulation, setInitializeSimulation] = useState(false);
  const [isSimulateFetching, setIsSimulateFetching] = useState(false);
  const [simulation, setSimulation] = useState(null);
  const [pointStepData, setPointStepData] = useState(null);
  const [informationStepData, setInformationStepData] = useState(null);
  const [declarationStepData, setDeclarationStepData] = useState(null);
  const [summaryStepData, setSummaryStepData] = useState(null);
  const [error, setError] = useState(null);

  const setErrorOnFormChange = deleteProps => {
    setError(prevError => {
      if (!prevError) return null;
      const newError = { ...prevError };
      if (!newError.preventDelete) {
        deleteProps.forEach(props => {
          delete newError[props];
        });
      } else {
        newError.preventDelete = false;
      }
      return newError;
    });
  };

  return (
    <ConvertPointsContext.Provider
      value={{
        reward,
        setReward,
        initializeSimulation,
        setInitializeSimulation,
        isSimulateFetching,
        setIsSimulateFetching,
        simulation,
        setSimulation,
        pointStepData,
        setPointStepData,
        informationStepData,
        setInformationStepData,
        declarationStepData,
        setDeclarationStepData,
        summaryStepData,
        setSummaryStepData,
        error,
        setError,
        setErrorOnFormChange,
      }}
    >
      {children}
    </ConvertPointsContext.Provider>
  );
};

export const useConvertPoints = () => {
  const context = useContext(ConvertPointsContext);

  if (!context) {
    throw new Error(
      'Convert points context provider needs to be declared to be used',
    );
  }

  return context;
};
