import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { formatDate } from '../../../../helpers/DateHelper';
import { useIntl } from 'react-intl';
/** @typedef {import('@async-calls/hierarchyNodeUsers').PointBalance} PointBalanceType */

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '8rem',
  },
  name: {
    fontSize: '1.5rem',
    fontWeight: 'black',
  },
  expiration: {
    fontSize: '0.6rem',
  },
};

/** @param {{point_balance: PointBalanceType}} props */
const Component = ({ point_balance, classes }) => {
  const { available, next_expiration } = point_balance;
  const intl = useIntl();

  return (
    <Box className={classes.container}>
      <Typography variant='h5'>{available}</Typography>
      <Typography variant='h6'>
        {intl.formatMessage(
          {
            id: 'spider.participants.tabs.list.points_balance.expiration',
          },
          {
            expiringPoints: next_expiration.available,
            expirationDate: formatDate(next_expiration.usability_end),
          },
        )}
      </Typography>
    </Box>
  );
};

const PointsBalance = withStyles(styles)(Component);

export { PointsBalance };
