import React from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import { Button } from '@src/Spider/components';
import { neutralColors } from '@src/Spider';
import { withStyles } from '@mui/styles';
import { ReactComponent as SuccessIcon } from '@spider:src/assets/img/success-icon.svg';
import { CloseButtonIcon } from '@spider:components/CloseButtonIcon';

const styles = {
  goodNewsCard: {
    display: 'flex',
    padding: '1rem 0.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    borderRadius: '0.5rem',
    background: neutralColors.neutralColdWhite,
  },
};

/**
 * @param {{
 *   open: boolean,
 *   onClose: () => {},
 *   points: string,
 *   rewardName: string,
 *   pointBalanceRest: string,
 *   classes: any
 * }} props
 */
const Component = ({
  open,
  onClose,
  points,
  rewardName,
  pointBalanceRest,
  classes,
}) => {
  const intl = useIntl();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <CloseButtonIcon onClick={onClose} />
      </DialogTitle>

      <DialogContent
        style={{ display: 'flex', flexDirection: 'column', gap: '2.5rem' }}
      >
        <SuccessIcon style={{ margin: 'auto' }} />

        <div>
          <Typography
            variant={'h1'}
            component={'h1'}
            style={{ width: '100%', textAlign: 'center' }}
          >
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.orderRewardDialog.title',
            })}
          </Typography>
          <Typography
            variant={'h1'}
            component={'h1'}
            className={'underline-center'}
            style={{ width: '100%', textAlign: 'center' }}
          >
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.orderRewardDialog.subtitle',
            })}
          </Typography>
        </div>

        <div>
          <Typography variant={'subtitle1'} style={{ textAlign: 'center' }}>
            {intl
              .formatMessage({
                id: 'spider.convertPoints.stepper.orderRewardDialog.message',
              })
              .format(parseInt(points).toFormatNumber(false), rewardName ?? '')}
          </Typography>
          <Typography variant={'subtitle1'} style={{ textAlign: 'center' }}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.orderRewardDialog.mailMessage',
            })}
          </Typography>
        </div>

        {pointBalanceRest > 0 && (
          <div className={classes.goodNewsCard}>
            <Typography
              variant={'h2'}
              component={'h2'}
              style={{
                textAlign: 'center',
                background: 'linear-gradient(90deg, #F72717 0%, #FF007D 100%)',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
              }}
            >
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.orderRewardDialog.convertGoodNews',
              })}
            </Typography>
            <div>
              <Typography variant={'subtitle1'} style={{ textAlign: 'center' }}>
                {intl
                  .formatMessage({
                    id: 'spider.convertPoints.stepper.orderRewardDialog.messageGoodNews',
                  })
                  .format(pointBalanceRest.toFormatNumber(false))}
              </Typography>
              <Typography variant={'subtitle1'} style={{ textAlign: 'center' }}>
                {intl.formatMessage({
                  id: 'spider.convertPoints.stepper.orderRewardDialog.subMessageGoodNews',
                })}
              </Typography>
            </div>

            <Button
              size={'small'}
              color={'primary'}
              variant={'contained'}
              onClick={onClose}
            >
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.orderRewardDialog.continueConvert',
              })}
            </Button>
          </div>
        )}
      </DialogContent>

      {pointBalanceRest === 0 && (
        <DialogActions>
          <Button
            size={'small'}
            color={'primary'}
            variant={'contained'}
            onClick={onClose}
          >
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointBalanceDialog.closeAction',
            })}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const SuccessOrderDialog = withStyles(styles)(Component);

export { SuccessOrderDialog };
