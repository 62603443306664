import { ImportLogsStatuses } from '../enums/importLogsStatuses';
import { useIntl } from 'react-intl';

const UseImportLogFile = () => {
  const intl = useIntl();

  const getChipsLabel = status => {
    let chipStatus;
    switch (status) {
      case ImportLogsStatuses.FAILED:
        chipStatus = intl.formatMessage({
          id: 'spider.importLog.status.failed',
        });
        break;
      case ImportLogsStatuses.FINISHED:
        chipStatus = intl.formatMessage({
          id: 'spider.importLog.status.finished',
        });
        break;
      case ImportLogsStatuses.STARTED:
        chipStatus = intl.formatMessage({
          id: 'spider.importLog.status.started',
        });
        break;
      default:
        chipStatus = intl.formatMessage({
          id: 'spider.importLog.status.pending',
        });
        break;
    }

    return chipStatus;
  };

  const getChipsStatus = status => {
    let chipStatus;
    switch (status) {
      case ImportLogsStatuses.FAILED:
        chipStatus = 'error';
        break;
      case ImportLogsStatuses.FINISHED:
        chipStatus = 'imported';
        break;
      default:
        chipStatus = 'inactive';
        break;
    }

    return chipStatus;
  };

  return { getChipsStatus, getChipsLabel };
};

export default UseImportLogFile;
