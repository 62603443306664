import React from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { TitleContainer } from '@spider:src/components/TitleContainer';
import { OwnerEnum } from '@src/Spider/enums/ownerType';
import { PointsList } from '@src/Spider/components/PointsList/PointsList';
import { useCurrentUser } from '@src/Spider/hooks/useCurrentUser';

const styles = {
  ordersBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '0.5rem',
    marginRight: '0.5rem',
  },
};

const UserPoints = ({ ...props }) => {
  const intl = useIntl();
  const { contract } = useParams();
  const user = useCurrentUser();

  return (
    <div className={props.classes.ordersBody}>
      <TitleContainer>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.points.titleUser' })}
        </Typography>
      </TitleContainer>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '1.5rem',
          padding: '1rem',
        }}
      >
        <PointsList
          queryParams={{
            id: contract,
            owner_types: [OwnerEnum.HIERARCHY_NODE_USER],
            hierarchy_node_user: user.uuid,
          }}
        />
      </Box>
    </div>
  );
};

const Component = withStyles(styles)(UserPoints);
export { Component as UserPoints };
