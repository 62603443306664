import React, { useEffect } from 'react';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import UsePagination from '@src/Spider/hooks/UsePagination';
import { useIntl } from 'react-intl';
import { useLazyGetFileListQuery } from '@src/mock/datasets/exportLogs';
import { formatDate } from '@src/helpers/DateHelper';
import { Button } from '@src/Spider/components';
import { Link } from 'react-router-dom';
import { Import } from 'iconsax-react';
import { ExportStatus } from '../ExportStatus';
import { ExportedDate } from './components/ExportedDate';
import { DataList } from '../DataList';
import { EmptyTableView } from '../EmptyTableView';
import EmptyTableImage from '../../assets/img/empty-import-logs-note.png';

/**
 * @param {{queryParams: import("@async-calls/importLogs").ExportImportParams}} props
 */
const ExportLogsList = ({ queryParams }) => {
  const intl = useIntl();
  const [fetch, { data, isFetching, error }] = useLazyGetFileListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      ...queryParams,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const columns = ['creationDate', 'file', 'exportDate', 'status'].map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.orders.tabs.exports.table.${el}`,
    }),
  }));

  const Row = React.memo(({ item, classes }) => (
    <TableRow key={item.uuid}>
      <TableCell>
        <Typography>
          {formatDate(item.created_at).replaceAll('/', '.')}
        </Typography>
        <Typography variant='h6'>
          {item.created_at.toDate().toLocaleString('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </Typography>
      </TableCell>
      <TableCell>{item.file.filename}</TableCell>
      <TableCell>
        <ExportedDate date={item.exported_at} />
      </TableCell>
      <TableCell>
        <ExportStatus status={item.status} />
      </TableCell>
      <TableCell className={classes.stickyColumn}>
        <Button variant='text' size='small' component={Link} to={item.file.src}>
          <Import />
        </Button>
      </TableCell>
    </TableRow>
  ));

  return (
    <DataList
      hasAction
      columns={columns}
      data={data?.results}
      RowComponent={Row}
      isFetching={isFetching}
      error={error}
      pagination={pagination}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      paginationCount={paginationCount}
      emptyState={
        <EmptyTableView
          imageSrc={EmptyTableImage}
          imageAlt='spider.orders.tabs.exports.table.no-data.image-alt'
          title='spider.orders.tabs.exports.table.no-data.title'
        />
      }
    />
  );
};

export { ExportLogsList };
