import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Loader } from '@src/components';
import { withStyles } from '@mui/styles';
import LFSSParameters from './components/LFSSParameters/LFSSParameters';
import { getContractDetailsKeyStart } from '@spider:src/features/contractDetails/getContractDetails/slices';
import { PointsParameters } from './components/PointsParameters';
import { ProductsParameters } from './components/ProductsParameters';
import { ContractDetailsParameters } from '@src/Spider/scenes/Admin/components/ParametersTab/components/ContractDetailsParameters';
import { PlatformParameters } from '@spider:src/scenes/Admin/components/ParametersTab/components/PlatformParameters';
import { ContactsParameters } from './components/ContactsParameters';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
};

const ParametersTabs = ({ ...props }) => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(getContractDetailsKeyStart({ teamGroupId: params.contract }));
  }, []);

  const contractDetails = useSelector(state => state.getContractDetailsKey);

  return (
    <div className={props.classes.root}>
      <PlatformParameters />

      <ContractDetailsParameters />

      {contractDetails.loading && <Loader centered />}
      {contractDetails.success && <LFSSParameters />}

      {contractDetails.loading && <Loader centered />}
      {contractDetails.success && <PointsParameters />}
      <ContactsParameters />

      <ProductsParameters />
    </div>
  );
};

export default withStyles(styles)(ParametersTabs);
