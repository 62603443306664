import { createSlice } from '@reduxjs/toolkit';
import { ConfigCodes } from '../../enums/configCodes';
import { gradients } from '../../themes';

const initialState = {
  config: {
    mainColor: { value: gradients.gradientLeftToRight100 },
    mainDefaultColor: { value: gradients.gradientLeftToRight100 },
  },
  legalDocuments: null,
  hierarchyNode: null,
  success: false,
  loading: false,
  error: null,
};

const createMainSlice = name =>
  createSlice({
    name: name,
    initialState,
    reducers: {
      mainStart: state => {
        state.success = false;
        state.loading = true;
        state.error = null;
      },
      loadLegalDocumentsSuccess: (state, action) => {
        state.legalDocuments = action.payload.legalDocuments.data;

        state.success = true;
        state.loading = false;
        state.error = null;
      },
      loadConfigsSuccess: (state, action) => {
        const mainColor = action.payload.configs.data.find(
          config => config.code === ConfigCodes.MAIN_COLOR,
        );
        if (mainColor) {
          state.config = {
            mainColor: { ...mainColor },
            mainDefaultColor: { ...mainColor },
          };
        }

        state.success = true;
        state.loading = false;
        state.error = null;
      },
      updateConfigProps: (state, action) => {
        if (!action.payload.props) return;
        state.config[action.payload.props].value = action.payload.value;
      },
      mainFailure: (state, action) => {
        state.success = false;
        state.loading = false;
        state.error = action.payload;
      },
      setMainHierarchyNode: (state, action) => {
        state.hierarchyNode = action.payload;
      },
      setMainHierarchyNodeLogo: (state, action) => {
        if (!state.hierarchyNode) return;
        state.hierarchyNode._context.logo = action.payload;
      },
      setMainHierarchyNodeBanner: (state, action) => {
        if (!state.hierarchyNode) return;
        state.hierarchyNode._context.logo = action.payload;
      },
      clearFailure: state => {
        state.error = null;
      },
      clearMainState: () => initialState,
    },
  });

export const createMainKeySlice = createMainSlice('mainKey');

export const {
  mainStart: mainKeyStart,
  setMainHierarchyNode,
  loadLegalDocumentsSuccess,
  loadConfigsSuccess,
  updateConfigProps,
  mainFailure: mainKeyFailure,
  clearFailure,
  clearMainState: mainKeyClear,
  setMainHierarchyNodeLogo,
  setMainHierarchyNodeBanner,
} = createMainKeySlice.actions;

export default {
  mainKey: createMainKeySlice.reducer,
};
