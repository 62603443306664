import React from 'react';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import {
  Button,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { ArrowLeft } from 'iconsax-react';
import { createTheme } from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '2rem',
  },
  stepper: {
    width: '100%',
    margin: 'auto',
    [createTheme().breakpoints.up('md')]: {
      width: '80%',
    },
    [createTheme().breakpoints.up('lg')]: {
      width: '65%',
    },
  },
};

/**
 * @param {{
 *   steps: Array<{ label: string, icon: React.ReactNode }>,
 *   activeStep: number,
 *   previousStep: () => void,
 *   classes: any
 * }} props
 */
const Component = ({ steps, activeStep, previousStep, classes }) => {
  const intl = useIntl();

  return (
    <div className={classes.root}>
      <div className={classes.rootTitle}>
        <Button
          variant={'contained'}
          color={'primary'}
          size={'small'}
          className={'icon light'}
          onClick={() => previousStep()}
        >
          <ArrowLeft />
        </Button>
        <Typography
          variant={'h1'}
          component={'h1'}
          className={'underline-left'}
        >
          {intl.formatMessage({ id: 'spider.convertPoints.title' })}
        </Typography>
      </div>

      <Stepper
        alternativeLabel
        activeStep={activeStep}
        className={classes.stepper}
      >
        {steps.map(step => (
          <Step key={step.label}>
            <StepLabel icon={step.icon}>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

const ConvertPointsStepper = withStyles(styles)(Component);

export { ConvertPointsStepper };
