import React from 'react';
import { ArrowRight as ArrowRightIcon } from 'iconsax-react';
import { Box, Button, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BuildCircleRoundedIcon from '@mui/icons-material/BuildCircleRounded';

import { gradients, neutralColors, systemColors } from '../../../../themes';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start',
    gap: '8px',
    border: '1px solid',
    borderColor: ({ isInMaintenance, maintenanceAccess }) =>
      isInMaintenance && !maintenanceAccess
        ? neutralColors.neutral200
        : 'transparent',
  },
  images: {
    position: 'relative',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
    paddingTop: '56px',
  },
  maintenance: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: systemColors.warningRegular,
  },
  logo: {
    alignItems: 'center',
    backgroundColor: neutralColors.neutralWhite,
    borderRadius: '16px 0px 0px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    height: '64px',
    justifyContent: 'center',
    padding: '10px',
    width: '66px',
    objectFit: 'cover',
  },
  infos: {
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '0px 16px 8px 16px',
  },
  name: {
    width: '100%',
    '&.underline-left::after': {
      background: ({ underlineLineLeftColor }) => underlineLineLeftColor,
    },
  },
  bottom: {
    alignItems: 'center',
    alignSelf: 'stretch',
    display: 'flex',
    flex: 1,
    gap: '8px',
    justifyContent: 'space-between',
    width: '100%',
  },
});

const ContractCard = ({
  cover,
  customer,
  logo,
  name,
  mainColor,
  isInMaintenance,
  maintenanceAccess,
}) => {
  const intl = useIntl();
  const coverBackground = isInMaintenance
    ? systemColors.warningBg
    : (cover ?? mainColor ?? gradients.gradientLeftToRight100);
  const classes = useStyles({
    underlineLineLeftColor: coverBackground,
    isInMaintenance,
    maintenanceAccess,
  });
  const isMaintenanceNotAccessible = isInMaintenance && !maintenanceAccess;
  const isAnimated = isMaintenanceNotAccessible ? '' : 'selectable';

  return (
    <>
      <Card className={`${classes.root} ${isAnimated}`}>
        <div
          className={classes.images}
          style={{
            background: cover
              ? `url(${cover}) center / cover no-repeat`
              : coverBackground,
          }}
        >
          {isInMaintenance && (
            <Box className={classes.maintenance}>
              <BuildCircleRoundedIcon />
              <Typography style={{ color: 'inherit' }}>
                {intl.formatMessage({
                  id: 'spider.org.contract_selection.in_maintenance',
                })}
              </Typography>
            </Box>
          )}
          <img src={logo} alt={'Logo'} className={classes.logo} />
        </div>

        <div className={classes.infos}>
          <Typography
            variant={'h4'}
            component={'h4'}
            className={`${classes.name} underline-left`}
          >
            {name}
          </Typography>

          <div className={classes.bottom}>
            <Typography variant={'subtitle2'}>{customer}</Typography>
            <Button
              variant={'contained'}
              color={'primary'}
              className={'size-tiny icon'}
              disabled={isMaintenanceNotAccessible}
            >
              <ArrowRightIcon />
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ContractCard;
