import useCKS from './UseCKS';
import useKS from './UseKS';
import useCKU from './UseCKU';
import useKSC from './UseKSC';
import { PointCommandIds } from '@spider:src/enums';
import useDefaultHook from './UseDefaultHook';

const UseCommandReward = reward => {
  // static hooks
  const cks = useCKS();
  const cku = useCKU();
  const ks = useKS();
  const ksc = useKSC();
  const defaultHook = useDefaultHook();

  const getHook = () => {
    let commandRewardHook = defaultHook;

    if (!reward) return commandRewardHook;

    switch (reward.uuid) {
      case PointCommandIds.CKS:
        commandRewardHook = cks;
        break;
      case PointCommandIds.CKU:
        commandRewardHook = cku;
        break;
      case PointCommandIds.KSC:
        commandRewardHook = ksc;
        break;
      case PointCommandIds.KS:
        commandRewardHook = ks;
        break;
      default:
        commandRewardHook = defaultHook;
        break;
    }

    return commandRewardHook;
  };

  return getHook(reward);
};

export default UseCommandReward;
