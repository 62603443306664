import React from 'react';
import { withStyles } from '@mui/styles';
import { formatDate } from '@src/helpers/DateHelper';
import { Box, Typography } from '@material-ui/core';

const styles = {
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  time: {
    fontSize: '0.6rem',
  },
};

const ExportedDate = ({ classes, date }) => {
  return (
    <Box className={classes.dateContainer}>
      <Typography>{formatDate(date).replaceAll('/', '.')}</Typography>
      <Typography className={classes.time}>
        {formatDate(date, {
          hour: '2-digit',
          minute: '2-digit',
        })}
      </Typography>
    </Box>
  );
};

const Component = withStyles(styles)(ExportedDate);
export { Component as ExportedDate };
