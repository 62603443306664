import { Chip } from '@material-ui/core';
import React from 'react';
import { ReactComponent as Wrench } from '@spider:src/assets/img/wrench-tool.svg';
import { ReactComponent as TickCircle } from '@spider:src/assets/img/tick-circle.svg';
import { useIntl } from 'react-intl';
import { HierarchyNodeStatuses } from '@src/Spider/enums/hierarchyNodeStatuses';

/**
 * @typedef {import('@spider:src/enums/hierarchyNodeStatuses').HierarchyNodeStatusesType} HierarchyNodeStatusesType
 * @param {{status: HierarchyNodeStatusesType}} props
 */
const HierarchyNodeStatus = ({ status }) => {
  const intl = useIntl();

  switch (status) {
    case HierarchyNodeStatuses.MAINTENANCE:
      return (
        <Chip
          avatar={<Wrench />}
          label={intl.formatMessage({
            id: 'spider.hierarchy_node_status.maintenance',
          })}
          className='partial full-height'
        />
      );
    case HierarchyNodeStatuses.DELETED:
      return (
        <Chip
          label={intl.formatMessage({
            id: 'spider.hierarchy_node_status.deleted',
          })}
          className='error full-height'
        />
      );
    case HierarchyNodeStatuses.INACTIVE:
      return (
        <Chip
          label={intl.formatMessage({
            id: 'spider.hierarchy_node_status.inactive',
          })}
          className='inactive full-height'
        />
      );
    default:
      return (
        <Chip
          avatar={<TickCircle />}
          label={intl.formatMessage({
            id: 'spider.hierarchy_node_status.active',
          })}
          className='active full-height'
        />
      );
  }
};

export default HierarchyNodeStatus;
