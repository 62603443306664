import React from 'react';
import { UserRoles } from '../../enums';
import { AdminHome } from './components/AdminHome';
import { UserHome } from './components/UserHome';
import { Container } from '@material-ui/core';
import { AuthorizedRolesWrapper } from '@src/Spider/components/AuthorizedRolesWrapper';

const Points = () => {
  return (
    <Container maxWidth='xl' disableGutters>
      <AuthorizedRolesWrapper
        roles={[UserRoles.ADMINISTRATOR, UserRoles.SUPER_ADMINISTRATOR]}
      >
        <AdminHome />
      </AuthorizedRolesWrapper>
      <AuthorizedRolesWrapper
        roles={[UserRoles.COLLABORATOR, UserRoles.MANAGER]}
      >
        <UserHome />
      </AuthorizedRolesWrapper>
    </Container>
  );
};

export default Points;
