import { useDispatch, useSelector } from 'react-redux';
import {
  settingPristineDirtyKey,
  settingPristineKeyClear,
} from '../features/settings/preventLeavePageWithoutSave/slices';

const UseDirtyPristine = () => {
  const dispatch = useDispatch();
  const { pristinePage } = useSelector(state => state.settingPristineKey);

  const dirtyChange = () => {
    dispatch(settingPristineDirtyKey());
  };

  const resetPristinePage = () => {
    dispatch(settingPristineKeyClear());
  };

  return { dirtyChange, resetPristinePage, pristinePage };
};

export default UseDirtyPristine;
