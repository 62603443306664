import React from 'react';
import { ConvertPoints } from '@src/Spider/scenes/ConvertPoints/ConvertPoints';
import { ConvertPointsProvider } from '@src/Spider/hooks/useConvertPoints';

const Component = () => {
  return (
    <ConvertPointsProvider>
      <ConvertPoints />
    </ConvertPointsProvider>
  );
};

const ConvertPointWrapper = Component;

export { ConvertPointWrapper };
