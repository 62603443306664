/** @typedef {{
 *   uuid: string,
 *   filename: string,
 *   src: string
 * }} FileInfo */

/** @typedef {{
 *   uuid: string,
 *   created_at: number,
 *   exported_at: number,
 *   type: 'REWARD_ORDER',
 *   status: 'PENDING' | 'COMPLETED' | 'ERROR',
 *   file: FileInfo,
 *   origin: 'AUTOMATIC' | 'MANUAL'
 * }} FileExport */

import { baseApi } from '@src/store/api-query';
import { mockedGetDetails, mockedGetList } from '../fetching';

/** @type {FileExport[]} */
export const filesList = [
  {
    uuid: 'ecded2f1-509c-411a-a116-27f0f7a7eb1b',
    created_at: 1725364800, // Feb 3, 2025
    exported_at: 1725451200, // Feb 4, 2025
    type: 'REWARD_ORDER',
    status: 'PENDING',
    file: {
      uuid: '04f64e88-dc03-4aae-a1c8-3d2e89dec951',
      filename: 'export_cku_batch1.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/da821326478349e7948426c0ff6ec6d8_export_cku_batch1.csv',
    },
    origin: 'AUTOMATIC',
  },
  {
    uuid: '7a9c4e25-3b8d-42f1-95e3-8d6b4f2a1c3d',
    created_at: 1722772800, // Jan 4, 2025
    exported_at: 1722859200, // Jan 5, 2025
    type: 'REWARD_ORDER',
    status: 'COMPLETED',
    file: {
      uuid: '15b23c67-8f4a-4d2e-b6c9-1a2b3c4d5e6f',
      filename: 'export_rewards_march.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_rewards_march.csv',
    },
    origin: 'MANUAL',
  },
  {
    uuid: '9b1d5f36-4c2e-5ga2-a6f4-9e7d8c1b2a3b',
    created_at: 1720785600, // Dec 12, 2024
    exported_at: 1728067200,
    type: 'REWARD_ORDER',
    status: 'ERROR',
    file: {
      uuid: '26c34d78-9e5b-5f3e-c7d8-2b3c4d5e6f7g',
      filename: 'export_kadeos_q1.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_kadeos_q1.csv',
    },
    origin: 'AUTOMATIC',
  },
  {
    uuid: '3c4d5e6f-7g8h-9i1j-k2l3-m4n5o6p7q8r',
    created_at: 1727980800, // Mar 4, 2025
    exported_at: 1728067200, // Mar 5, 2025
    type: 'REWARD_ORDER',
    status: 'COMPLETED',
    file: {
      uuid: '37d45e89-af6c-6g4f-d8e9-3c4d5e6f7g8h',
      filename: 'export_orders_weekly.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_orders_weekly.csv',
    },
    origin: 'AUTOMATIC',
  },
  {
    uuid: '4d5e6f7g-8h9i-1j2k-3l4m-5n6o7p8q9r0',
    created_at: 1719576000, // Nov 27, 2024
    exported_at: 1730659200,
    type: 'REWARD_ORDER',
    status: 'PENDING',
    file: {
      uuid: '48e56f9a-bg7d-7h5g-e9f0-4d5e6f7g8h9i',
      filename: 'export_special_campaign.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_special_campaign.csv',
    },
    origin: 'MANUAL',
  },
  {
    uuid: '5e6f7g8h-9i1j-2k3l-4m5n-6o7p8q9r0s1',
    created_at: 1730572800, // Apr 4, 2025
    exported_at: 1730659200, // Apr 5, 2025
    type: 'REWARD_ORDER',
    status: 'COMPLETED',
    file: {
      uuid: '59f67g0b-ch8e-8i6h-f0g1-5e6f7g8h9i1j',
      filename: 'export_january_rewards.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_january_rewards.csv',
    },
    origin: 'AUTOMATIC',
  },
  {
    uuid: '6f7g8h9i-1j2k-3l4m-5n6o-7p8q9r0s1t2',
    created_at: 1718880000, // Nov 19, 2024
    exported_at: 1730659200,
    type: 'REWARD_ORDER',
    status: 'ERROR',
    file: {
      uuid: '60g78h1c-di9f-9j7i-g1h2-6f7g8h9i1j2k',
      filename: 'export_quarterly_summary.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_quarterly_summary.csv',
    },
    origin: 'AUTOMATIC',
  },
  {
    uuid: '7g8h9i1j-2k3l-4m5n-6o7p-8q9r0s1t2u3',
    created_at: 1733251200, // May 3, 2025
    exported_at: 1733337600, // May 4, 2025
    type: 'REWARD_ORDER',
    status: 'COMPLETED',
    file: {
      uuid: '71h89i2d-ej0g-0k8j-h2i3-7g8h9i1j2k3l',
      filename: 'export_february_batch.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_february_batch.csv',
    },
    origin: 'MANUAL',
  },
  {
    uuid: '8h9i1j2k-3l4m-5n6o-7p8q-9r0s1t2u3v4',
    created_at: 1718275200, // Nov 12, 2024
    exported_at: 1735929600,
    type: 'REWARD_ORDER',
    status: 'PENDING',
    file: {
      uuid: '82i91j3e-fk1h-1l9k-i3j4-8h9i1j2k3l4m',
      filename: 'export_spring_campaign.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_spring_campaign.csv',
    },
    origin: 'AUTOMATIC',
  },
  {
    uuid: '9i1j2k3l-4m5n-6o7p-8q9r-0s1t2u3v4w5',
    created_at: 1735843200, // Jun 3, 2025
    exported_at: 1735929600, // Jun 4, 2025
    type: 'REWARD_ORDER',
    status: 'COMPLETED',
    file: {
      uuid: '93j02k4f-gl2i-2m0l-j4k5-9i1j2k3l4m5n',
      filename: 'export_annual_review.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_annual_review.csv',
    },
    origin: 'AUTOMATIC',
  },
  {
    uuid: '1j2k3l4m-5n6o-7p8q-9r0s-1t2u3v4w5x6',
    created_at: 1717670400, // Nov 5, 2024
    exported_at: 1738608000,
    type: 'REWARD_ORDER',
    status: 'ERROR',
    file: {
      uuid: '04k13l5g-hm3j-3n1m-k5l6-1j2k3l4m5n6o',
      filename: 'export_december_final.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_december_final.csv',
    },
    origin: 'MANUAL',
  },
  {
    uuid: '2k3l4m5n-6o7p-8q9r-0s1t-2u3v4w5x6y7',
    created_at: 1738521600, // Jul 3, 2025
    exported_at: 1738608000, // Jul 4, 2025
    type: 'REWARD_ORDER',
    status: 'COMPLETED',
    file: {
      uuid: '15l24m6h-in4k-4o2n-l6m7-2k3l4m5n6o7p',
      filename: 'export_performance_review.csv',
      src: 'https://objow-secured-cdn-dev.azureedge.net/8f12fe0b-6b87-4ff7-98d5-ffc267c64180/in/system_files/export_performance_review.csv',
    },
    origin: 'AUTOMATIC',
  },
];

/**
 * @param {Object} params
 * @returns {Promise<{results: FileExport[], count: number}>}
 */
export const mockedGetFileList = params => mockedGetList(params, filesList);

export const mockedGetFileDetails = ({ id }) =>
  mockedGetDetails(filesList, file => file.uuid === id);

/**
 * @typedef {Object} GetFileListParams
 * @property {number} [page]
 * @property {number} [page_size]
 */

/**
 * @typedef {Object} GetFileListResponse
 * @property {FileExport[]} results
 * @property {number} count
 */

/**
 * @typedef {Object} GetFileDetailsParams
 * @property {string} id
 */

export const queries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<GetFileListParams, typeof builder.query, 'api-query', GetFileListResponse>}*/
    getFileList: builder.query({
      queryFn: params => mockedGetFileList(params || {}),
    }),

    /**
     * Get file export details by ID
     * @param {GetFileDetailsParams} params
     * @returns {Promise<FileExport>}
     */
    getFileDetails: builder.query({
      queryFn: () => mockedGetFileDetails({}),
    }),
  }),
});

export const {
  useGetFileListQuery,
  useLazyGetFileListQuery,
  useGetFileDetailsQuery,
} = queries;
