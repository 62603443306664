import { baseApi } from '@src/store/api-query';
import instance from '../instance';
import { buildEndpointUrlWithParams } from '../utils';

const baseUrl = 'hierarchy-nodes/';

const hierarchyNodes = {
  get(
    id,
    includeCku = false,
    includePointBalance = false,
    includeAddress = false,
  ) {
    const params = new URLSearchParams();
    params.append('include_cku', includeCku.toString());
    params.append('include_point_balance', includePointBalance.toString());
    params.append('include_address', includeAddress.toString());
    params.append('include_approval', 'true');

    const url = buildEndpointUrlWithParams(baseUrl, id, params);

    return instance.get(url);
  },
  /**
   * @typedef {{code: string}} AccountLinkBody
   * @typedef {{requestBody: AccountLinkBody}} AccountLinkParams
   * @param {AccountLinkParams} params
   * @returns any
   */
  linkAccount({ code }) {
    return instance.post(`${baseUrl}${code}/link-account/`);
  },
  list(type, page) {
    const params = new URLSearchParams();

    if (type) params.append('type', type.toString());
    if (page) params.append('page', page.toString());
    params.append('include_approval', 'true');

    const url = buildEndpointUrlWithParams(baseUrl, '', params);

    return instance.get(url);
  },
  hierarchyNodeUsers(id, own, page) {
    const params = new URLSearchParams();

    params.append('own', own ?? 'false');
    params.append('include_cku', 'true');
    params.append('include_point_balance', 'true');
    params.append('include_address', 'true');
    params.append('include_approval', 'true');
    if (page) params.append('page', page);

    const url = buildEndpointUrlWithParams(
      baseUrl,
      `${id}/hierarchy-node-users`,
      params,
    );

    return instance.get(url);
  },
  /**
   * @param {OrderFilters} params
   */
  listOrders({ id, hierarchy_node_user, page, page_size, include_items }) {
    const params = new URLSearchParams();
    params.append('hierarchy_node_user', hierarchy_node_user);
    params.append('page', page);
    params.append('page_size', page_size);
    params.append('include_items', include_items);

    return instance.get(`${baseUrl}${id}/orders/`, { params });
  },
};

const hierarchyNodeQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<TransactionParams, typeof builder.query, 'api-query', {}>}*/
    getHierarchyNodeOrdersList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => hierarchyNodes.listOrders(params));
      },
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<AccountLinkParams, typeof builder.mutation, 'api-query', AccountLinkResult>}*/
    postLinkAccount: builder.mutation({
      queryFn: async (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => hierarchyNodes.linkAccount(params));
      },
    }),
  }),
});

export const {
  useGetHierarchyNodeOrdersListQuery,
  useLazyGetHierarchyNodeOrdersListQuery,
  usePostLinkAccountMutation,
} = hierarchyNodeQueries;

export default hierarchyNodes;

// Types

/**
 * @typedef {Object} AccountLinkResult
 * @property {string} link_made État indiquant si une liaison a été établie (true) ou non (false)
 * */

/**
 * @typedef {Object} OrderFilters
 * @property {string} id - Code of the hierarchical node in the database (Required)
 * @property {boolean} [include_items] - If true, returns order lines in the items property of a RewardOrderItem resource
 * @property {Array<'CREATED'|'EXPORTED'|'COMPLETED'|'CANCELED'|'DELETED'>} [statuses] - Status(es) of orders to obtain
 * @property {Array<string>} [rewards] - UUIDs of rewards. Filters the list of orders based on rewards present in order lines
 * @property {Date} [start_date] - Start date of the order range to obtain. Only orders made from this date will be returned
 * @property {Date} [end_date] - End date of the order range to obtain. Only orders made until this date will be returned
 * @property {number} [monetary_value_min] - Minimum amount of orders to obtain
 * @property {number} [monetary_value_max] - Maximum amount of orders to obtain
 * @property {Array<'HIERARCHY_NODE'|'HIERARCHY_NODE_USER'>} [owner_types] - Types of order owners to filter
 * @property {Array<'INDIVIDUAL'|'LEGAL_ENTITY'>} [user_types] - Types of users to filter. Note: Using this filter prevents returning hierarchy node orders
 * @property {Array<'ACTIVE'|'INACTIVE'>} [owner_statuses] - Status of profile owners
 * @property {string} [hierarchy_node_user] - Secondary identifier of a user profile to get only their transactions
 * @property {string} [search] - Search by user registration numbers, first names and last names
 * @property {number} [page] - Page number to obtain
 * @property {number} [page_size] - Number of elements to return per page
 */
