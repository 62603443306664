import React from 'react';
import { UserRoles } from '@spider:src/enums';
import { AdminPoints } from './components/AdminPoints';
import { UserPoints } from './components/UserPoints';
import { AuthorizedRolesWrapper } from '@spider:src/components/AuthorizedRolesWrapper';

const Points = () => {
  return (
    <>
      <AuthorizedRolesWrapper
        roles={[UserRoles.ADMINISTRATOR, UserRoles.SUPER_ADMINISTRATOR]}
      >
        <AdminPoints />
      </AuthorizedRolesWrapper>
      <AuthorizedRolesWrapper
        roles={[UserRoles.COLLABORATOR, UserRoles.MANAGER]}
      >
        <UserPoints />
      </AuthorizedRolesWrapper>
    </>
  );
};

export default Points;
