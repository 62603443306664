/** Sert à stocker tous les tag types présents dans les queries de l'app */
export const tagTypes = {
  hierarchyNodeUser: 'hierarchy-node-user',
  systemFile: 'system-file',
  importLog: 'import-log',
  reward: 'reward',
  hierarchyNode: 'hierarchy-node',
  contractDetails: 'contract-details',
  contract: 'contract',
  contact: 'contact',
};
