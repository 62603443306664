import React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import { createTheme } from '@material-ui/core/styles';

const styles = {
  root: {
    display: 'flex',
    height: '16.25rem',
    padding: '2rem 1rem',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: '#FFFAEE',
    position: 'relative',
    overflow: 'clip',
  },
  sphere: {},
  design: {},
  card: {
    display: 'flex',
    margin: 'auto',
  },
};

const DecorationCard = ({
  title = 'La carte Kadéos Universel',
  CommandCard,
  CommandDesign,
  classes,
}) => {
  const isMobile = useMediaQuery(createTheme().breakpoints.down('md'));
  return (
    <div className={classes.root}>
      <Typography
        variant={isMobile ? 'h3' : 'h2'}
        component={isMobile ? 'h3' : 'h2'}
        style={{ zIndex: 4 }}
      >
        {title}
      </Typography>

      <div style={{ zIndex: 1 }} className={classes.sphere} />

      {CommandDesign && (
        <CommandDesign
          style={{ zIndex: 0, width: '100%' }}
          stroke={isMobile ? '#FAAF0C' : '#FDDF9E'}
          className={classes.design}
        />
      )}

      {CommandCard && (
        <div className={classes.card}>
          <CommandCard style={{ zIndex: 5 }} />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(DecorationCard);
