import { OidcClient, User, UserManager } from 'oidc-client-ts';

import { setHierarchyNodeUser } from '@src/auth/features/hierarchyNodeUser/slices';
import { config } from './config';

let oidcClient = new OidcClient(config);
let userManager = new UserManager(config);

export const getAccessToken = async () => {
  try {
    const user = await userManager.getUser();

    if (user && !user.expired) {
      return user.access_token;
    }

    const renewedUser = await attemptSilentRenewal();
    return renewedUser ? renewedUser.access_token : null;
  } catch (error) {
    console.error('Error getting access token:', error);
    return null;
  }
};

const attemptSilentRenewal = async () => {
  try {
    const renewedUser = await userManager.signinSilent();
    if (renewedUser && !renewedUser.expired) {
      return renewedUser;
    } else {
      console.warn('Renewed user is null or expired');
      return null;
    }
  } catch (silentError) {
    console.error('Silent renew error:', silentError);
    return null;
  }
};

export const getUser = () => {
  return userManager.getUser();
};

export const signInRedirect = (
  redirectUri = config.redirect_uri,
  kcIdpHint = null,
) => {
  const extraQueryParams = kcIdpHint ? { kc_idp_hint: kcIdpHint } : {};
  return userManager.signinRedirect({
    redirect_uri: redirectUri,
    extraQueryParams,
  });
};

export const signInRedirectCallback = () => {
  return userManager.signinRedirectCallback();
};

export const signOutSilent = dispatch => {
  userManager
    .signoutSilent()
    .then(() => {
      userManager.storeUser(null);
      dispatch(setHierarchyNodeUser(null));
    })
    .catch(error => {
      console.error('Error during logout', error);
      userManager.storeUser(null);
      dispatch(setHierarchyNodeUser(null));
    });
};

export const signUpRedirect = async redirectUri => {
  try {
    const effectiveRedirectUri = redirectUri || config.redirect_uri;
    const signinRequest = await oidcClient.createSigninRequest({
      redirect_uri: effectiveRedirectUri,
    });

    window.location.href = signinRequest.url.replace(
      '/openid-connect/auth',
      '/openid-connect/registrations',
    );
  } catch (error) {
    console.error('Error during registration request:', error);
  }
};

export const signUpRedirectCallback = async () => {
  try {
    let user = await userManager.getUser();

    if (user === null) {
      const signinResponse = await oidcClient.processSigninResponse(
        window.location.href,
      );
      user = new User(signinResponse);
      await userManager.storeUser(user);
    }
  } catch (error) {
    console.error('Error during callback registration:', error);
  }
};
