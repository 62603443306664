import instance from '../instance';
import { baseApi } from '@src/store/api-query';
import { tagTypes } from '@src/store/api-tags';

const baseUrl = 'hierarchy-nodes/';

const contacts = {
  /**
   * @param {string} hierarchyNodeId
   */
  list(hierarchyNodeId) {
    return instance.get(`${baseUrl}${hierarchyNodeId}/contacts/`);
  },

  /**
   * @param {string} hierarchyNodeId
   * @param {Array<Object>} contactsData
   */
  update(hierarchyNodeId, contactsData) {
    return instance.put(`${baseUrl}${hierarchyNodeId}/contacts/`, contactsData);
  },

};

const contactQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /**
     * @param {Object} params
     * @property {string} hierarchyNodeId
     */
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<{}, typeof builder.query, 'api-query', {}>} */
    getContacts: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => contacts.list(params.hierarchyNodeId));
      },
      providesTags: result =>
        result?.results
          ? result.results.map(contact => ({
              type: tagTypes.contact,
              id: contact.id,
            }))
          : [tagTypes.contact],
    }),
    /**
     * @param {Object} params
     * @property {string} hierarchyNodeId
     * @property {Array<Object>} contacts
     */
    updateContacts: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() =>
          contacts.update(params.hierarchyNodeId, params.contacts),
        );
      },
      invalidatesTags: [tagTypes.contact],
    }),
  }),
});

export const { useGetContactsQuery, useUpdateContactsMutation } =
  contactQueries;

export default contacts;
