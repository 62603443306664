import React from 'react';
import { ActionModal } from '@src/Spider/components/ActionModal';
import { useUpdateHierarchyNodeUserStatusMutation } from '@src/data/api/endpoints/hierarchyNodeUsers';
import { HierarchyNodeUserStatuses } from '@src/Spider/enums';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';

/**
 * @typedef {Object} ChangeStatusDialogProps
 * @property {() => void} handleClose - Function to close the dialog
 * @property {boolean} open - Controls dialog visibility
 * @property {import('@async-calls/hierarchyNodeUsers.jsx').UserBodyDetailed} user
 */

/**
 * Dialog component for changing user status
 * @param {ChangeStatusDialogProps} props
 * @returns {JSX.Element}
 */
export const ChangeStatusDialog = ({ handleClose, open, user }) => {
  const intl = useIntl();
  const [updateStatus, { isLoading }] =
    useUpdateHierarchyNodeUserStatusMutation();
  const isUserActive = user?.status === 'ACTIVE';

  /**
   * Handles the status update operation
   * @returns {Promise<void>}
   */
  const handleStatusUpdate = async () => {
    try {
      await updateStatus({
        user_id: user?.uuid,
        requestBody: {
          status: isUserActive
            ? HierarchyNodeUserStatuses.INACTIVE
            : HierarchyNodeUserStatuses.ACTIVE,
        },
      }).unwrap();
      handleClose();
      toast.success(
        intl.formatMessage({
          id: 'spider.hierarchy_node_user_status.update.success',
        }),
      );
    } catch (error) {
      // Handle error
      console.error('Failed to update status:', error);
      toast.error(
        intl.formatMessage({
          id: 'spider.hierarchy_node_user_status.update.error',
        }),
      );
      handleClose();
    }
  };
  const content = isUserActive ? 'unsubscribe' : 'subscribe';
  return (
    <ActionModal
      open={open}
      handleClose={handleClose}
      title={`spider.participants.details.modals.${content}.title`}
      description={`spider.participants.details.modals.${content}.description`}
      confirmButtonText={`spider.participants.details.modals.${content}.confirm`}
      cancelButtonText={`spider.participants.details.modals.${content}.cancel`}
      onConfirm={handleStatusUpdate}
      isLoading={isLoading}
    />
  );
};
