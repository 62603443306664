import { neutralColors } from '../colors';

const tooltipStyles = {
  MuiTooltip: {
    tooltip: {
      width: '194px',
      maxWidth: '194px',
      borderRadius: '16px',
      backgroundColor: neutralColors.neutral800,
      color: neutralColors.neutralWhite,
      padding: '16px 24px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    arrow: {
      color: neutralColors.neutral800,
      '&::before': {
        backgroundColor: neutralColors.neutral800,
      },
    },
  },
};

export default tooltipStyles;
